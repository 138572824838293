import { createKeyStoreProvider } from '@candis/utils';
import { ReactNode, useId } from 'react';
import { INITIAL_SCALE } from './ReactPdf/constants';

type PdfViewProviderType = {
  fitType: 'height' | 'width';
  page: number;
  rotation: number;
  scale: number;
  size: {
    height: number;
    width: number;
  };
  viewerId: string;
};

const [Provider, useProvider] = createKeyStoreProvider<PdfViewProviderType>(
  'PdfViewProvider',
  'usePdfViewProvider'
);

const initialValue: PdfViewProviderType = {
  fitType: 'width',
  page: 1,
  rotation: 0,
  scale: 1,
  size: {
    height: 0,
    width: 0,
  },
  viewerId: '',
};

export const PdfViewProvider = ({
  children,
  initialScale = INITIAL_SCALE,
}: { children: ReactNode; initialScale?: number }) => {
  const value = {
    ...initialValue,
    scale: initialScale,
    viewerId: useId(),
  };

  return <Provider initialValue={value}>{children}</Provider>;
};

export const usePdfViewProvider = () => {
  const [useValue, setValue] = useProvider();
  return { usePdfView: useValue, setPdfView: setValue };
};
