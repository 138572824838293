import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { AddReimbursementItemActionMenuButton } from './AddReimbursementItemActionMenuButton';
import { ReimbursementItem } from './ReimbursementItem';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import styles from './LeftSection.module.css';
import { ScrollBox } from '@candisio/design-system/next';

interface ReimbursementItemsCardProps {
  creatingReimbursementItem: boolean;
  items: ReimbursementItemForList[];
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
}

export const ReimbursementItemsCard = ({
  items,
  creatingReimbursementItem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ReimbursementItemsCardProps) => {
  const { updateSearchParam } = useMutateSearchParams();
  const { reimbursementUIConfig } = useReimbursementFormsContext();

  const { canManageReimbursementItems } = reimbursementUIConfig;

  const handleClick = (id: string) => () => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(id);
  };

  return (
    <div className="grid gap-2 overflow-y-auto">
      <ScrollBox scrollDirection="y" className="max-h-[15.625rem]">
        <ul className={styles['items-ulist']}>
          {items.map(item => {
            return (
              <li key={item.id} className="rounded-lg">
                <ReimbursementItem
                  reimbursementItem={item}
                  onClick={handleClick(item.id)}
                />
              </li>
            );
          })}
        </ul>
      </ScrollBox>
      {canManageReimbursementItems && (
        <div className="border border-gray-200 rounded-[0.5rem]">
          <AddReimbursementItemActionMenuButton
            creatingReimbursementItem={creatingReimbursementItem}
            onGeneralExpenseClick={onGeneralExpenseClick}
            onHospitalityExpenseClick={onHospitalityExpenseClick}
          />
        </div>
      )}
    </div>
  );
};
