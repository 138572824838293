import { PerDiemDay } from 'generated-types/graphql.types';

export type Day = Pick<
  PerDiemDay,
  'id' | 'date' | 'providedBreakfast' | 'providedDinner' | 'providedLunch'
>;

const mapMeals = (day: Day) => {
  const meals: Array<'breakfast' | 'lunch' | 'dinner'> = [];
  if (day.providedBreakfast) {
    meals.push('breakfast');
  }
  if (day.providedLunch) {
    meals.push('lunch');
  }
  if (day.providedDinner) {
    meals.push('dinner');
  }

  return meals;
};

export const mapDay = (day: Day) => ({
  dayId: day.id,
  date: day.date,
  meals: mapMeals(day),
});
