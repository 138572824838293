import {
  ActivityUser,
  ReimbursementCaseWorkflowStepSkippedEventDataFragment,
  WorkflowStepCondition,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'components/History/items/Collapse';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import clsx from 'clsx';

export const ReimbursementCaseWorkflowStepSkipped = ({
  properties,
}: ReimbursementCaseWorkflowStepSkippedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const workflowConditions = properties?.workflowStep?.conditions ?? [];
  const filteredApprovers = (properties?.skippedApprovers ?? []).filter(
    (approver): approver is ActivityUser => !!approver
  );

  const isLessThan10Approvers = filteredApprovers.length < 10;

  const renderConditionText = (conditions: WorkflowStepCondition[]) => {
    if (!conditions.length) return null;

    const conditionTexts = conditions.map((step: WorkflowStepCondition) => {
      const field = t(
        `timeline.workflowStepSkipped.reason.field.${step.field}`
      );
      const operator = t(
        `timeline.workflowStepSkipped.reason.operator.${step.operator}`
      );

      return t('timeline.workflowStepSkipped.reason.composed', {
        field,
        operator,
        value: step.values[0],
      });
    });

    return conditionTexts.join(t('timeline.workflowStepSkipped.and'));
  };

  return (
    <>
      <span>{t('timeline.workflowStepSkipped.title') + ' '}</span>
      {renderConditionText(workflowConditions)}
      <Collapse>
        <ol
          className={clsx('pl-4', {
            'pl-6': !isLessThan10Approvers,
          })}
        >
          {filteredApprovers.map((approver, index) => (
            <li key={`${approver.name}-${index}`}>
              {t('timeline.workflowStepSkipped.by', {
                name: approver.name,
              })}
            </li>
          ))}
        </ol>
      </Collapse>
    </>
  );
};
