import { Flex, Grid, TruncatedText } from '@candisio/design-system';
import { SplitBookingsLayout } from 'containers/SplitBookings/components/SplitBookingsLayout';
import { AccordionItem } from 'containers/SplitBookings/components/Summary/AccordionItem';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementSplitBookingsFormValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { mappedTypesToItems } from '../../LeftSection/ReimbursementItem';
import { generateSplitBookingEntryFieldNames } from '../utils/generateSplitBookingsFormFieldNames';
import { BookingFooterActions } from './BookingFooterActions';
import { BookingSummary } from './BookingSummary';
import { ReimbursementSplitBookingsForm } from './ReimbursementSplitBookingsForm';
import { ReimbursementSplitBookingsFormHeader } from './ReimbursementSplitBookingsFormHeader';
import { useFormEffects } from './hooks/useFormEffects';
import { useRemainingAmount } from './hooks/useFormatAmountHelpers';
import { useReimbursementSplitBookingFormActions } from './hooks/useReimbursementItemBookingsFormActions';
import { useUserRoles } from 'hooks/useUserRoles';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useFormFieldOptions } from './hooks/useFormFieldOptions';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';

const ACCORDION_PREFIX = 'reimbursement-booking-accordion';

interface ReimbursmentItemBookingsFormContainerProps {
  status?: ReimbursementCaseStatus;
  isCurrentUserCaseApprover: boolean;
}

export const ReimbursmentItemBookingsFormContainer = ({
  status,
  isCurrentUserCaseApprover,
}: ReimbursmentItemBookingsFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { isOnlyApprover, isOnlyRequester } = useUserRoles();
  const [showErrors, setShowErrors] = useState(false);
  const { track } = useAnalytics();
  const fieldOptions = useFormFieldOptions();

  const {
    activeBooking,
    updateActiveBooking,
    formMethods,
    resetDrawerAndForm,
    getSplitBookingsFormRules,
  } = useReimbursementSplitBookingHelpers();

  const { isApprovingEditMode } = useReimbursementFormsContext();

  const { entryIndex, bookingId } = activeBooking;
  const topFields = generateSplitBookingEntryFieldNames(entryIndex);

  const { control, getValues, watch } = formMethods;
  const [grossAmount, itemType, itemTitle, itemStatus, itemId] = getValues([
    topFields.grossAmount,
    topFields.reimbursementItemType,
    topFields.reimbursementItemTitle,
    topFields.reimbursementItemStatus,
    topFields.reimbursementItemId,
  ]);
  const bookings = watch(topFields.bookings) ?? [];

  const { calculateRemainingAmount } = useRemainingAmount();
  const remainingAmount = calculateRemainingAmount(grossAmount, bookings);

  const { fieldRules, actionRules } = getSplitBookingsFormRules({
    status,
    itemStatus,
    isCurrentUserCaseApprover,
    isApprovingEditMode,
    fieldOptions,
    isOnlyApproverRole: isOnlyApprover,
    isOnlyRequesterRole: isOnlyRequester,
  });

  const { append, remove } = useFieldArray<
    ReimbursementSplitBookingsFormValues,
    `reimbursementItemBookings.${number}.bookings`
  >({
    control,
    name: topFields.bookings,
  });

  const handleShowErrors = useCallback((val: boolean) => {
    setShowErrors(val);
  }, []);

  const {
    isUpdatingBooking,
    checkFormErrors,
    handleDeleteBooking,
    handleAcceptSplits,
    handleAddSplitBooking,
    handleSetRemainingAmount,
  } = useReimbursementSplitBookingFormActions({
    topFields,
    onAppendBooking: append,
    onRemoveBooking: remove,
    setShowErrors: handleShowErrors,
  });

  const hasOneBooking = bookings?.length === 1;
  const { bookingErrors, hasErrors } = checkFormErrors(
    entryIndex,
    remainingAmount
  );

  useFormEffects({
    status,
    hasErrors,
    remainingAmount,
    handleSetRemainingAmount,
    handleShowErrors,
  });

  const currentActionRules = actionRules[entryIndex] ?? {};

  const canAddAndDeleteSplits = currentActionRules.canAddAndDeleteSplits;
  const ItemIcon = mappedTypesToItems[itemType].icon;

  const shouldTrack =
    status === ReimbursementCaseStatus.Review ||
    status === ReimbursementCaseStatus.Approving;

  const acceptSplitBookings = async () => {
    await handleAcceptSplits();

    if (shouldTrack) {
      track(TrackingEvents.EXPENSE_COLLECTION_ACCEPT_SPLIT_CLICKED, {
        expense_id: itemId,
        expense_type: itemType,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <SplitBookingsLayout
        key={`reimbursement-split-bookings-${entryIndex}`}
        title={
          <Flex gap="space8" alignItems="center">
            <ItemIcon />
            <TruncatedText>{itemTitle}</TruncatedText>
          </Flex>
        }
        header={
          <ReimbursementSplitBookingsFormHeader
            fields={topFields}
            remainingAmount={remainingAmount}
            isReadOnly={!canAddAndDeleteSplits}
          />
        }
      >
        <Grid as="form" height="100%">
          <Grid alignContent="space-between" gap="space16">
            <Flex direction="column">
              {hasOneBooking ? (
                <ReimbursementSplitBookingsForm
                  hasOneBooking
                  entryIndex={entryIndex}
                  bookingIndex={0}
                  bookingId={bookings[0].bookingId}
                  fieldRules={fieldRules[entryIndex]?.[0]}
                />
              ) : (
                bookings.map((booking, bookingIndex) => {
                  return (
                    <AccordionItem
                      key={booking.bookingId}
                      id={`${ACCORDION_PREFIX}-${bookingIndex}`}
                      open={booking.bookingId === bookingId}
                      error={!!bookingErrors[bookingIndex]}
                      onClick={() =>
                        updateActiveBooking({ bookingId: booking.bookingId })
                      }
                      summary={
                        <BookingSummary
                          splitNumber={bookingIndex + 1}
                          booking={booking}
                          onDelete={
                            canAddAndDeleteSplits
                              ? async () =>
                                  await handleDeleteBooking(bookingIndex)
                              : undefined
                          }
                        />
                      }
                    >
                      {booking.bookingId === bookingId && (
                        <ReimbursementSplitBookingsForm
                          bookingId={bookingId}
                          entryIndex={entryIndex}
                          bookingIndex={bookingIndex}
                          fieldRules={fieldRules[entryIndex][bookingIndex]}
                        />
                      )}
                    </AccordionItem>
                  );
                })
              )}
            </Flex>
            <BookingFooterActions
              actionRules={currentActionRules}
              isLoading={isUpdatingBooking}
              onCancelClick={resetDrawerAndForm}
              onAddNewSplit={async () =>
                await handleAddSplitBooking(remainingAmount)
              }
              onAcceptBookings={acceptSplitBookings}
              errorMessage={
                showErrors ? t('splitBookings.genericError') : undefined
              }
            />
          </Grid>
        </Grid>
      </SplitBookingsLayout>
    </FormProvider>
  );
};
