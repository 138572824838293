import { Button } from '@candisio/design-system';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';

export const FitWidthButton = () => {
  const [t] = useTranslation();
  const { usePdfView, setPdfView } = usePdfViewProvider();
  const initialScale = useRef(usePdfView('scale')).current;

  return (
    <Button
      icon="expand"
      label={t('document.pageWidth')}
      onClick={() => {
        setPdfView('fitType', 'width');
        setPdfView('scale', initialScale);
      }}
      variant="tertiary"
      size="small"
    />
  );
};
