import { DocumentCurrency, DocumentType } from 'generated-types/graphql.types';
import { isNumber } from 'lodash';
import moment from 'moment';
import { z } from 'zod';

export const MAX_NOTIFY_PEOPLE_LENGTH = 10;
export const MAX_RESPONSIBLE_PEOPLE_LENGTH = 1;

export interface StorageFormSchemaOptions {
  modifyEcmDocumentEditAccessFF?: boolean;
}

const formSchema = z.object({
  amount: z.number().nullish(),
  currency: z.nativeEnum(DocumentCurrency).nullish(),
  contact: z
    .object({ inputValue: z.string(), value: z.string().nullable() })
    .superRefine(({ inputValue, value }, ctx) => {
      if (inputValue && !value) {
        ctx.addIssue({
          code: 'custom',
          path: ['value'],
        });
      }
    }),
  documentSubCategory: z.string().nullish(),
  costCenter: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  documentDate: z.string().nullish(),
  documentName: z.string().nullish(),
  documentNumber: z.string().trim().nullish(),
  documentType: z.string().transform(value => value as DocumentType),
  endDate: z.string().nullish(),
  notes: z.string().trim().nullish(),
  notifyPerson: z.array(z.string()).max(MAX_NOTIFY_PEOPLE_LENGTH).nullish(),
  relativeDate: z.string().nullish(),
  responsiblePerson: z
    .array(z.string())
    .max(MAX_RESPONSIBLE_PEOPLE_LENGTH)
    .nullish(),
  startDate: z.string().nullish(),
  terminationDate: z.string().nullish(),
  terminationReminderDate: z.string().nullish(),
  isSensitive: z.boolean(),
});

/** Schema to validate that currency is required
 *  when amount is entered. We allow amount to be "0 / 0,00"
 */
const amountWithoutCurrencySchema = formSchema
  .pick({ amount: true, currency: true })
  .superRefine(({ amount, currency }, ctx) => {
    if ((amount && currency) || (!isNumber(amount) && !currency)) {
      return;
    }

    if (isNumber(amount) && !currency) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'ecm:storageForm.actions.validationErrors.amountWithoutCurrency',
        },
        path: ['currency'],
      });
    }
  });

/** Schema to validate that amount is required
 *  when currency is selected. We allow amount to be "0 / 0,00"
 */
const currencyWithoutAmountSchema = formSchema
  .pick({ amount: true, currency: true })
  .superRefine(({ amount, currency }, ctx) => {
    if ((amount && currency) || (!isNumber(amount) && !currency)) {
      return;
    }

    if (!isNumber(amount) && currency) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey:
            'ecm:storageForm.actions.validationErrors.currencyWithoutAmount',
        },
        path: ['amount'],
      });
    }
  });

/** Schema to validate end date relative to start date */
const startDateBeforeEndDateSchema = formSchema
  .pick({ documentType: true, startDate: true, endDate: true })
  .superRefine(({ documentType, startDate, endDate }, ctx) => {
    if (documentType !== DocumentType.Contract || !startDate || !endDate) {
      return;
    }

    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);

    if (endDateMoment.isBefore(startDateMoment)) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey: 'ecm:storageForm.actions.validationErrors.endDate',
        },
        path: ['endDate'],
      });
    }
  });

/** Schema to validate termination reminder date relative to termination date */
const terminationReminderDateSchema = formSchema
  .pick({
    documentType: true,
    terminationDate: true,
    terminationReminderDate: true,
  })
  .superRefine(
    ({ documentType, terminationDate, terminationReminderDate }, ctx) => {
      if (documentType !== DocumentType.Contract || !terminationReminderDate) {
        return;
      }

      const terminationDateMoment = moment(terminationDate);
      const terminationReminderDateMoment = moment(terminationReminderDate);

      // No cancel date -> reminder date must be in the future
      if (!terminationDate && terminationReminderDateMoment.isBefore()) {
        ctx.addIssue({
          code: 'custom',
          params: {
            translationKey:
              'ecm:storageForm.actions.validationErrors.terminationReminderDate',
          },
          path: ['terminationReminderDate'],
        });
        // Has cancel date -> reminder date must be before or same as cancel date
      } else if (
        terminationDate &&
        terminationDateMoment.isBefore(terminationReminderDateMoment)
      ) {
        ctx.addIssue({
          code: 'custom',
          params: {
            translationKey:
              'ecm:storageForm.actions.validationErrors.terminationReminderDateBeforeTerminationDate',
          },
          path: ['terminationReminderDate'],
        });
      }
    }
  );

/** Require responsible person when reminder date is selected */
const responsiblePersonSchema = ({
  modifyEcmDocumentEditAccessFF,
}: Pick<StorageFormSchemaOptions, 'modifyEcmDocumentEditAccessFF'>) =>
  formSchema
    .pick({
      documentType: true,
      responsiblePerson: true,
      terminationReminderDate: true,
    })
    .superRefine(
      ({ documentType, responsiblePerson, terminationReminderDate }, ctx) => {
        if (documentType !== DocumentType.Contract) {
          return;
        }

        if (
          modifyEcmDocumentEditAccessFF &&
          (responsiblePerson ?? []).length < 1
        ) {
          ctx.addIssue({
            code: 'invalid_type',
            expected: 'string',
            received: 'null',
            path: ['responsiblePerson'],
          });
        }

        // TODO remove this once dissolveInformedPeopleFieldFF is archived
        if (terminationReminderDate && (responsiblePerson ?? []).length < 1) {
          ctx.addIssue({
            code: 'custom',
            params: {
              translationKey:
                'ecm:storageForm.actions.validationErrors.responsiblePerson',
            },
            path: ['responsiblePerson'],
          });
        }
      }
    );

// TODO remove this once dissolveInformedPeopleFieldFF is archived
/** Require responsible or notify person when contract was uploaded as sensitive */
export const sensitiveContractVisibilitySchema = ({
  modifyEcmDocumentEditAccessFF,
}: Pick<StorageFormSchemaOptions, 'modifyEcmDocumentEditAccessFF'>) =>
  formSchema
    .pick({
      documentType: true,
      responsiblePerson: true,
      notifyPerson: true,
      isSensitive: true,
    })
    .superRefine(
      ({ documentType, responsiblePerson, notifyPerson, isSensitive }, ctx) => {
        if (documentType !== DocumentType.Contract || !isSensitive) {
          return;
        }

        if (
          (notifyPerson ?? []).length < 1 &&
          (responsiblePerson ?? []).length < 1 &&
          !modifyEcmDocumentEditAccessFF
        ) {
          ctx.addIssue({
            code: 'custom',
            params: {
              translationKey:
                'ecm:storageForm.actions.validationErrors.responsiblePersonRequired',
            },
            path: ['responsiblePerson'],
          });

          ctx.addIssue({
            code: 'custom',
            params: {
              translationKey:
                'ecm:storageForm.actions.validationErrors.requiredForSensitiveContracts',
            },
            path: ['notifyPerson'],
          });
        } else if ((responsiblePerson ?? []).length < 1) {
          ctx.addIssue({
            code: 'custom',
            params: {
              translationKey:
                'ecm:storageForm.actions.validationErrors.responsiblePersonRequired',
            },
            path: ['responsiblePerson'],
          });
        }
      }
    );

export const storageFormSchema = ({
  modifyEcmDocumentEditAccessFF,
}: StorageFormSchemaOptions) =>
  formSchema
    .and(amountWithoutCurrencySchema)
    .and(currencyWithoutAmountSchema)
    .and(responsiblePersonSchema({ modifyEcmDocumentEditAccessFF }))
    .and(startDateBeforeEndDateSchema)
    .and(terminationReminderDateSchema)
    .and(sensitiveContractVisibilitySchema({ modifyEcmDocumentEditAccessFF }));

export type StorageFormValues = z.infer<ReturnType<typeof storageFormSchema>>;
