import {
  DocumentCurrency,
  Maybe,
  Money,
  PerDiemDay,
  PerDiemDayMeal,
  PerDiemLocation,
} from 'generated-types/graphql.types';
import { formatDateTime } from './formatDateTime';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useCallback } from 'react';
import { formatRatioAsPercentage } from './formatRatioAsPercentage';
import { DayType, MealRate } from '../hooks/usePerDiemItemCalculation';
import { useCurrentUser } from 'providers/CurrentUserProvider';

interface FormattedPerDiemDay {
  id: string;
  date: string;
  dayRate: string;
  dayType: DayType;
  reimbursableRate: string;
  location: string;
  countryIsoCode: string;
  breakfast?: MealRate;
  lunch?: MealRate;
  dinner?: MealRate;
}

type PerDiemLocationKeys = Pick<
  PerDiemLocation,
  'location' | 'countryIsoCode' | 'country'
>;

type PerDiemDayKeys = Pick<
  PerDiemDay,
  'id' | 'dayRate' | 'reimbursableRate' | 'isFullDay' | 'meals' | 'date'
> & {
  location: PerDiemLocationKeys;
};

const getLocationDisplayText = (location?: PerDiemLocationKeys): string => {
  if (!location) return '';

  const { country, location: locationData } = location;
  if (locationData && country) return `${country}, ${locationData}`;
  return country;
};

export const useFormatPerDiemDay = () => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  const locale = useCurrentUser()?.locale;

  const formatMoney = useCallback(
    (money: Maybe<Money>) => {
      return transactionAmountFormatter({
        value: money?.amount ?? 0,
        currency: money?.currency ?? DocumentCurrency.Eur,
        precision: money?.precision ?? 2,
      });
    },
    [transactionAmountFormatter]
  );

  const createMealRate = (meal: PerDiemDayMeal): MealRate | undefined => {
    if (!meal.provided) return undefined;

    return {
      rateDifference: `-${formatMoney(meal.deduction)}`,
      percentageDifference: `-${formatRatioAsPercentage(meal.rateRatio)}`,
    };
  };

  const formatDay = (day: PerDiemDayKeys): FormattedPerDiemDay => {
    const { dayRate, reimbursableRate, isFullDay, location, date, id, meals } =
      day;

    return {
      id,
      date: formatDateTime(date, { formatKey: 'DATE_WITH_YEAR', locale }),
      dayRate: formatMoney(dayRate),
      dayType: isFullDay ? 'FULL_DAY' : 'HALF_DAY',
      reimbursableRate: formatMoney(reimbursableRate),
      breakfast: createMealRate(meals.breakfast),
      lunch: createMealRate(meals.lunch),
      dinner: createMealRate(meals.dinner),
      location: getLocationDisplayText(location),
      countryIsoCode: location.countryIsoCode,
    };
  };

  return formatDay;
};
