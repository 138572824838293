import { InfoPopupButton } from '@candisio/design-system';
import { UploadFileErrorIcon } from 'components/Icons/notifications/UploadFileErrorIcon';
import { UploadFileProcessingIcon } from 'components/Icons/notifications/UploadFileProcessingIcon';
import { UploadFileSuccessIcon } from 'components/Icons/notifications/UploadFileSuccessIcon';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { FileState, UploadFileItem } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { RoutePath } from 'utils/path_generator';

const mapStatusToColor = (status: FileState) => {
  switch (status) {
    case FileState.Error:
      return 'text-red-500';
    case FileState.Uploading:
      return 'text-yellow-500';
    case FileState.Uploaded:
      return 'text-green-500';
    default:
      return 'text-gray-800';
  }
};

const iconMap: Record<FileState, JSX.Element> = {
  [FileState.Uploading]: <UploadFileProcessingIcon />,
  [FileState.Error]: <UploadFileErrorIcon />,
  [FileState.Uploaded]: <UploadFileSuccessIcon />,
};

export interface UploadFileNotificationItemProps {
  data: UploadFileItem;
  onClose: () => void;
}
const inboxPathPattern = new RoutePath(`/:organizationSlug${Routes.INBOX}/:id`);

export const UploadFileNotificationItem = ({
  data,
  onClose,
}: UploadFileNotificationItemProps) => {
  const { fileName, state, errorDetails, documentId } = data;
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();

  const label = documentId ? (
    <RouterLink
      onClick={onClose}
      to={inboxPathPattern.stringify({
        id: documentId,
        organizationSlug,
      })}
    >
      {fileName}
    </RouterLink>
  ) : (
    fileName
  );

  const notificationData = (
    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-4 px-3 py-2">
      {iconMap[state]}
      <span className="font-semibold">{label}</span>
      <div className="grid grid-flow-col items-center gap-1">
        <span className={mapStatusToColor(state)}>
          {t(`uploads.status.${state.toLowerCase()}`)}
        </span>
        {errorDetails && <InfoPopupButton message={t(errorDetails)} />}
      </div>
    </div>
  );

  return state !== FileState.Uploading ? (
    notificationData
  ) : (
    <ProgressBar childStyles={{ padding: '0' }}>{notificationData}</ProgressBar>
  );
};
