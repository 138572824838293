import { FilterOption } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { DocumentStatus } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const availableFilters: Array<keyof DocumentsTableData> = [
  'status',
  'contact',
  'costCenter',
  'costObject',
  'extraCostInfo',
  'artistSocialInsuranceCode',
  'creditCardPayment',
  'tags',
  'invoiceDate',
  'deliveryDate',
  'dueDate',
  'discountDateWPercentage',
  'createdAt',
  'accountsPayableNumber',
  'accountsReceivableNumber',
] as const;

export const useStatusFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const [visualiseWaitingForClarificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.visualiseWaitingForClarification,
  ]);

  const { showConsistentSortAndFiltering } = useEcm();

  const statusFilters = useMemo(
    () => ({
      data: Array<FilterOption | false>(
        // TODO: Remove this once we support isDuplicate filter in new ES index
        !showConsistentSortAndFiltering && {
          id: 'DUPLICATE',
          label: t('statuses.duplicate'),
        },
        {
          id: DocumentStatus.New,
          label: t('statuses.new'),
        },
        {
          id: 'OVERDUE',
          label: t('statuses.overdue'),
        },
        {
          id: DocumentStatus.Rejected,
          label: t('statuses.rejected'),
        },
        !showConsistentSortAndFiltering &&
          visualiseWaitingForClarificationFF && {
            id: 'WAITING_FOR_CLARIFICATION',
            label: t('statuses.waitingForClarification'),
          }
      ).filter(Boolean),
    }),
    [t, visualiseWaitingForClarificationFF, showConsistentSortAndFiltering]
  );

  return statusFilters;
};
