import {
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ALPHA2_EUROPEAN_COUNTRIES } from 'views/CreditCards/CreditCardsInsights/OrganizationSettingsDrawer/utils/consts';
import { CredCardApplicationFormValues } from './toolkit/schema';

const MIN_MONTHLY_CARD_LIMIT = 5000;

export interface CompanyInfoStepProps {
  onNextStep: () => void;
}

export const CompanyInfoStep = ({ onNextStep }: CompanyInfoStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { watch, trigger, clearErrors } =
    useFormContext<CredCardApplicationFormValues>();

  const monthlyCardLimit = watch('monthlyCardLimit');

  const countriesOptions = useMemo(
    () =>
      Object.entries(ALPHA2_EUROPEAN_COUNTRIES).map(([key, value]) => ({
        key: key,
        children: t(value),
      })),
    [t]
  );

  const isBelowMinimumCardLimit = Boolean(
    monthlyCardLimit && Number(monthlyCardLimit) < MIN_MONTHLY_CARD_LIMIT
  );

  const handleValidation = async () => {
    await trigger(
      [
        'companyName',
        'annualRevenue',
        'zipCode',
        'city',
        'employeeCount',
        'foundingYear',
        'address',
        'country',
        'authorizedRepresentativeFirstName',
        'authorizedRepresentativeLastName',
        'authorizedRepresentativeEmail',
        'monthlyCardLimit',
      ],
      { shouldFocus: true }
    ).then(isValid => {
      if (isValid) {
        clearErrors();
        onNextStep();
      }
    });
  };

  return (
    <Grid>
      <Text color="gray800" fontSize="xlarge" fontWeight="semibold">
        {t('modal.form.companyInfoForm.companyInfoTitle')}
      </Text>
      <Flex direction="column" gap="space16" paddingTop="space12">
        <Grid templateColumns="1fr 1fr" rows={2} gap="space16">
          <HookFormTextField
            name="companyName"
            label={t('modal.form.companyInfoForm.companyName.label')}
            placeholder={t(
              'modal.form.companyInfoForm.companyName.placeholder'
            )}
            autoFocus
          />
          <HookFormAmountField
            name="annualRevenue"
            currency="EUR"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            minValue={0}
            label={t('modal.form.companyInfoForm.annualRevenue.label')}
            placeholder={t(
              'modal.form.companyInfoForm.annualRevenue.placeholder'
            )}
          />
          <HookFormTextField
            name="zipCode"
            label={t('modal.form.companyInfoForm.zipCode.label')}
            placeholder={t('modal.form.companyInfoForm.zipCode.placeholder')}
          />
          <HookFormTextField
            name="city"
            label={t('modal.form.companyInfoForm.city.label')}
            placeholder={t('modal.form.companyInfoForm.city.placeholder')}
          />
        </Grid>
        <Grid templateColumns="1fr 1fr" rows={2} gap="space16">
          <HookFormTextField
            name="employeeCount"
            label={t('modal.form.companyInfoForm.employeeCount.label')}
            placeholder={t(
              'modal.form.companyInfoForm.employeeCount.placeholder'
            )}
          />
          <HookFormTextField
            name="foundingYear"
            label={t('modal.form.companyInfoForm.foundingYear.label')}
            placeholder={t(
              'modal.form.companyInfoForm.foundingYear.placeholder'
            )}
          />
          <HookFormTextField
            name="address"
            label={t('modal.form.companyInfoForm.address.label')}
            placeholder={t('modal.form.companyInfoForm.address.placeholder')}
          />
          <HookFormSelectField
            name="country"
            label={t('modal.form.companyInfoForm.country.label')}
            placeholder={t('modal.form.companyInfoForm.country.placeholder')}
            items={countriesOptions}
          />
        </Grid>
      </Flex>
      <Flex
        direction="column"
        gap="space16"
        paddingTop="space12"
        paddingBottom="space24"
      >
        <Text color="gray800" fontSize="xlarge" fontWeight="semibold">
          {t('modal.form.companyInfoForm.managingPersonInfoTitle')}
        </Text>
        <Grid templateColumns="1fr 1fr" rows={2} gap="space16">
          <HookFormTextField
            name="authorizedRepresentativeFirstName"
            label={t(
              'modal.form.companyInfoForm.authorizedRepresentativeFirstName.label'
            )}
            placeholder={t(
              'modal.form.companyInfoForm.authorizedRepresentativeFirstName.placeholder'
            )}
          />
          <HookFormTextField
            name="authorizedRepresentativeLastName"
            label={t(
              'modal.form.companyInfoForm.authorizedRepresentativeLastName.label'
            )}
            placeholder={t(
              'modal.form.companyInfoForm.authorizedRepresentativeLastName.placeholder'
            )}
          />
          <HookFormTextField
            name="authorizedRepresentativeEmail"
            label={t(
              'modal.form.companyInfoForm.authorizedRepresentativeEmail.label'
            )}
            placeholder={t(
              'modal.form.companyInfoForm.authorizedRepresentativeEmail.placeholder'
            )}
          />
        </Grid>
      </Flex>
      <Text color="gray800" fontSize="xlarge" fontWeight="semibold">
        {t('modal.form.companyInfoForm.creditCardInfoTitle')}
      </Text>
      <Flex direction="column" gap="space16" paddingTop="space12">
        <Grid templateColumns="1fr 1fr" rows={1} gap="space16">
          <Flex direction="column" gap="space4">
            <HookFormAmountField
              name="monthlyCardLimit"
              currency="EUR"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              minValue={0}
              label={t('modal.form.companyInfoForm.monthlyCardLimit.label')}
              placeholder={t(
                'modal.form.companyInfoForm.monthlyCardLimit.placeholder'
              )}
              infoTooltip={
                isBelowMinimumCardLimit
                  ? t('modal.form.companyInfoForm.monthlyCardLimit.hint')
                  : undefined
              }
            />
          </Flex>
          <HookFormTextField
            name="paymentFrequency"
            label={t('modal.form.companyInfoForm.paymentFrequency.label')}
            readOnly
          />
        </Grid>
        <Card background="bluebg">
          <Grid templateColumns="auto 1fr" gap="space8">
            <Icon
              icon="infoCircle"
              color="blue500"
              size="space12"
              style={{ marginTop: '1px' }}
            />
            <Paragraph>
              {t('modal.form.companyInfoForm.paymentFrequency.info')}
            </Paragraph>
          </Grid>
        </Card>
      </Flex>
      <Flex gap="space16" justifyContent="end" paddingTop="space20">
        <Button onClick={handleValidation}>
          {t('modal.form.companyInfoForm.ctaNextButton')}
        </Button>
      </Flex>
    </Grid>
  );
};
