import styles from './UserListItem.module.css';
import { CardHolderCCInsightsWidgetData } from 'views/CreditCards/hooks/useTransactionsInsights';
import { NavLink } from 'react-router-dom-v5-compat';
import { usePath } from 'hooks/usePath';
import { Avatar } from '@candisio/design-system';

export const UserListItem = ({
  cardholderInsight,
}: { cardholderInsight: CardHolderCCInsightsWidgetData }) => {
  const { cardholder, invoicesWithoutTransaction } = cardholderInsight;

  const fullName = `${cardholder.firstName} ${cardholder.lastName}`;

  const href = usePath({
    pathname: '/inbox/transactions',
    search: {
      cardholderName: cardholder.id,
    },
  });

  return (
    <NavLink className={styles.item} to={href}>
      <Avatar
        size="small"
        img={cardholder.avatarUrl}
        hasTooltip={false}
        name={fullName}
      />
      <div>
        <p className={styles.name}>{fullName}</p>
        <p className={styles.email}>{cardholder.membershipEmail}</p>
      </div>
      <span className={styles.count}>{invoicesWithoutTransaction}</span>
    </NavLink>
  );
};
