import { UserRole } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { useInitialExportUrl } from 'views/Integrations/Export/Manifest';

export type MenuLinkItem = {
  title: string;
  url: string;
  allowedRoles: UserRole[];
  showMenuItem?: boolean;
};

export const useMenuTopLinks = (): MenuLinkItem[] => {
  const { ft, show } = useFeatureToggle();
  const showSepaXML = show(ft.SEPA_XML);

  const exportViewDef = useInitialExportUrl();

  const links: MenuLinkItem[] = useMemo(
    () => [
      {
        title: 'navigation.dashboard',
        url: Routes.DASHBOARD,
        allowedRoles: [
          UserRole.Requester,
          UserRole.Admin,
          UserRole.Approver,
          UserRole.Accountant,
        ],
      },
      {
        title: 'navigation.inbox',
        url: Routes.INBOX,
        allowedRoles: [
          UserRole.Requester,
          UserRole.Admin,
          UserRole.Approver,
          UserRole.Accountant,
        ],
      },
      {
        title: 'navigation.approvals',
        url: Routes.APPROVALS,
        allowedRoles: [
          UserRole.Requester,
          UserRole.Admin,
          UserRole.Approver,
          UserRole.Accountant,
        ],
      },
      {
        title: 'navigation.payments',
        url: Routes.PAYMENTS,
        allowedRoles: showSepaXML ? [UserRole.Admin, UserRole.Accountant] : [],
      },
      {
        title: 'navigation.export',
        url: exportViewDef.url || Routes.EXPORTS,
        allowedRoles: [UserRole.Admin, UserRole.Accountant],
      },
      {
        title: 'navigation.archive',
        url: Routes.ARCHIVE,
        allowedRoles: [
          UserRole.Requester,
          UserRole.Admin,
          UserRole.Approver,
          UserRole.Accountant,
        ],
      },
    ],
    [exportViewDef.url, showSepaXML]
  );

  return links.filter(item => item.showMenuItem !== false);
};

export const useMenuBottomLinks = (): MenuLinkItem[] => {
  const organization = useFullOrganization();
  const { isAdmin, isCardManager, isAccountant } = useUserRoles();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const showCreditCardMenuItem = Boolean(
    organization?.creditCardsDetails && showAllExtraFeatures
  );

  const creditCardsUrl =
    isAdmin || isCardManager || isAccountant
      ? Routes.CREDIT_CARDS_DASHBOARD
      : Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW;

  const links: MenuLinkItem[] = useMemo(
    () => [
      {
        title: 'navigation.travelAndExpense',
        url: Routes.REIMBURSEMENTS_DASHBOARD,
        allowedRoles: [
          UserRole.Admin,
          UserRole.CardManager,
          UserRole.Approver,
          UserRole.Requester,
          UserRole.Accountant,
        ],
      },
      {
        showMenuItem: showCreditCardMenuItem,
        title: 'navigation.creditCards',
        url: creditCardsUrl,
        allowedRoles: [
          UserRole.CardManager,
          UserRole.Admin,
          UserRole.Accountant,
          UserRole.Requester,
          UserRole.Approver,
        ],
      },
      {
        title: 'navigation.contacts',
        url: Routes.CONTACTS,
        allowedRoles: [UserRole.Admin, UserRole.Requester, UserRole.Accountant],
      },
      {
        title: 'navigation.settings',
        url: `${Routes.SETTINGS}${Routes.ORGANIZATION}`,
        allowedRoles: [UserRole.Admin, UserRole.Accountant],
      },
    ],
    [creditCardsUrl, showCreditCardMenuItem]
  );

  return links.filter(item => item.showMenuItem !== false);
};

export const useAllMenuLinks = () => {
  const visibleMenuTopLinks = useMenuTopLinks();
  const visibleMenuBottomLinks = useMenuBottomLinks();

  const allMenuLinks = useMemo(
    () => [...visibleMenuTopLinks, ...visibleMenuBottomLinks],
    [visibleMenuTopLinks, visibleMenuBottomLinks]
  );

  return allMenuLinks;
};
