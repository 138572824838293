import {
  Button,
  Flex,
  Grid,
  Paragraph,
  SectionHeading,
} from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { InvalidEInvoiceWarning } from 'components/Form/ProcessingForm/InvalidEInvoiceWarning';
import { useUserRoles } from 'hooks/useUserRoles';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { FieldPath, UseFormReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';
import { ProcessingFormSubmitErrors } from './ProcessingForm';
import { ProcessingFormValues } from './processingFormSchema';
import { InfoPopupButton } from '@candisio/design-system';

export interface ProcessingFormActionsProps {
  /** Modus operandi */
  mode?: 'request' | 'duplicate' | 'edit-request' | 're-request';
  /** Called when document should be (fast) approved  */
  onApprove: (
    values: ProcessingFormValues
  ) => Promise<ProcessingFormSubmitErrors | void>;
  /** Called when editing request should be canceled  */
  onCancelEdit?: () => void;
  /** Called when document should be deleted */
  onDeleteDocument?: () => Promise<void>;
  /** Called when duplicate comparison view should be shown */
  onCompareWithOriginal?: () => void;
  /** Document ID */
  documentId: string;
}

/**
 * Actions for processing form:
 * - Request approval (submit)
 * - Delete document
 * - Compare with original (for duplicate documents)
 * - Cancel edit (for documents already in approval)
 * - (Fast) approve
 *
 **/
export const ProcessingFormActions = ({
  mode = 'request',
  onCancelEdit,
  onCompareWithOriginal,
  onApprove,
  onDeleteDocument,
  documentId,
}: ProcessingFormActionsProps) => {
  const [t] = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [approving, setApproving] = useState(false);
  const { showConversionNeededWarning } = useDocumentTypeContext();

  const { isOnlyRequester, isOnlyAccountant } = useUserRoles();

  const processingForm = useFormContext<ProcessingFormValues>() as
    | UseFormReturn<ProcessingFormValues>
    | undefined; // can be undefined if rendered outside of the form

  const isSubmitting = processingForm?.formState.isSubmitting;
  const isSubmitted = processingForm?.formState.isSubmitted;

  const errors = processingForm?.formState.errors;
  const hasErrors = !isEmpty(errors);

  const approve = processingForm?.handleSubmit(async values => {
    const submitErrors = await onApprove(values);
    if (submitErrors) {
      Object.entries(submitErrors).forEach(([path, message], index) => {
        processingForm?.setError(
          path as FieldPath<ProcessingFormValues>,
          { message },
          { shouldFocus: index === 0 } // focus first field with error
        );
      });
    }
  });

  const approveButton = (
    <ConfirmationButton
      flex="1 1 0%"
      minWidth="max-content"
      color="blue"
      loading={approving}
      variant="secondary"
      confirmationTitle={t(
        'document.approveDocument.fastApproval.confirmationPopover.title'
      )}
      confirmButtonProps={{ color: 'blue' }}
      confirmText={t(
        'document.approveDocument.fastApproval.confirmationPopover.actions.yes'
      )}
      onConfirm={async () => {
        setApproving(true);
        processingForm?.setValue('mode', 'approve');
        await approve?.();
        setApproving(false);
      }}
    >
      {t('document.approveDocument.fastApproval.approve')}
    </ConfirmationButton>
  );

  const deleteButton = (
    <ConfirmationButton
      flex="1 1 0%"
      minWidth="max-content"
      color="red"
      disabled={isSubmitting || approving}
      loading={deleting}
      variant="secondary"
      confirmationTitle={t('document.delete')}
      onConfirm={async () => {
        setDeleting(true);
        await onDeleteDocument?.();
        setDeleting(false);
      }}
    >
      {t('document.requestApproval.actions.remove')}
    </ConfirmationButton>
  );

  return (
    <Grid gap="space8">
      <Grid
        autoFlow="column"
        gap="space4"
        alignItems="center"
        justifyContent="space-between"
      >
        <SectionHeading as="h2">
          {t('document.requestApproval.actions.title')}
        </SectionHeading>
        {mode === 'edit-request' && (
          <Button
            onClick={onCancelEdit}
            size="small"
            variant="tertiary"
            disabled={isSubmitting}
          >
            {t('document.requestApproval.actions.cancelEdit')}
          </Button>
        )}
      </Grid>

      <InvalidEInvoiceWarning documentId={documentId} />

      {isSubmitted && hasErrors && (
        <Paragraph color="red500">
          {errors.root?.message ??
            t('document.requestApproval.inputs.errors.generic')}
        </Paragraph>
      )}

      {mode === 'duplicate' && (
        <div className="flex gap-1 items-center">
          <Paragraph color="yellow500">
            {t('document.requestApproval.duplicationAlert.hint')}
          </Paragraph>
          <InfoPopupButton
            message={t('document.requestApproval.duplicationAlert.hintInfo')}
          />
        </div>
      )}

      {mode === 'duplicate' ? (
        <Button
          color="yellow"
          disabled={deleting}
          onClick={onCompareWithOriginal}
        >
          {t('document.requestApproval.duplicationAlert.resolve')}
        </Button>
      ) : (
        <Button
          color="blue"
          disabled={deleting || approving || showConversionNeededWarning}
          loading={isSubmitting && !approving}
          type="submit"
        >
          {mode === 're-request'
            ? t('document.requestApproval.actions.requestApprovalAgainRejected')
            : t('document.requestApproval.actions.requestApproval')}
        </Button>
      )}

      {mode === 'duplicate' ? (
        deleteButton
      ) : (
        <Flex gap="space8" wrap="wrap">
          {!isOnlyRequester && !isOnlyAccountant && approveButton}
          {deleteButton}
        </Flex>
      )}
    </Grid>
  );
};
