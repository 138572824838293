import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { ComponentProps, useState, useCallback, type ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContractCustomSubCategoryDetailsDrawer } from 'views/Settings/ContractSubCategories/elements/Drawer/ContractCustomSubCategoryDetailsDrawer';
import { AddContractTypeActionBox } from '../AddActionBox/AddContractTypeActionBox';
import { StorageFormComboBoxField } from './StorageFormComboBoxField';
import { StorageFormValues } from './storageFormSchema';

type StorageFormContractTypeFieldProps = ComponentProps<
  typeof StorageFormComboBoxField
>;

export const StorageFormContractTypeField = ({
  name,
  label,
  readOnly,
  defaultItems,
}: StorageFormContractTypeFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openNewContractTypeDrawer = useCallback(
    () => setIsDrawerOpen(true),
    []
  );
  const closeNewContractTypeDrawer = useCallback(
    () => setIsDrawerOpen(false),
    []
  );

  const [inputValue, setInputValue] = useState('');
  const { setValue } = useFormContext<StorageFormValues>();

  const { isAdmin, isAccountant } = useUserRoles();
  const canManageContractTypes = isAdmin || isAccountant;

  let emptyListPlaceholder;
  let renderCustomDropdown;

  if (canManageContractTypes) {
    emptyListPlaceholder = (
      <AddContractTypeActionBox onPointerDown={openNewContractTypeDrawer} />
    );

    renderCustomDropdown = (list: ReactNode) => (
      <>
        {list}
        <AddContractTypeActionBox onPointerDown={openNewContractTypeDrawer} />
      </>
    );
  }

  return (
    <>
      <StorageFormComboBoxField
        allowsCustomValue
        placeholder={t('storageForm.fields.contractType.placeholder')}
        onInputChange={setInputValue}
        renderCustomDropdown={renderCustomDropdown}
        emptyListPlaceholder={emptyListPlaceholder}
        name={name}
        label={label}
        readOnly={readOnly}
        defaultItems={defaultItems}
      />

      <ContractCustomSubCategoryDetailsDrawer
        compact
        isOpen={isDrawerOpen}
        onCloseDrawer={closeNewContractTypeDrawer}
        defaultName={inputValue}
        onSubCategoryCreated={subCategory => {
          setValue(name, subCategory.id);
        }}
      />
    </>
  );
};
