import { CustomEmptyStateProps, Link } from '@candisio/design-system';
import {
  LottieAnimation,
  noresultsData,
  successData,
} from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ApprovalEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

export const ApprovalEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: ApprovalEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  return (
    <div className="grid gap-4">
      <LottieAnimation
        options={{
          animationData: isTableFiltered ? noresultsData : successData,
        }}
      />
      <div className="flex flex-col max-w-64 items-center">
        {isFilteredAndEmpty ? (
          <>
            <p className="text-center whitespace-pre-wrap m-0">
              {t('emptyState.content.filtered')}
            </p>
            <Link as="button" onClick={() => resetFilters()}>
              {t('emptyState.resetFilters')}
            </Link>
          </>
        ) : (
          <p
            className="text-center font-medium"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {t('emptyState.content.allDoneInbox')}
          </p>
        )}
      </div>
    </div>
  );
};
