type FieldKeys =
  | 'expenseDate'
  | 'reimbursementItemId'
  | 'files'
  | 'reason'
  | 'totalAmount'
  | 'itemStatus'
  | 'invoiceNumber'
  | 'isItemExcluded'
  | 'isExtractedDataAccepted'
  | 'extractedData';

export type FieldNames = {
  [K in FieldKeys]: `reimbursementItems.${number}.${K}`;
};

export const generateGeneralExpenseFormFieldNames = (
  index: number
): FieldNames => ({
  expenseDate: `reimbursementItems.${index}.expenseDate`,
  reimbursementItemId: `reimbursementItems.${index}.reimbursementItemId`,
  files: `reimbursementItems.${index}.files`,
  reason: `reimbursementItems.${index}.reason`,
  totalAmount: `reimbursementItems.${index}.totalAmount`,
  itemStatus: `reimbursementItems.${index}.itemStatus`,
  invoiceNumber: `reimbursementItems.${index}.invoiceNumber`,
  isItemExcluded: `reimbursementItems.${index}.isItemExcluded`,
  isExtractedDataAccepted: `reimbursementItems.${index}.isExtractedDataAccepted`,
  extractedData: `reimbursementItems.${index}.extractedData`,
});
