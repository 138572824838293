import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';

export const RotateButton = () => {
  const [t] = useTranslation();
  const { setPdfView } = usePdfViewProvider();

  return (
    <Button
      icon="rotateLeft"
      label={t('document.rotateLeft')}
      onClick={() => setPdfView('rotation', current => (current - 90) % 360)}
      variant="tertiary"
      size="small"
    />
  );
};
