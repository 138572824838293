import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  Row,
  SortingState,
} from '@tanstack/react-table';
import styles from './Table.module.css';
import { Cell } from './components/Cell';
import {
  ComponentProps,
  CSSProperties,
  memo,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import clsx from 'clsx';
import { Button } from '../../../Atoms/Button/Button';
import { handleUpdateRowOverlayPosition } from './handleUpdateRowOverlayPosition';
import { Spinner } from '../../../Atoms/Spinner';
import { EmptyStateLayout } from './components/EmptyStateLayout';
import { TableRowContent } from './components/TableRowContent';
import { TableRow } from './components/TableRow';
import { InitialDataLoadingSpinner } from './components/InitialDataLoadingSpinner';
import { BaseTableDataType } from './components/types';

const MemoizedButton = memo(Button);

export const HEADER_CLASS_NAME = 'table-header';

type TanstackTableConfig<T extends BaseTableDataType> = Parameters<
  typeof useReactTable<T>
>[0];

export interface TableProps<T extends BaseTableDataType>
  extends ComponentProps<'table'> {
  data: T[];
  columns: TanstackTableConfig<T>['columns'];
  defaultColumn?: TanstackTableConfig<T>['defaultColumn'];
  onEndReached?: ComponentProps<typeof TableVirtuoso>['endReached'];
  overscan?: ComponentProps<typeof TableVirtuoso>['overscan'];
  onRowClick?: (row: T) => void;
  CellWrapper?: (props: { row: T; children?: ReactNode }) => JSX.Element;
  sorting?: SortingState;
  setSorting?: TanstackTableConfig<T>['onSortingChange'];
  rowOverlay?: (props: { data: T }) => JSX.Element;
  isLoading?: boolean;
  emptyState?: ReactNode;
  columnVisibility?: NonNullable<
    TanstackTableConfig<T>['state']
  >['columnVisibility'];
  columnOrder?: NonNullable<TanstackTableConfig<T>['state']>['columnOrder'];
  rowSelection?: NonNullable<TanstackTableConfig<T>['state']>['rowSelection'];
  onRowSelectionChange?: TanstackTableConfig<T>['onRowSelectionChange'];
  enableRowSelection?: TanstackTableConfig<T>['enableRowSelection'];
  enableMultiRowSelection?: TanstackTableConfig<T>['enableMultiRowSelection'];
}

export function Table<T extends BaseTableDataType>({
  data,
  columns,
  defaultColumn: defaultColumnProp,
  onEndReached,
  overscan = 0,
  onRowClick,
  CellWrapper,
  sorting,
  setSorting,
  rowOverlay,
  isLoading,
  emptyState = <EmptyStateLayout />,
  columnVisibility,
  columnOrder,
  rowSelection,
  onRowSelectionChange,
  enableRowSelection,
  enableMultiRowSelection,
  className,
}: TableProps<T>) {
  const hasRowOverlay = Boolean(rowOverlay);
  const defaultColumn = useMemo(
    () => ({
      cell: Cell,
      size: 128,
      ...defaultColumnProp,
    }),
    [defaultColumnProp]
  );

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    onRowSelectionChange,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
      rowSelection,
    },
    onSortingChange: setSorting,
    manualSorting: true,
    sortDescFirst: false,
    enableRowSelection,
    enableMultiRowSelection,
  });

  const { rows } = table.getRowModel();
  const headers = table.getHeaderGroups();

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO HACK: columnVisibility + columnOrder need to force rerender when TableRow is memoized
  const components: TableComponents<Row<T>> = useMemo(
    () => ({
      Table: props => (
        <table {...props} className={clsx(styles.table, className)} />
      ),
      TableRow: props => (
        <TableRow onRowClick={onRowClick} overlay={rowOverlay} {...props} />
      ),
    }),
    [onRowClick, rowOverlay, className, columnVisibility, columnOrder]
  );

  const isLoadingFirstData = rows.length < 1 && isLoading;
  const isLoadingNextPage = rows.length > 0 && isLoading;
  const isTableEmpty = rows.length === 0 && !isLoading;

  // biome-ignore lint/correctness/useExhaustiveDependencies: We need to add `sorting` in dependency array, becaue otherwise headers would not reflect latest state of sort. It could be a bug in table lib.
  const fixedHeaderContent = useCallback(() => {
    return headers.map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => {
          const isSorted = header.column.getIsSorted();
          const canSort = header.column.getCanSort();

          return (
            <th
              key={header.id}
              id={header.id}
              style={
                {
                  '--column-size': `${header.getSize()}px`,
                } as CSSProperties
              }
              colSpan={header.colSpan}
              className={styles['column-header']}
              onClick={header.column.getToggleSortingHandler()}
              data-sorted={Boolean(isSorted)}
              data-clickable={canSort}
            >
              <div
                className={clsx(
                  styles['column-header-cell'],
                  HEADER_CLASS_NAME
                )}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                {canSort && (
                  <MemoizedButton
                    icon={
                      isSorted
                        ? isSorted === 'desc'
                          ? 'caretDown'
                          : 'caretUp'
                        : 'sortOutlined'
                    }
                    label={
                      isSorted
                        ? isSorted === 'desc'
                          ? 'reset'
                          : 'desc'
                        : 'asc'
                    }
                    size="xsmall"
                    variant={isSorted ? 'primary' : 'secondary'}
                    color="blue"
                    className={styles['sort-button']}
                  />
                )}
              </div>
            </th>
          );
        })}
        {hasRowOverlay && (
          <th
            style={{
              padding: 0,
              width: 0,
              height: 0,
              maxWidth: 0,
              maxHeight: 0,
            }}
          ></th>
        )}
      </tr>
    ));
  }, [headers, hasRowOverlay, sorting]);

  const fixedFooterContent = useCallback(() => {
    return (
      <tr>
        <td>
          <div className="grid absolute bg-white p-5 left-[50%] -translate-x-1/2">
            <Spinner size="space64" color="gray400" />
          </div>
        </td>
      </tr>
    );
  }, []);

  return (
    <>
      <TableVirtuoso
        className={clsx(styles['table-virtuoso'])}
        data-loading-page={isLoadingNextPage}
        scrollerRef={hasRowOverlay ? handleUpdateRowOverlayPosition : undefined}
        data={rows}
        totalCount={rows.length}
        components={components}
        itemContent={(_, row) => (
          <TableRowContent row={row} CellWrapper={CellWrapper} />
        )}
        endReached={onEndReached}
        overscan={overscan}
        fixedHeaderContent={fixedHeaderContent}
        fixedFooterContent={fixedFooterContent}
        increaseViewportBy={100}
      />
      {isTableEmpty && (
        <div className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2">
          {emptyState}
        </div>
      )}
      {isLoadingFirstData && <InitialDataLoadingSpinner />}
    </>
  );
}
