import { useTranslation } from 'react-i18next';
import styles from './EmptyState.module.css';
import emptyStateIcon from 'assets/icon-empty-state.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const EmptyState = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <div className={styles.wrapper}>
      <img src={emptyStateIcon} alt="" className={styles.icon} />
      <h2 className={styles.title}>
        {t('insights.swimlanes.missingInvoices.emptyState.title')}
      </h2>
      <p className={styles.description}>
        {t('insights.swimlanes.missingInvoices.emptyState.description')}
      </p>
    </div>
  );
};
