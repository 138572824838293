import { Grid, useTheme } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { CreateExpenseActionMenuButton } from '../CreateExpenseActionMenuButton';
import { ReimbursementItemsSummary } from './ReimbursementItemsSummary';
import { ReimbursementItemSummaryCard } from './ReimbursementItemSummaryCard';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { ReimbursementItemType } from 'generated-types/graphql.types';

interface ExpenseDetailsCardWithActionProps {
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
  isPromotion?: boolean;
  reimbursementItems?: ReimbursementItemForList[];
}

export const ExpenseDetailsCardWithAction = ({
  creatingExpense,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  isPromotion = false,
  reimbursementItems = [],
}: ExpenseDetailsCardWithActionProps) => {
  const { radii } = useTheme();
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const filteredExpenseItems = (reimbursementItems ?? []).filter(item =>
    [
      ReimbursementItemType.GeneralExpense,
      ReimbursementItemType.HospitalityExpense,
    ].includes(item.type)
  );

  // XXX : This value should be coming from BE since amounts could be in different currencies.
  // it will be fixed in here -> https://candis.atlassian.net/jira/software/projects/TA/boards/64/backlog?selectedIssue=TA-5219
  const totalAmount = filteredExpenseItems.reduce(
    (acc, value) => acc + (value.totalAmount?.amount ?? 0),
    0
  );

  const expensesCount = filteredExpenseItems.length ?? undefined;

  const formattedTotalAmount = transactionAmountFormatter({
    precision: 2,
    currency: 'EUR',
    value: totalAmount,
  });

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!filteredExpenseItems.length) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
  };

  const hasExpenses = !!filteredExpenseItems.length;

  return (
    <ReimbursementItemSummaryCard
      count={expensesCount}
      formattedTotalAmount={
        expensesCount > 0 ? formattedTotalAmount : undefined
      }
      isClickable={!!expensesCount}
      description={t(
        'reimbursementView.middleSection.emptyDetails.description'
      )}
      title={t('reimbursementView.middleSection.emptyDetails.title')}
      icon="receipt"
      iconColor="green"
      onClick={handleClick}
      customListItemsWithCta={
        <div
          className="grid bg-white"
          style={{
            borderBottomLeftRadius: radii.medium,
            borderBottomRightRadius: radii.medium,
          }}
        >
          {hasExpenses && (
            <ReimbursementItemsSummary
              reimbursementItems={filteredExpenseItems}
            />
          )}
          <Grid
            placeItems="center"
            paddingY="space16"
            borderTop={hasExpenses ? '1px solid gray200' : 'none'}
          >
            <CreateExpenseActionMenuButton
              count={expensesCount}
              creatingExpense={creatingExpense}
              onGeneralExpenseClick={onGeneralExpenseClick}
              onHospitalityExpenseClick={onHospitalityExpenseClick}
              isPromotion={isPromotion}
            />
          </Grid>
        </div>
      }
    />
  );
};
