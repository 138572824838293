import { useApprovalTabNavigationData } from './useApprovalTabNavigationData';
import { useApprovalTabNavigationDataDeprecated } from './useApprovalTabNavigationDataDeprecated';
import { useEcm } from 'orgConfig/ecm/useEcm';

export const useApprovalData = ({ skip = false }: { skip?: boolean } = {}) => {
  const { showConsistentSortAndFiltering } = useEcm();

  const deprecatedData = useApprovalTabNavigationDataDeprecated({
    skip: showConsistentSortAndFiltering || skip,
  });

  const data = useApprovalTabNavigationData({
    skip: !showConsistentSortAndFiltering || skip,
  });

  return showConsistentSortAndFiltering
    ? {
        ...data,
        isLoading: data.approvalDocumentsBasicsQueryResult.loading,
        approvalDocumentsBasicsQueryResult:
          data.approvalDocumentsBasicsQueryResult.data
            ?.approvalInvoiceDocuments,
      }
    : {
        ...deprecatedData,
        isLoading: deprecatedData.approvalDocumentsBasicsQueryResult.loading,
        approvalDocumentsBasicsQueryResult:
          deprecatedData.approvalDocumentsBasicsQueryResult.data
            ?.listApprovalsDocuments,
      };
};
