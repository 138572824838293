import { useCallback, useState } from 'react';
import { Page, PageProps } from 'react-pdf';
import { usePdfViewProvider } from '../PdfViewProvider';
import { useDocumentPages } from './useDocumentPages';
import { usePageVisibility } from './usePageVisibility';
import { getPageId, intersectionOptions } from './utils';

interface PageWrapperProps
  extends Pick<
    ReturnType<typeof useDocumentPages>,
    'handleChangePageVisibility'
  > {
  pageNumber: number;
  pageNumberInTotal: number;
  uniqueId: string;
}

export const PageWrapper = ({
  pageNumber,
  handleChangePageVisibility,
  pageNumberInTotal,
  uniqueId,
}: PageWrapperProps) => {
  const { usePdfView } = usePdfViewProvider();
  const { width, height } = usePdfView('size');
  const rotation = usePdfView('rotation');
  const scale = usePdfView('scale');
  const fitType = usePdfView('fitType');
  const [rotationOffset, setRotationOffset] = useState(0);

  const handleOnIntersecting = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      handleChangePageVisibility(pageNumberInTotal, entry.intersectionRatio);
    },
    [handleChangePageVisibility, pageNumberInTotal]
  );

  const { ref } = usePageVisibility({
    onIntersecting: handleOnIntersecting,
    options: intersectionOptions,
  });

  const handlePageLoadSuccess: NonNullable<PageProps['onLoadSuccess']> =
    useCallback(page => setRotationOffset(page.rotate), []);

  return (
    <div id={getPageId(uniqueId, pageNumberInTotal)} ref={ref}>
      <Page
        pageNumber={pageNumber}
        width={fitType === 'width' ? width : undefined}
        height={fitType === 'height' ? height : undefined}
        scale={scale}
        rotate={rotation + rotationOffset}
        loading={null}
        onLoadSuccess={handlePageLoadSuccess}
      />
    </div>
  );
};
