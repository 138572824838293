import { flexRender, Table } from '@tanstack/react-table';
import { useMemo } from 'react';
import { PerDiemDayCalculationDataProps } from 'views/Reimbursement/hooks/usePerDiemItemCalculation';
import styles from './PerDiemCalculationTable.module.css';
import { LottieAnimation } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface PerDiemCalculationTableProps {
  tableInstance: Table<PerDiemDayCalculationDataProps>;
  totalReimbursableRate?: string;
}

export const PerDiemCalculationTable = ({
  tableInstance,
  totalReimbursableRate,
}: PerDiemCalculationTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { headerGroups, rows, numOfColumns } = useMemo(() => {
    return {
      numOfColumns: tableInstance.getAllColumns().length,
      headerGroups: tableInstance.getHeaderGroups(),
      rows: tableInstance.getRowModel().rows,
    };
  }, [tableInstance]);

  if (rows?.length === 0) {
    return (
      <div className="grid place-items-center h-[15rem] w-[56vw]">
        <div className="text-center">
          <LottieAnimation animationData="nocontactData" />
          <span>
            {t(
              'reimbursementView.middleSection.form.perDiem.calculationTable.emptyTable'
            )}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} className={styles.headerCell}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.id} className={styles.tableRow}>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={styles.tableCell}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            <tr className={styles.tableRow}>
              <td colSpan={numOfColumns - 1} className={styles.tableCell} />
              <td className={styles.totalAmount}>{totalReimbursableRate}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
