import { IconKey, Link, LinkProps } from '@candisio/design-system';
import { ReactNode } from 'react';

interface BasicLink {
  id?: string;
  icon?: IconKey;
  iconPosition?: LinkProps['iconPosition'];
  onClick?: () => void;
  variant?: IntercomLinkVariants;
  style?: LinkProps['style'];
}

interface LinkWithTranslation extends BasicLink {
  translation: string;
}

interface LinkWithChildren extends BasicLink {
  children: ReactNode;
}

type IntercomLinkProps = LinkWithTranslation | LinkWithChildren;

type IntercomLinkVariants = 'default' | 'noIcon';

export const IntercomLink = ({
  variant = 'default',
  onClick,
  icon = 'mail',
  iconPosition = 'right',
  id,
  style,
  ...props
}: IntercomLinkProps) => {
  const handleIntercomClick = () => {
    onClick?.();
    window.Intercom('showSpace', 'messages');
  };

  const linkText = 'children' in props ? props.children : props.translation;

  return (
    <Link
      as="button"
      icon={variant === 'noIcon' ? undefined : icon}
      id={id}
      iconPosition={iconPosition}
      onClick={!id ? handleIntercomClick : handleIntercomClick}
      textAlign="left"
      style={style}
    >
      {linkText}
    </Link>
  );
};
