import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import {
  DocumentCurrency,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { ReimbursementItemSummaryCard } from './ReimbursementItemSummaryCard';

interface PerDiemDetailsCardProps {
  reimbursementItems?: ReimbursementItemForList[];
  creatingPerDiem?: boolean;
  onCreatePerDiem?: () => void;
}

export const PerDiemDetailsCard = ({
  reimbursementItems = [],
  creatingPerDiem = false,
  onCreatePerDiem,
}: PerDiemDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { setSelectedItemId } = useReimbursementFormsContext();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const perDiem = reimbursementItems.find(
    item => item.type === ReimbursementItemType.PerDiem
  );

  const { id, totalAmount } = perDiem ?? {};

  const formattedTotalAmount = !!id
    ? transactionAmountFormatter({
        value: totalAmount?.amount ?? 0,
        precision: totalAmount?.precision ?? 2,
        currency: totalAmount?.currency ?? DocumentCurrency.Eur,
      })
    : undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!id) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    setSelectedItemId(id);
  };

  return (
    <ReimbursementItemSummaryCard
      isClickable={!!id}
      iconColor="blue"
      icon="calendarDays"
      formattedTotalAmount={formattedTotalAmount}
      onClick={handleClick}
      description={t(
        'reimbursementView.middleSection.form.perDiem.description'
      )}
      title={t('reimbursementView.middleSection.form.perDiem.title')}
      ctaTranslation={t(
        'reimbursementView.middleSection.form.perDiem.callToAction'
      )}
      isCtaDisabled={!!id}
      onAddNew={onCreatePerDiem}
      isLoading={creatingPerDiem}
    />
  );
};
