import { Popover, usePopover } from '@candisio/design-system';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { useTranslation } from 'react-i18next';
import { NavigationIcon, NavigationIconType } from '../../NavigationIcon';
import { MenuErrorIndicator } from './components/MenuErrorIndicator';
import { MenuSpinner } from './components/MenuSpinner';
import { MenuDocumentUploadPopoverContent } from './components/PopoverContent';
import { useFileUploadPolling } from './hooks/useFileUploadPolling';
import { useProcessingToast } from './hooks/useProcessingToast';

type Props = Pick<NavigationIconType, 'expanded'>;

export const MenuDocumentUpload = ({ expanded }: Props) => {
  const [t] = useTranslation('navigation');
  const { useLayoutValue, setLayoutValue } = useAppLayout();
  const { open: isOpen } = useLayoutValue('docUploadPopover');
  const { popoverProps, popoverRef, triggerProps, triggerRef } = usePopover({
    isOpen,
    onOpenChange: open => setLayoutValue('docUploadPopover', { open }),
    placement: 'right top',
  });

  useProcessingToast();
  useFileUploadPolling();

  return (
    <>
      <NavigationIcon
        expanded={expanded}
        isActive={isOpen}
        icon="menuUpload"
        label={t('documentUpload.header')}
        ref={triggerRef}
        showLabelInTooltip={!isOpen}
        {...triggerProps}
      >
        <div className="flex gap-2 empty:hidden">
          <MenuErrorIndicator />
          {expanded && <MenuSpinner />}
        </div>
      </NavigationIcon>
      {isOpen && (
        <Popover
          className="w-[26rem] flex flex-col gap-4 max-h-[calc(100vh-1.5rem)]!"
          padding={24}
          showArrow={false}
          {...popoverProps}
          ref={popoverRef}
        >
          <MenuDocumentUploadPopoverContent />
        </Popover>
      )}
    </>
  );
};
