import { Flex, Image } from '@candisio/design-system';
import {
  ExportStatus,
  ExportType as GqlExportType,
  useExportDatevBdsMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDatev } from 'orgConfig/datev';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportAvailableMonths } from 'views/Integrations/Export/toolkit/components/InitExportAvailableMonths';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { useExportContactsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportContactsFactory';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { useExportPaymentConditionsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportPaymentConditionsFactory';
import { useGetReadyToExportCountByTimeframe } from 'views/Integrations/Export/toolkit/hooks/useGetReadyToExportCountByTimeframe';
import { useSelectedTimeFrame } from 'views/Integrations/Export/toolkit/hooks/useSelectedTimeFrame';
import datevApiIcon from 'views/Integrations/Export/toolkit/images/icon-datev-api.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { getDatevExportableEntities } from 'views/Integrations/Export/toolkit/utils';
import { getTimeFrameInputFromTimeFrameOption } from 'views/Integrations/Export/toolkit/utils/timeframe';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { BdsExportButton } from './BdsExportButton';
import { useEcm } from 'orgConfig/ecm/useEcm';

const useGetExportableCoreDataCount = () => {
  const { includeAllContacts, includeAllPaymentConditions } =
    useContext(ExportContext);

  const contacts = useExportContactsFactory();
  const paymentConditions = useExportPaymentConditionsFactory();

  const { exportDatevBdsViaIntegrationSvcFF: isNewBdsExport } = useDatev();

  if (!isNewBdsExport) {
    return { newContacts: 0, newPaymentConditions: 0 };
  }

  return {
    newContacts: includeAllContacts
      ? contacts.count.all
      : contacts.count.newAndUpdated,
    newPaymentConditions: includeAllPaymentConditions
      ? paymentConditions.count.all
      : paymentConditions.count.newAndUpdated,
  };
};

export const BDS = () => {
  const state = useContext(ExportContext);
  const counterQueries = useCounterQueries();
  const { addExport } = useContext(ExportNotificationsContext);
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const { exportProvisionsFF, exportDatevBdsViaIntegrationSvcFF } = useDatev(); // BDS-checked

  const {
    exportType,
    exportId,
    selectedProvisions,
    includeEntitiesWithoutDoc,
    includeAllContacts,
    includeAllPaymentConditions,
  } = state;

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportAll,
    exportDetails,
  } = useExportDataGigaFactory(state);

  const { selectedTimeFrame, timeFrames } = useSelectedTimeFrame({
    entities: allEntities,
    exportAll,
    exportType,
    exportId,
  });

  const datevExportableEntities = getDatevExportableEntities(
    selectedTimeFrame,
    exportId ?? '',
    includeEntitiesWithoutDoc,
    exportProvisionsFF,
    selectedProvisions
  );

  const hasExportableEntity =
    exportAll ||
    !!datevExportableEntities.documentIds.length ||
    !!datevExportableEntities.provisionIds.length ||
    !!datevExportableEntities.reversalIds.length ||
    !!datevExportableEntities.transactionIds.length ||
    !!datevExportableEntities.cardSettlementIds.length ||
    !!datevExportableEntities.reimbursementItemsIds.length;

  const documentIds = datevExportableEntities.documentIds;
  const isFailedExport = exportDetails?.status === ExportStatus.Failed;
  const isNewBdsExport = exportDatevBdsViaIntegrationSvcFF;

  const { showConsistentSortAndFiltering } = useEcm();

  const [requestDatevBdsExport, { loading }] = useExportDatevBdsMutation({
    refetchQueries: [
      ...getRefetchExportsQueries({
        documentIds,
        enableNewESIndex: showConsistentSortAndFiltering,
      }),
      ...counterQueries,
    ],
    awaitRefetchQueries: true,
  });

  const onExport = async () => {
    const coreDataExport = isNewBdsExport && {
      exportAllContacts: includeAllContacts,
      exportAllPaymentConditions: includeAllPaymentConditions,
    };

    const { data } = await requestDatevBdsExport({
      variables: {
        documentIds: exportAll ? [] : datevExportableEntities.documentIds,
        transactionIds: exportAll ? [] : datevExportableEntities.transactionIds,
        cardSettlementIds: exportAll
          ? []
          : datevExportableEntities.cardSettlementIds,
        provisionIds: datevExportableEntities.provisionIds,
        reversalIds: datevExportableEntities.reversalIds,
        reimbursementIds: exportAll
          ? []
          : datevExportableEntities.reimbursementItemsIds,
        timeFrame: getTimeFrameInputFromTimeFrameOption(selectedTimeFrame),
        exportAll,
        originalExportGuid: isFailedExport ? undefined : exportId,
        ...coreDataExport,
      },
    });

    if (data?.exportDatevBds) {
      addExport({
        hash: data.exportDatevBds,
        organization: orgId ?? '',
        status: ExportStatus.Exporting,
        documents: exportAll
          ? selectedTimeFrame.numberOfExportableEntities
          : selectedTimeFrame.documentIds.length,
        type: GqlExportType.DatevBds,
      });

      const viewDef = defineExportView(
        {
          ...state,
          sidebar: Sidebar.HISTORY,
          view: View.HISTORY,
          exportId: data.exportDatevBds,
          shouldTriggerDownload: true,
        },
        { orgId }
      );

      navigate(viewDef.navigate);
    }
  };

  const { newContacts, newPaymentConditions } = useGetExportableCoreDataCount();
  const readyToExportEntitiesCountByTimeframe =
    useGetReadyToExportCountByTimeframe({
      selectedTimeFrame,
      entities: allEntities,
      contacts: newContacts,
      paymentConditions: newPaymentConditions,
    });

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={datevApiIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        {timeFrames.length > 0 && (
          <InitExportAvailableMonths
            exportType={exportType}
            timeFrames={timeFrames}
          />
        )}
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByTimeframe}
        />
      </Flex>
      <BdsExportButton
        loading={loading}
        hasExportableEntity={hasExportableEntity}
        entities={allEntities}
        onExport={onExport}
      />
    </Flex>
  );
};
