type FieldKeys =
  | 'reimbursementItemId'
  | 'segments'
  | 'itemStatus'
  | 'isItemExcluded'
  | 'currentStep'
  | 'days';

export type SegmentFieldKeys =
  | 'startDate'
  | 'endDate'
  | 'startTime'
  | 'endTime'
  | 'location';

export type FieldNames = {
  [K in FieldKeys]: `reimbursementItems.${number}.${K & string}`;
};

type SegmentFields = {
  [K in SegmentFieldKeys]: `reimbursementItems.${number}.segments.${number}.${K & string}`;
};

export const generatePerDiemFormFieldNames = (index: number): FieldNames => ({
  reimbursementItemId: `reimbursementItems.${index}.reimbursementItemId`,
  segments: `reimbursementItems.${index}.segments`,
  itemStatus: `reimbursementItems.${index}.itemStatus`,
  isItemExcluded: `reimbursementItems.${index}.isItemExcluded`,
  currentStep: `reimbursementItems.${index}.currentStep`,
  days: `reimbursementItems.${index}.days`,
});

export const generateSegmentFieldNames = (
  itemIndex: number,
  segmentIndex: number
): SegmentFields => ({
  startDate: `reimbursementItems.${itemIndex}.segments.${segmentIndex}.startDate`,
  endDate: `reimbursementItems.${itemIndex}.segments.${segmentIndex}.endDate`,
  startTime: `reimbursementItems.${itemIndex}.segments.${segmentIndex}.startTime`,
  endTime: `reimbursementItems.${itemIndex}.segments.${segmentIndex}.endTime`,
  location: `reimbursementItems.${itemIndex}.segments.${segmentIndex}.location`,
});

export const generateDaysFieldNames = (
  itemIndex: number,
  dayIndex: number
) => ({
  meals: `reimbursementItems.${itemIndex}.days.${dayIndex}.meals`,
});
