import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useGetReimbursementById } from 'views/Reimbursement/hooks/useGetReimbursementById';

export interface FieldsOptions {
  isExcluded: {
    isVisible: boolean;
    isReadOnly: boolean;
    showInfoIsExcluded: boolean;
  };
}

export const useFieldsOptions = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const { reimbursementUIConfig } = useReimbursementFormsContext();

  const { isExpenseItemFormEditable } = reimbursementUIConfig;

  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const isCaseInReviewOrRejected =
    reimbursement?.status === ReimbursementCaseStatus.Review ||
    reimbursement?.status === ReimbursementCaseStatus.Rejected;

  const fieldOptions = {
    isExcluded: {
      isVisible: isCaseInReviewOrRejected && isExpenseItemFormEditable,
      isReadOnly: !isCaseInReviewOrRejected,
      showInfoIsExcluded:
        !isCaseInReviewOrRejected &&
        reimbursement?.status !== ReimbursementCaseStatus.Draft,
    },
  } satisfies FieldsOptions;

  return fieldOptions;
};
