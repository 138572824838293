import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { useExpenseFormsHelpers } from './utils/useExpenseFormsHelpers';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemSummaryContainer } from './ReimbursementItemSummary';
import { ReimbursementItemSummaryWithActionContainer } from './ReimbursementItemSummaryWithActionContainer';

export interface ReimbursementItemsSectionContainerProps {
  reimbursement?: Reimbursement;
  formHelpers?: ReturnType<typeof useExpenseFormsHelpers>;
  reimbursementItems?: ReimbursementItemForList[];
}

export const ReimbursementItemsSectionContainer = ({
  reimbursement,
  formHelpers,
  reimbursementItems,
}: ReimbursementItemsSectionContainerProps) => {
  const {
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
    handleCreatePerDiem,
    handleCreateMileage,
    isCreateMileagePending,
    isCreatingExpenseItem,
    isCreatePerDiemPending,
  } = useReimbursementMenuButtonActions({ formHelpers });

  const { reimbursementUIConfig } = useReimbursementFormsContext();
  const { canManageReimbursementItems } = reimbursementUIConfig;

  const isReimbursementInDraftOrCheck =
    reimbursement?.status === ReimbursementCaseStatus.Draft ||
    reimbursement?.status === ReimbursementCaseStatus.Check;

  if (canManageReimbursementItems && isReimbursementInDraftOrCheck) {
    return (
      <ReimbursementItemSummaryWithActionContainer
        creatingExpense={isCreatingExpenseItem}
        onGeneralExpenseClick={handleCreateGeneralExpense}
        onHospitalityExpenseClick={handleCreateHospitalityExpense}
        onCreateMileage={handleCreateMileage}
        isCreateMileagePending={isCreateMileagePending}
        reimbursementItems={reimbursementItems}
        onCreatePerDiem={handleCreatePerDiem}
        creatingPerDiem={isCreatePerDiemPending}
      />
    );
  }

  return (
    <ReimbursementItemSummaryContainer
      reimbursementItems={reimbursementItems}
    />
  );
};
