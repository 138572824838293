import { useTranslation } from 'react-i18next';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementRouteParams } from './useReimbursementNavigation';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateMileageReimbursementItemMutation } from 'generated-types/graphql.types';

export const useCreateMileage = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const { error } = useToastMessage();
  const [createMilage, { loading: isCreateMileagePending }] =
    useCreateMileageReimbursementItemMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: {
            id: reimbursementId,
          },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
        {
          query: reimbursementItemsSplitBookingsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
      ],
    });

  const handleCreateMileage = async () => {
    const { data, errors } = await createMilage({
      variables: {
        input: {
          reimbursementCaseId: reimbursementId,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));

      return;
    }

    if (data?.createMileageReimbursementItem.id) {
      return data.createMileageReimbursementItem.id;
    }
  };

  return {
    isCreateMileagePending,
    createMilage: handleCreateMileage,
  };
};
