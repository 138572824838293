import { InfoPopupButton } from '@candisio/design-system';
import { WarningIcon } from 'components/Icons/DefaultIcons';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const DocumentsExceededWarning = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <div className="flex gap-1 items-center justify-start">
      <WarningIcon size="space20" />
      <span className="text-basic font-normal text-yellow-500">
        {t('table.maxNumberOfDocuments.warning')}
      </span>
      <InfoPopupButton
        message={t('table.maxNumberOfDocuments.warningTooltip')}
      />
    </div>
  );
};
