import { mergeRefs } from '@candisio/design-system';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { usePdfViewProvider } from '../PdfViewProvider';
import {
  DEFAULT_PDF_RESIZE_WAIT_TIME_IN_MS,
  SCROLLBAR_WIDTH,
} from './constants';

export const useReactPdfControls = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { setPdfView } = usePdfViewProvider();
  const scrollContainer = useScrollContainer({
    mouseScroll: {
      ignoreElements: 'span, a',
      inertia: false,
      overscroll: false,
      rubberBand: false,
    },
  });

  const handleResize = useCallback(
    debounce(() => {
      if (!wrapperRef.current) return;

      const width = wrapperRef.current.offsetWidth - SCROLLBAR_WIDTH;
      const height = wrapperRef.current.offsetHeight;

      setPdfView('size', {
        width,
        height,
      });
    }, DEFAULT_PDF_RESIZE_WAIT_TIME_IN_MS),
    []
  );

  useEffect(() => {
    if (!wrapperRef.current) return;

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize]);

  const memoizedRef = useMemo(() => {
    return mergeRefs([wrapperRef, scrollContainer.ref]);
  }, [scrollContainer.ref]);

  return {
    wrapperRef: memoizedRef,
  };
};
