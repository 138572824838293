import { InfoPanel, useTheme } from '@candisio/design-system';

interface PerDiemLengthErrorInfoBoxProps {
  travelLengthError: string;
}

export const PerDiemLengthErrorInfoBox = ({
  travelLengthError,
}: PerDiemLengthErrorInfoBoxProps) => {
  const { colors } = useTheme();
  return (
    <InfoPanel variant="error" style={{ border: `1px solid ${colors.red200}` }}>
      <span>{travelLengthError}</span>
    </InfoPanel>
  );
};
