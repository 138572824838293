import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoImage } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoImage';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const PromoCreditCardChart = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <SwimlaneTilePromo>
      <SwimlaneTilePromoImage
        src={t('insights.swimlanes.promotions.promoCreditCardChart.imageUrl')}
      />
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileDescription>
            {t(
              'insights.swimlanes.promotions.promoCreditCardChart.description'
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileTitle>
            {t('insights.swimlanes.promotions.promoCreditCardChart.title')}
          </SwimlaneTileTitle>
          <SwimlaneTilePromoCTAButton
            icon="arrowRight"
            iconPosition="right"
            as="a"
            target="_blank"
            href={t(
              'insights.swimlanes.promotions.promoCreditCardChart.ctaButton.link'
            )}
          >
            {t(
              'insights.swimlanes.promotions.promoCreditCardChart.ctaButton.text'
            )}
          </SwimlaneTilePromoCTAButton>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};
