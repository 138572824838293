import { Button } from '@candisio/design-system';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';

export const FitHeightButton = () => {
  const [t] = useTranslation();
  const { usePdfView, setPdfView } = usePdfViewProvider();
  const initialScale = useRef(usePdfView('scale')).current;

  return (
    <Button
      icon="expand"
      label={t('document.pageHeight')}
      onClick={() => {
        setPdfView('fitType', 'height');
        setPdfView('scale', initialScale);
      }}
      variant="tertiary"
      size="small"
      style={{
        transform: 'rotate(90deg)',
      }}
    />
  );
};
