import { SearchScope, SearchType } from 'generated-types/graphql.types';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';

export const useDefaultSearchInput = () => {
  const currentEntitlement = useEntitlementsFlag();
  const { showEcm, showConsistentSortAndFiltering } = useEcm();
  const isLegacyUsingESIndex =
    currentEntitlement === Entitlements.LEGACY &&
    showConsistentSortAndFiltering;

  if (currentEntitlement === Entitlements.BASE || isLegacyUsingESIndex) {
    return {
      DEFAULT_SEARCH_TYPE: SearchType.PrefixMatch,
      DEFAULT_SEARCH_SCOPE: SearchScope.DataFields,
    };
  }

  if (currentEntitlement === Entitlements.LEGACY && showEcm === false) {
    return {
      DEFAULT_SEARCH_TYPE: SearchType.Contains,
      DEFAULT_SEARCH_SCOPE: SearchScope.DataFields,
    };
  }

  return {
    DEFAULT_SEARCH_TYPE: SearchType.PrefixMatch,
    DEFAULT_SEARCH_SCOPE: SearchScope.EntireDocument,
  };
};
