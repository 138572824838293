import { Grid } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useDatev } from 'orgConfig/datev';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { PaymentsEntitlementsPromo } from 'views/Settings/PaymentsSettings/toolkit/containers/PaymentsEntitlementsPromo';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { BankAccounts } from './BankAccounts';
import { PaymentActivationForm } from './PaymentActivationForm';
import { ReWeActivationForm } from './ReWeActivationForm';
import { ExpenseExportNotAvailableBanner } from 'views/Payments/PaymentTable/ExpenseExportNotAvailableBanner';
import { EXPENSE_BANNER_KEY } from 'views/Payments/PaymentTable';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCallback } from 'react';

export const PaymentsSettingsLegacy = () => {
  const { canUseReimbursement } = useReimbursement();
  const organization = useFullOrganization();
  const isActive = organization?.payment.isActive ?? false;
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [isExpenseExportBannerVisible, setIsExpenseExportBannerVisible] =
    useLocalStorage(EXPENSE_BANNER_KEY, canUseReimbursement);

  const {
    bdsBought,
    bdsConnected,
    isLoadingDatev,
    hide: hideDatev,
  } = useDatev();

  const handleHideExpenseExportBanner = useCallback(() => {
    setIsExpenseExportBannerVisible(false);
  }, [setIsExpenseExportBannerVisible]);

  const startDate = organization?.payment.startDate
    ? new Date(organization.payment.startDate)
    : null;

  return (
    <>
      <Grid alignContent="start" gap="space32">
        {isExpenseExportBannerVisible && (
          <ExpenseExportNotAvailableBanner
            onHideExpenseExportBanner={handleHideExpenseExportBanner}
          />
        )}
        <PaymentActivationForm isActive={isActive} startDate={startDate} />
        {isActive && <BankAccounts />}
        {!hideDatev && !(bdsConnected && bdsBought) && (
          <ReWeActivationForm
            isActive={!!organization?.exportConfiguration?.isReweEnabled}
          />
        )}
      </Grid>
      <Grid alignContent="start" gap="space32">
        <InfoPanel
          title={t('payments.infoPanel.title')}
          sections={t('payments.infoPanel.sections', { returnObjects: true })}
          externalLink={t('payments.infoPanel.externalLink', {
            returnObjects: true,
          })}
          loading={isLoadingDatev}
        />
      </Grid>
    </>
  );
};

export const PaymentsSettings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { ft, promo } = useFeatureToggle();

  const showPaymentEntitlementsPromo = promo(ft.SEPA_XML);

  return (
    <SettingsLayout
      title={t('payments.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      {showPaymentEntitlementsPromo ? (
        <PaymentsEntitlementsPromo />
      ) : (
        <PaymentsSettingsLegacy />
      )}
    </SettingsLayout>
  );
};
