import { PropsWithChildren } from 'react';
import styles from './power-search-toolbar.module.css';
import clsx from 'clsx';

interface ToolbarAreaSearchProps extends PropsWithChildren {
  className?: string;
}

export const ToolbarAreaSearch = ({
  className,
  ...props
}: ToolbarAreaSearchProps) => {
  return (
    <div
      className={clsx(styles.powerSearchToolbarSearchField, className)}
      {...props}
    />
  );
};
