import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';
import { goToPage } from '../utils';

type Props = {
  disabled?: boolean;
  totalPages?: number;
};

export const NextPageButton = ({ disabled, totalPages }: Props) => {
  const [t] = useTranslation();
  const { usePdfView } = usePdfViewProvider();
  const currentPage = usePdfView('page');
  const viewerId = usePdfView('viewerId');

  return (
    <Button
      data-cy="page-control-next"
      disabled={Boolean(disabled || (totalPages && currentPage >= totalPages))}
      icon="arrowDownCircle"
      label={t('document.nextPagePdf')}
      onClick={() => goToPage(viewerId, currentPage + 1)}
      variant="tertiary"
      size="small"
    />
  );
};
