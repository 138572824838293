import { Box } from '@candisio/design-system';
import { CreditCardsDetailsDrawer } from '../CreditCardDetailsDrawer';
import { CreditCardsIssueNewCardModal } from '../CreditCardsInsights/CreditCardsIssueNewCardModal';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { CreditCardsTableContainer } from './CreditCardsTableContainer';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

export const CardholderOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate({ pathname: '..', search: location.search }, { relative: 'path' });
  };

  return (
    <CreditCardsProvider>
      <Box height="100%" overflow="hidden">
        <CreditCardsTableContainer />
      </Box>
      <CreditCardsIssueNewCardModal />
      <CreditCardsDetailsDrawer onClose={goBack} />
    </CreditCardsProvider>
  );
};
