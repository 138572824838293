import { Grid } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LottieAnimation, extractionPending } from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { ExtractedGeneralExpenseInvoiceData } from './ExtractedGeneralExpenseInvoiceData';
import { extractedDataSummary } from './utils/extractedDataSummary';
import { FieldNames } from './utils/generateGeneralExpenseFormFieldNames';
import {
  ExtractedData,
  ExtractionStatus,
  ExtractionStatusType,
} from './utils/invoiceDataExtractionHelpers';

export const ExtractionLoader = () => {
  return (
    <Grid
      padding="space16"
      height="space256"
      background="gray100"
      borderRadius="medium"
      placeItems="center"
    >
      <LottieAnimation
        height="75%"
        options={{ animationData: extractionPending, loop: true }}
      />
    </Grid>
  );
};
interface GeneralExpenseInvoiceDataFieldsProps {
  fields: FieldNames;
  isLoadingInvoiceData: boolean;
  isExcluded: boolean;
  formIndex: number;
  isExpenseFormEditable: boolean;
  extractedData?: ExtractedData;
  extractionStatus: ExtractionStatusType;
  onUpdateExpense: (index: number) => () => Promise<void>;
  onSetValue: UseFormSetValue<ReimbursementItemsFormOutput>;
}
export const GeneralExpenseInvoiceDataFields = ({
  fields,
  isExcluded,
  isLoadingInvoiceData,
  formIndex,
  extractionStatus,
  extractedData,
  onSetValue,
  onUpdateExpense,
  isExpenseFormEditable,
}: GeneralExpenseInvoiceDataFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  if (isLoadingInvoiceData) {
    return <ExtractionLoader />;
  }

  if (extractionStatus === ExtractionStatus.NO_EXTRACTION) {
    return (
      <>
        <HookFormDatePickerField
          name={fields.expenseDate}
          readOnly={isExcluded}
          label={t('reimbursementView.middleSection.form.general.date.label')}
          onBlur={onUpdateExpense(formIndex)}
          onClear={onUpdateExpense(formIndex)}
        />
        <HookFormTextField
          name={fields.invoiceNumber}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.general.invoiceNumber.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.general.invoiceNumber.placeholder'
          )}
          onClear={onUpdateExpense(formIndex)}
          inputProps={{
            onBlur: onUpdateExpense(formIndex),
          }}
        />
        <HookFormAmountField
          name={fields.totalAmount}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.general.totalAmount.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.general.totalAmount.placeholder'
          )}
          currency="EUR"
          minValue={0}
          clearable
          onClear={onUpdateExpense(formIndex)}
          onBlur={onUpdateExpense(formIndex)}
        />
      </>
    );
  }

  const formattedData = extractedDataSummary(extractedData);

  return (
    <ExtractedGeneralExpenseInvoiceData
      onSetValue={onSetValue}
      extractionStatus={extractionStatus}
      extractedData={formattedData}
      fields={fields}
      formIndex={formIndex}
      isExcluded={isExcluded}
      onUpdateExpense={onUpdateExpense}
      isExpenseFormEditable={isExpenseFormEditable}
    />
  );
};
