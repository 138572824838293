import { Box } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { ReactNode, useMemo } from 'react';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { ApprovedStatusFooterActionBox } from './ApprovedStatusFooterActionBox';
import { ApprovingStatusFooterActionBox } from './ApprovingStatusFooterActionBox';
import { CheckFooterActionBox } from './CheckFooterActionBox';
import { FooterActions } from './FooterActions';
import { PartiallyExportedFooterActionBox } from './PartiallyExportedFooterActionBox';
import { RejectedStatusFooterActionBox } from './RejectedStatusFooterActionBox';
import { ReimbursementWorkflow } from 'views/Reimbursement/hooks/useWorkflowForReimbursementCaseById';
import { UseFormGetValues } from 'react-hook-form';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { ApprovalFieldData } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReviewStatusFooterActionBox } from './ReviewStatusFooterActionBox';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

export type FastApproveReturn = {
  status: string;
};

interface FooterActionBoxProps {
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ReimbursementItemsFormOutput>;
  onSubmitForReview: (reimbursementId: string) => Promise<{
    status: string;
  }>;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
  onSubmitForApproval: (
    values: ApprovalFieldData
  ) => Promise<'error' | 'success'>;
  isWorkflowLoading: boolean;
  reimbursement?: Reimbursement;
  isSubmittingForReview: boolean;
  isFastApprovePending?: boolean;
  isRequestAmendmentPending?: boolean;
  reimbursmentWorkflow?: ReimbursementWorkflow;
  reimbursementItems: ReimbursementItemForList[];
  isSubmitForApprovalPending: boolean;
}

export const FooterActionBox = ({
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
  onFastApprove,
  onRequestAmendment,
  onSubmitForApproval,
  isSubmitForApprovalPending,
  reimbursement,
  reimbursmentWorkflow,
  isRequestAmendmentPending = false,
  isFastApprovePending = false,
  isSubmittingForReview,
  reimbursementItems,
  isWorkflowLoading,
}: FooterActionBoxProps) => {
  const currentUser = useCurrentUser();
  const { isOnlyApprover, isOnlyRequester } = useUserRoles();
  const { status, createdByMembership } = reimbursement ?? {};
  const createdById = createdByMembership?.id;
  const isReimbursementOwner = currentUser?.id === createdById;
  const hasReviewOrApprovalRights = !isOnlyApprover && !isOnlyRequester;

  const footer: Partial<Record<ReimbursementCaseStatus, ReactNode>> = useMemo(
    () => ({
      DRAFT: (
        <FooterActions
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          onSubmitForReview={onSubmitForReview}
          isSubmittingForReview={isSubmittingForReview}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
        />
      ),
      CHECK: isReimbursementOwner ? (
        <CheckFooterActionBox
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          onSubmitForReview={onSubmitForReview}
          isSubmittingForReview={isSubmittingForReview}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
        />
      ) : null,
      REVIEW: (
        <ReviewStatusFooterActionBox
          createdById={createdById}
          isFastApprovePending={isFastApprovePending}
          onRequestAmendment={onRequestAmendment}
          isRequestAmendmentPending={isRequestAmendmentPending}
          onFastApprove={onFastApprove}
          onSubmitForApproval={onSubmitForApproval}
          isSubmitForApprovalPending={isSubmitForApprovalPending}
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
        />
      ),
      APPROVING: (
        <ApprovingStatusFooterActionBox
          createdById={createdById}
          isFastApprovePending={isFastApprovePending}
          onFastApprove={onFastApprove}
          getExpenses={getExpenses}
          workflow={reimbursmentWorkflow}
          onCycleReimbursments={onCycleReimbursments}
          onRequestAmendment={onRequestAmendment}
          onSubmitForApproval={onSubmitForApproval}
          isSubmitForApprovalPending={isSubmitForApprovalPending}
          isRequestAmendmentPending={isRequestAmendmentPending}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
          isWorkflowLoading={isWorkflowLoading}
        />
      ),
      APPROVED: hasReviewOrApprovalRights && <ApprovedStatusFooterActionBox />,
      REJECTED: (
        <RejectedStatusFooterActionBox
          createdById={createdById}
          isFastApprovePending={isFastApprovePending}
          onRequestAmendment={onRequestAmendment}
          isRequestAmendmentPending={isRequestAmendmentPending}
          onSubmitForApproval={onSubmitForApproval}
          isSubmitForApprovalPending={isSubmitForApprovalPending}
          onFastApprove={onFastApprove}
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
        />
      ),
      PARTIALLY_EXPORTED: hasReviewOrApprovalRights && (
        <PartiallyExportedFooterActionBox
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
        />
      ),
    }),
    [
      createdById,
      getExpenses,
      isFastApprovePending,
      hasReviewOrApprovalRights,
      isReimbursementOwner,
      isRequestAmendmentPending,
      isSubmittingForReview,
      onCycleReimbursments,
      onFastApprove,
      onRequestAmendment,
      onSubmitForReview,
      reimbursmentWorkflow,
      reimbursement,
      isSubmitForApprovalPending,
      onSubmitForApproval,
      reimbursementItems,
      isWorkflowLoading,
    ]
  );

  if (!status || !footer[status]) return null;

  return (
    <Box paddingRight="space14" right={-2.5}>
      {footer[status]}
    </Box>
  );
};
