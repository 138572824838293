import { Grid, Item, ListView } from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback, useMemo } from 'react';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementItemSplitBookingItem } from './ReimbursementItemSplitBookingItem';
import { ReimbursementSplitActionButton } from './ReimbursementSplitAction';
import { calculateSumOfBookingsSplitAmounts } from './hooks/useFormatAmountHelpers';
import { BookingItem } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { useUserRoles } from 'hooks/useUserRoles';
import { useFormFieldOptions } from './hooks/useFormFieldOptions';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';

export interface ReimbursementItemSplitBookingsListProps {
  reimbursementCaseStatus?: ReimbursementCaseStatus;
  isLoading: boolean;
  totalAmount: number;
  currency: DocumentCurrency;
  entryIndex: number;
  reimbursementItemId: string;
  bookings: BookingItem[];
  itemStatus: ReimbursementItemStatus;
  isCurrentUserCaseApprover: boolean;
  shouldShowFullSummary?: boolean;
  reimbursementItemType?: ReimbursementItemType;
}

export const ReimbursementItemSplitBookingsList = ({
  reimbursementCaseStatus,
  isLoading,
  totalAmount,
  currency,
  entryIndex,
  bookings,
  reimbursementItemId,
  itemStatus,
  isCurrentUserCaseApprover,
  shouldShowFullSummary,
  reimbursementItemType,
}: ReimbursementItemSplitBookingsListProps) => {
  const { updateSearchParam } = useMutateSearchParams();
  const { isOnlyApprover, isOnlyRequester } = useUserRoles();
  const fieldOptions = useFormFieldOptions();
  const { track } = useAnalytics();

  const {
    updateActiveBooking,
    setDrawerView,
    formMethods,
    getSplitBookingsFormRules,
  } = useReimbursementSplitBookingHelpers();
  const { setSelectedItemId, isApprovingEditMode } =
    useReimbursementFormsContext();

  const {
    formState: { errors },
  } = formMethods;

  const sumOfBookingsSplitAmounts = useMemo(
    () => calculateSumOfBookingsSplitAmounts(bookings),
    [bookings]
  );

  const { actionRules } = getSplitBookingsFormRules({
    status: reimbursementCaseStatus as ReimbursementCaseStatus,
    itemStatus,
    isCurrentUserCaseApprover,
    isOnlyApproverRole: isOnlyApprover,
    isApprovingEditMode,
    fieldOptions,
    isOnlyRequesterRole: isOnlyRequester,
  });

  const remainingAmount = totalAmount - sumOfBookingsSplitAmounts;
  const hasBookings = (bookings ?? [])?.length > 0;
  const hasRemainingAmount = Boolean(remainingAmount);
  const { canAddAndDeleteSplits, canAcceptAllSplits } =
    actionRules[entryIndex] ?? {};

  const bookingErrors =
    errors.reimbursementItemBookings?.[entryIndex]?.bookings ?? [];

  const isItemExcluded = itemStatus === ReimbursementItemStatus.Excluded;

  const shouldTrack =
    reimbursementCaseStatus === ReimbursementCaseStatus.Review ||
    reimbursementCaseStatus === ReimbursementCaseStatus.Approving;

  const navigateToBooking = useCallback(
    (bookingId: string) => {
      updateActiveBooking({ entryIndex, bookingId, reimbursementItemId });
      setDrawerView('split-bookings');
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
      setSelectedItemId(reimbursementItemId);

      if (shouldTrack) {
        track(TrackingEvents.EXPENSE_COLLECTION_SPLIT_OPENED, {
          expense_id: reimbursementItemId,
          expense_type: reimbursementItemType,
        });
      }
    },
    [
      entryIndex,
      reimbursementItemId,
      setDrawerView,
      setSelectedItemId,
      updateActiveBooking,
      updateSearchParam,
      track,
      shouldTrack,
      reimbursementItemType,
    ]
  );

  const handleItemClick = useCallback(
    (bookingId: string) => {
      navigateToBooking(bookingId);
    },
    [navigateToBooking]
  );

  const handleSplitDocumentClick = useCallback(() => {
    const previousBooking = bookings[bookings.length - 1];
    navigateToBooking(previousBooking.id);
  }, [bookings, navigateToBooking]);

  return (
    <Grid borderRadius="medium" overflow="hidden">
      <Grid
        borderBottom={
          hasBookings
            ? `1px solid ${!hasRemainingAmount ? 'gray200' : undefined}`
            : undefined
        }
      >
        <ListView>
          {(bookings ?? []).map((booking, bookingIndex) => (
            <Item key={String(bookingIndex)} textValue={booking.id}>
              <ReimbursementItemSplitBookingItem
                bookingId={booking?.id}
                key={booking.id}
                bookingIndex={bookingIndex}
                onEditSplit={() => {
                  handleItemClick(booking.id);
                }}
                isReadOnly={!canAcceptAllSplits}
                costCenter={booking.costCenter}
                costObject={booking.costObject}
                splitAmount={booking.splitAmount}
                vatRate={booking.vatRate}
                currency={currency}
                hasBookingError={!!bookingErrors[bookingIndex]}
                isItemExcluded={isItemExcluded}
                shouldShowFullSummary={shouldShowFullSummary}
              />
            </Item>
          ))}
        </ListView>
      </Grid>
      {!isLoading && hasBookings && (
        <ReimbursementSplitActionButton
          currency={currency}
          hasItems={hasBookings}
          readOnly={!canAddAndDeleteSplits || isItemExcluded}
          remainingAmount={remainingAmount}
          onSplitDocument={handleSplitDocumentClick}
        />
      )}
    </Grid>
  );
};
