import { SwimlaneGroup } from 'components/Swimlane/SwimlaneGroup';
import { DeclinedTransactionsSwimlane } from './DeclinedTransactions/DeclinedTransactionsSwimlane';
import { LimitRequestsSwimlane } from './LimitRequests/LimitRequestsSwimlane';
import { CardRequestsSwimlane } from './CardRequests/CardRequestsSwimlane';
import { useCreditCardInsights } from './hooks/useCreditCardInsights';
import { OverViewGrid } from './OverviewSwimlane/OverviewGrid';
import { SwimlaneCreditCardDrawer } from './shared/SwimlaneCreditCardDrawer/SwimlaneCreditCardDrawer';

export const CreditCardsInsightSwimlanes = () => {
  const {
    cardRequests,
    limitRequests,
    declinedTransactions,
    isInitialLoading,
  } = useCreditCardInsights();

  return (
    <>
      <OverViewGrid />
      <SwimlaneGroup>
        {!isInitialLoading && (
          <>
            <DeclinedTransactionsSwimlane
              isVisible={!!declinedTransactions.totalCount}
              declinedTransactions={declinedTransactions.data}
              totalCount={declinedTransactions.totalCount ?? 0}
            />
            <CardRequestsSwimlane
              isVisible={!!cardRequests.totalCount}
              cardRequests={cardRequests.data}
              totalCount={cardRequests.totalCount ?? 0}
            />
            <LimitRequestsSwimlane
              isVisible={!!limitRequests.totalCount}
              limitRequests={limitRequests.data}
              totalCount={limitRequests.totalCount ?? 0}
            />
          </>
        )}
      </SwimlaneGroup>
      <SwimlaneCreditCardDrawer />
    </>
  );
};
