import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

interface UseEcmResult {
  showEcm: boolean;
  showEcmPromo: boolean;
  isDocumentRelationsAvailable: boolean;
  showDocumentRelations: boolean;
  showEcmContractManagement: boolean;
  showEcmContractManagementPromo: boolean;
  showEcmDocumentsDownload: boolean;
  showEcmSensitiveDocuments: boolean;
  showDocumentTags: boolean;
  showDocumentPreview: boolean;
  showDocumentPreviewPromo: boolean;
  canAccessEcm: boolean;
  showSearchOverlayPromo: boolean;
  showConsistentSortAndFiltering: boolean;
}

export const useEcm = (): UseEcmResult => {
  const [
    documentRelationsFF,
    enterpriseContentManagementFF,
    consistentSortAndFilteringFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentRelations,
    FEATURE_FLAGS.enterpriseContentManagement,
    FEATURE_FLAGS.consistentSortAndFiltering,
  ]);

  const { isAccountant, isAdmin, isRequester } = useUserRoles();
  const canAccessEcm = isAdmin || isRequester || isAccountant;

  const entitlements = useEntitlementsFlag();
  const { ft, promo, show, hide } = useFeatureToggle();

  const isNewPackage = entitlements !== Entitlements.LEGACY;
  if (isNewPackage) {
    return {
      showEcm: show(ft.ECM),
      showEcmPromo: promo(ft.ECM),
      isDocumentRelationsAvailable: !hide(ft.DOCUMENT_RELATIONS),
      showDocumentRelations: show(ft.DOCUMENT_RELATIONS),
      showEcmContractManagement: show(ft.CONTRACTS),
      showEcmContractManagementPromo: promo(ft.CONTRACTS),
      showEcmDocumentsDownload: show(ft.ECM),
      showEcmSensitiveDocuments: show(ft.CONTRACTS),
      showDocumentTags: show(ft.TAGS),
      showDocumentPreview: show(ft.DOCUMENT_PREVIEW),
      showDocumentPreviewPromo: promo(ft.DOCUMENT_PREVIEW),
      canAccessEcm,
      showSearchOverlayPromo: promo(ft.SEARCH_OVERLAY),
      showConsistentSortAndFiltering: consistentSortAndFilteringFF,
    };
  }

  return {
    showEcm: enterpriseContentManagementFF,
    showEcmPromo: !enterpriseContentManagementFF,
    isDocumentRelationsAvailable:
      enterpriseContentManagementFF && documentRelationsFF,
    showDocumentRelations: enterpriseContentManagementFF && documentRelationsFF,
    showEcmContractManagement: enterpriseContentManagementFF,
    showEcmContractManagementPromo: !enterpriseContentManagementFF,
    showEcmDocumentsDownload: enterpriseContentManagementFF,
    showEcmSensitiveDocuments: enterpriseContentManagementFF,
    showDocumentTags: enterpriseContentManagementFF,
    showDocumentPreview: enterpriseContentManagementFF,
    showDocumentPreviewPromo: !enterpriseContentManagementFF,
    canAccessEcm,
    showSearchOverlayPromo:
      !enterpriseContentManagementFF && promo(ft.SEARCH_OVERLAY),
    showConsistentSortAndFiltering: consistentSortAndFilteringFF,
  };
};
