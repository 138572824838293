import { TruncatedText } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { CommentContainer } from 'components/Comment/CommentContainer';
import {
  DocumentHistoryContainer,
  DocumentHistoryContainerProps,
} from 'components/DocumentHistory/DocumentHistoryContainer';
import { useDocumentHistory } from 'components/DocumentHistory/useDocumentHistory';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const DocumentHistoryComponent = memo(
  ({
    documentId,
    isEcmDocument,
    isSensitiveDocument,
  }: DocumentHistoryContainerProps) => {
    const [t] = useTranslation();
    const {
      documentData: documentHistoryData,
      loading: loadingDocumentHistory,
    } = useDocumentHistory(documentId);

    const [modifyEcmDocumentEditAccessFF] = useCandisFeatureFlags([
      FEATURE_FLAGS.modifyEcmDocumentEditAccess,
    ]);

    const timelineEntriesCount = documentHistoryData?.timeline?.length ?? 0;
    const showCountSuffix = !loadingDocumentHistory && timelineEntriesCount > 0;
    const countSuffix = showCountSuffix ? ` (${timelineEntriesCount})` : '';

    const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;
    const historyTitle = t('document.tabs.protocol') + countSuffix;

    const title = (
      <TruncatedText
        charsAfterEllipsis={charsAfterEllipsis}
        fontWeight="semibold"
      >
        {historyTitle}
      </TruncatedText>
    );

    return (
      <div className="pb-4 flex-1">
        <CollapsibleCard
          borderRadius="medium"
          overflow="visible"
          id="document-summary-protocol-open"
          headerProps={{ background: 'gray0' }}
          header={title}
          data-testid={t('document.tabs.protocol')}
          defaultOpen
          isStatic
          height="100%"
        >
          <div
            className={'overflow-hidden bg-white pb-2 rounded-[inherit] h-full'}
          >
            <div className="py-4 px-2">
              <CommentContainer
                documentId={documentId}
                isEcmDocument={isEcmDocument}
                restrictMentions={
                  isSensitiveDocument && !modifyEcmDocumentEditAccessFF
                }
              />
            </div>
            <DocumentHistoryContainer documentId={documentId} />
          </div>
        </CollapsibleCard>
      </div>
    );
  }
);
