import { Switch } from '@candisio/design-system';
import { Icon } from '@candisio/design-system/next';
import { useAcceptedFileTypesForInvoice } from 'components/FileDropZone/useAcceptedFileTypesForInvoice';
import { FilePicker } from 'components/FilePicker/FilePicker';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpload } from 'views/AppContainer/components/Header/components/DocumentUploadModal/useFileUpload';
import { DropZone } from './DropZone';
import styles from './FileUpload.module.css';

const useIsRestrictedContent = () => {
  const { useLayoutValue } = useAppLayout();
  const { isRestrictedContent = false } = useLayoutValue('docUploadPopover');
  const { showEcmContractManagement } = useEcm();

  return useState(showEcmContractManagement ? isRestrictedContent : false);
};

export const FileUpload = () => {
  const [isRestrictedContent, setRestictedContent] = useIsRestrictedContent();
  const { showEcmContractManagement } = useEcm();
  const { uploadFiles } = useFileUpload();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const acceptedFileTypes = useAcceptedFileTypesForInvoice({
    isXmlDocumentUploadAllowed: !isRestrictedContent,
  });

  return (
    <FilePicker
      acceptedFileTypes={acceptedFileTypes}
      allowsMultiple
      isRestrictedContent={isRestrictedContent}
      onSelect={files => uploadFiles(files, isRestrictedContent)}
    >
      <DropZone isRestrictedContent={isRestrictedContent} />
      <div className={styles['content-wrapper']}>
        <Icon icon="menuUpload" className="size-7" />
        <span>
          {isRestrictedContent ? (
            <Trans
              i18nKey="navigation:documentUpload.dropZone.restricted.title"
              components={{ strong: <strong /> }}
            />
          ) : (
            <Trans
              i18nKey="navigation:documentUpload.dropZone.nonRestricted.title"
              components={{ strong: <strong /> }}
            />
          )}
        </span>
      </div>
      {showEcmContractManagement && (
        <div className={styles['switch-wrapper']}>
          <Icon icon="lock" className="size-3" />
          <span>{t('documentUpload.dropZone.restrictSwitchButton')}</span>
          <Switch
            className={styles.switch}
            defaultChecked={isRestrictedContent}
            label=""
            onChange={setRestictedContent}
          />
        </div>
      )}
    </FilePicker>
  );
};
