import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PerDiemDayCalculationDataProps } from 'views/Reimbursement/hooks/usePerDiemItemCalculation';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { DayCell } from './DayCell';
import { DayRateCell } from './DayRateCell';
import { MealCell } from './MealCell';
import { ReimbursableRateCell } from './ReimbursableRateCell';
import { TableHeader } from './TableHeader';
import clsx from 'clsx';

const HEADER_TRANSLATION_KEYS = {
  day: 'reimbursementView.middleSection.form.perDiem.calculationTable.columns.day',
  dayRate:
    'reimbursementView.middleSection.form.perDiem.calculationTable.columns.dayRate',
  breakfast:
    'reimbursementView.middleSection.form.perDiem.calculationTable.columns.breakfast',
  lunch:
    'reimbursementView.middleSection.form.perDiem.calculationTable.columns.lunch',
  dinner:
    'reimbursementView.middleSection.form.perDiem.calculationTable.columns.dinner',
  reimbursableRate:
    'reimbursementView.middleSection.form.perDiem.calculationTable.columns.reimburseableRate',
};

const COLUMN_SIZES = {
  day: 250,
  default: 165,
};

export const usePerDiemDayCalculationColumns = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const columnHelper = createColumnHelper<PerDiemDayCalculationDataProps>();

  const getHeader = useMemo(() => {
    const header: { [key: string]: () => JSX.Element } = {};

    Object.entries(HEADER_TRANSLATION_KEYS).forEach(([key, translationKey]) => {
      header[key] = () => (
        <TableHeader
          text={t(translationKey)}
          className={clsx({
            'text-right': key === 'reimbursableRate',
          })}
        />
      );
    });

    return header;
  }, [t]);

  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row, {
        id: 'day',
        header: getHeader.day,
        cell: DayCell,
        size: COLUMN_SIZES.day,
      }),
      columnHelper.accessor(row => row, {
        id: 'dayRate',
        header: getHeader.dayRate,
        cell: DayRateCell,
        size: COLUMN_SIZES.default,
      }),
      columnHelper.accessor('breakfast', {
        header: getHeader.breakfast,
        cell: MealCell,
        size: COLUMN_SIZES.default,
      }),
      columnHelper.accessor('lunch', {
        header: getHeader.lunch,
        cell: MealCell,
        size: COLUMN_SIZES.default,
      }),
      columnHelper.accessor('dinner', {
        header: getHeader.dinner,
        cell: MealCell,
        size: COLUMN_SIZES.default,
      }),
      columnHelper.accessor('reimbursableRate', {
        header: getHeader.reimbursableRate,
        cell: ReimbursableRateCell,
        size: COLUMN_SIZES.default,
      }),
    ],
    [columnHelper, getHeader]
  );

  return columns;
};
