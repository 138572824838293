import { usePdfViewProvider } from '../../PdfViewProvider';

type Props = {
  totalPages?: number;
};

export const CurrentPageLabel = ({ totalPages }: Props) => {
  const { usePdfView } = usePdfViewProvider();
  const currentPage = usePdfView('page');

  return <span>{`${currentPage}/${totalPages}`}</span>;
};
