import { Grid, ScrollBox } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { FormProvider } from 'react-hook-form';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useCreateExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { useVisibleReimbursementItemTracker } from 'views/Reimbursement/toolkit/useVisibleReimbursementItemTracker';
import { CreateExpenseActionMenuButton } from '../CreateExpenseActionMenuButton';
import { GeneralExpensesForm } from './GeneralExpenseForm';
import { HospitalityExpenseForm } from './HospitalityExpenseForm';
import { useExpenseFormsHelpers } from './utils/useExpenseFormsHelpers';
import { useReimbursementItemsFormActions } from './utils/useReimbursementItemsFormActions';
import { useFieldsOptions } from './utils/useFieldsOptions';
import { PerDiemForm } from './PerDiemForm';
import { PerDiemItemDetails } from 'views/Reimbursement/hooks/usePerDiemItemDetails';

interface ReimbursementItemsFormsContainerProps {
  status?: ReimbursementCaseStatus;
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
  perDiemItemDetails?: PerDiemItemDetails;
  isLoadingPerDiemItem: boolean;
}

export const ReimbursementItemsFormsContainer = ({
  status,
  formHelpers,
  perDiemItemDetails,
  isLoadingPerDiemItem,
}: ReimbursementItemsFormsContainerProps) => {
  const { createExpenseInvoice } = useCreateExpenseInvoice();
  const { reimbursementUIConfig, itemsContainerRef } =
    useReimbursementFormsContext();
  const fieldOptions = useFieldsOptions();

  const {
    isExpenseItemFormEditable,
    canManageReimbursementItems,
    isPerDiemFormEditable,
  } = reimbursementUIConfig;

  const { forms, onRemoveForm, formMethods } = formHelpers;

  const {
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
    isCreatingExpenseItem,
  } = useReimbursementMenuButtonActions({ formHelpers });

  const {
    handleDeleteForm,
    handleInvoiceChange,
    handleUpdateHospitality,
    handleUpdateGeneralExpense,
    handleUpdateTotalAmount,
    isCreatingInvoice,
    isDeletingForm,
    isExtractingInvoiceData,
    extractAndProcessGeneralInvoice,
    extractAndProcessHospitalityInvoice,
    handleUpdatePerDiem,
    handleAddPerDiemSegment,
    handleUpdatePerDiemStep,
    handleUpdatePerDiemDays,
    isAddSegmentPending,
    isUpdatePerDiemPending,
    isEditSegmentPending,
    handleEditPerDiemSegment,
  } = useReimbursementItemsFormActions({
    createExpenseInvoice,
    formMethods,
    onRemoveForm,
  });

  useVisibleReimbursementItemTracker();

  return (
    <ScrollBox scrollDirection="y" ref={itemsContainerRef} overflow="clip">
      <Grid gap="space16">
        <FormProvider {...formMethods}>
          <Grid gap="space24">
            {forms.map((form, index) => {
              if (form.reimbursementItemType === 'hospitality') {
                return (
                  <HospitalityExpenseForm
                    key={form.reimbursementItemId}
                    expenseId={form.reimbursementItemId}
                    formIndex={index}
                    fieldOptions={fieldOptions}
                    reimbursementStatus={status}
                    isExpenseFormEditable={isExpenseItemFormEditable}
                    isCreatingInvoice={isCreatingInvoice}
                    isExtractingInvoiceData={isExtractingInvoiceData}
                    isDeletingForm={isDeletingForm}
                    onUpdateHospitality={handleUpdateHospitality}
                    formMethods={formMethods}
                    onRemoveForm={handleDeleteForm}
                    onInvoiceChange={handleInvoiceChange}
                    onUpdateTotalAmount={handleUpdateTotalAmount}
                    canManageReimbursementItems={canManageReimbursementItems}
                    onHospitalityInvoiceDataExtraction={
                      extractAndProcessHospitalityInvoice
                    }
                  />
                );
              }

              if (form.reimbursementItemType === 'general') {
                return (
                  <GeneralExpensesForm
                    key={form.reimbursementItemId}
                    expenseId={form.reimbursementItemId}
                    formIndex={index}
                    reimbursementStatus={status}
                    isExpenseFormEditable={isExpenseItemFormEditable}
                    isExtractingInvoiceData={isExtractingInvoiceData}
                    isCreatingInvoice={isCreatingInvoice}
                    isDeletingForm={isDeletingForm}
                    onUpdateExpense={handleUpdateGeneralExpense}
                    fieldOptions={fieldOptions}
                    formMethods={formMethods}
                    onRemoveForm={handleDeleteForm}
                    onInvoiceChange={handleInvoiceChange}
                    canManageReimbursementItems={canManageReimbursementItems}
                    onGeneralInvoiceDataExtraction={
                      extractAndProcessGeneralInvoice
                    }
                  />
                );
              }

              if (form.reimbursementItemType === 'perDiem') {
                return (
                  <PerDiemForm
                    key={form.reimbursementItemId}
                    reimbursementItemId={form.reimbursementItemId}
                    formIndex={index}
                    reimbursementStatus={status}
                    isCreatingInvoice={isCreatingInvoice}
                    isDeletingForm={isDeletingForm}
                    onUpdatePerDiem={handleUpdatePerDiem}
                    fieldOptions={fieldOptions}
                    formMethods={formMethods}
                    onRemoveForm={handleDeleteForm}
                    onInvoiceChange={handleInvoiceChange}
                    canManageReimbursementItems={canManageReimbursementItems}
                    onAddPerDiemSegment={handleAddPerDiemSegment}
                    onUpdatePerDiemStep={handleUpdatePerDiemStep}
                    onUpdatePerDiemDays={handleUpdatePerDiemDays}
                    isUpdatePerDiemStepPending={isUpdatePerDiemPending}
                    isAddSegmentPending={isAddSegmentPending}
                    perDiemItemDetails={perDiemItemDetails}
                    isLoadingPerDiemItemDetails={isLoadingPerDiemItem}
                    isEditSegmentPending={isEditSegmentPending}
                    onEditPerDiemSegment={handleEditPerDiemSegment}
                    isPerDiemFormEditable={isPerDiemFormEditable}
                  />
                );
              }
            })}
          </Grid>
        </FormProvider>

        {canManageReimbursementItems && (
          <Grid
            background="gray50"
            placeItems="center"
            borderRadius="medium"
            padding="space16"
          >
            <CreateExpenseActionMenuButton
              count={forms.length}
              creatingExpense={isCreatingExpenseItem}
              onGeneralExpenseClick={handleCreateGeneralExpense}
              onHospitalityExpenseClick={handleCreateHospitalityExpense}
            />
          </Grid>
        )}
      </Grid>
    </ScrollBox>
  );
};
