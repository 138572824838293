import { ScrollBox } from '@candisio/design-system/next';
import styles from './MissingInvoicesTile.module.css';
import { CopyButton } from './CopyButton/CopyButton';
import { Header } from './Header/Header';
import { UserListItem } from './UserListItem/UserListItem';
import { useMissingInvoicesList } from './useMissingInvoicesList';
import { EmptyState } from './EmptyState/EmptyState';
import { DeferredSpinner } from 'components/DeferredSpinner/DeferredSpinner';

export const MissingInvoicesTile = () => {
  const { missingInvoices, loading, totalCount, emailList } =
    useMissingInvoicesList();

  return (
    <div className={styles.tile}>
      <Header totalCount={totalCount} loading={loading} />
      {loading && <DeferredSpinner className="grid place-items-center" />}
      {!loading && missingInvoices.length > 0 && (
        <>
          <ScrollBox className={styles.scrollBox}>
            <ul className={styles.list}>
              {missingInvoices.map(user => (
                <UserListItem
                  cardholderInsight={user}
                  key={user.cardholder.id}
                />
              ))}
            </ul>
          </ScrollBox>
          <CopyButton emailList={emailList} />
        </>
      )}
      {!loading && missingInvoices.length === 0 && <EmptyState />}
    </div>
  );
};
