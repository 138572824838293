import {
  PerDiemItem,
  PerDiemItemDetailsQuery,
  PerDiemStep,
  ReimbursementItemStatus,
  usePerDiemItemDetailsQuery,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';

type PerDiemItemFragment =
  PerDiemItemDetailsQuery['perDiemItemDetails'][number];

export interface PerDiemItemDetails {
  id: string;
  status: ReimbursementItemStatus;
  currentStep: PerDiemStep;
  totalAmount?: number | null;
  segments: {
    id: string;
    location: {
      id?: string;
      locationName?: string;
      countryIsoCode?: string;
      startDate?: string;
      endDate?: string;
    };
  }[];
}

export const usePerDiemItemDetails = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUsePerDiemReimbursement } = useReimbursement();

  const { data, loading } = usePerDiemItemDetailsQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    fetchPolicy: 'network-only',
    skip: !reimbursementId || !canUsePerDiemReimbursement,
  });

  const items = data?.perDiemItemDetails ?? [];

  const perDiemItem = items.find(
    (item: PerDiemItemFragment): item is PerDiemItem =>
      item.__typename === 'PerDiemItem'
  );

  const perDiemItemDetails: PerDiemItemDetails | undefined = perDiemItem?.id
    ? {
        id: perDiemItem.id,
        status: perDiemItem.status,
        currentStep: perDiemItem.currentStep,
        totalAmount: !isNil(perDiemItem.totalAmount?.amount)
          ? formatFromCents(
              perDiemItem.totalAmount.currency,
              perDiemItem.totalAmount.amount
            )
          : undefined,
        segments: (perDiemItem.segments ?? [])?.map(
          ({ id, startAt, endAt, location }) => {
            const displayText = !!location?.location
              ? `${location?.country}, ${location?.location}`
              : location?.country;

            return {
              id,
              location: {
                id: location?.id ?? undefined,
                startDate: startAt ?? undefined,
                endDate: endAt ?? undefined,
                locationName: displayText ?? undefined,
                countryIsoCode: location?.countryIsoCode ?? undefined,
              },
            };
          }
        ),
      }
    : undefined;

  return {
    isLoadingPerDiemItem: loading,
    perDiemItemDetails,
  };
};
