import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

const getPricingLinks = {
  [Entitlements.LEGACY]: 'promotionDetailsModal.ctaMarketingLink.url.legacy',
  [Entitlements.LEGACY_DMS]:
    'promotionDetailsModal.ctaMarketingLink.url.legacy',
  [Entitlements.BASE]: 'promotionDetailsModal.ctaMarketingLink.url.base',
  [Entitlements.PLUS]: 'promotionDetailsModal.ctaMarketingLink.url.plus',
  // MAX user should not see this link, but this is a fallback
  // in case the entitlement is not set correctly
  [Entitlements.MAX]: 'promotionDetailsModal.ctaMarketingLink.url.plus',
};

export const buildPricingUrl = (
  baseUrl: string,
  integration: string,
  userName: string | undefined
): string => {
  try {
    const url = new URL(baseUrl);
    const existingParams = new URLSearchParams(url.search);

    existingParams.set('integration', integration);
    const base64User = Buffer.from(userName ?? '').toString('base64');
    existingParams.set('user', base64User);

    // Remove trailing slash before query parameters (/?param=value -> ?param=value)
    return `${url.origin}${url.pathname}?${existingParams.toString()}`.replace(
      /\/\?/,
      '?'
    );
  } catch (error) {
    console.error('Error building pricing URL:', error);
    return baseUrl;
  }
};

export const useEntitlementPricingLink = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const entitlement = useEntitlementsFlag();
  const integration = useIntegrationSettings();
  const user = useCurrentUser();

  const baseUrl = t(getPricingLinks[entitlement]);
  return buildPricingUrl(baseUrl, integration, user?.firstName);
};
