import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider as HookFormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementFormFieldOptions } from 'views/Reimbursement/hooks/types';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { IbanField } from './components/IbanField';
import { ReactNode } from 'react';
import { useUserRoles } from 'hooks/useUserRoles';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { MissingAccountsPayableNumberCard } from './components/MissingAccountsPayableNumberCard';
import { FormLayout } from './components/FormLayout';

interface ReadonlyReimbursementFormProps {
  isLoading: boolean;
  values: ReimbursementFormValues;
  fieldOptions: ReimbursementFormFieldOptions;
  splits: ReactNode;
  reimbursementStatus?: ReimbursementCaseStatus;
}

export const ReadonlyReimbursementForm = ({
  isLoading,
  values,
  fieldOptions,
  splits,
  reimbursementStatus,
}: ReadonlyReimbursementFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { isOnlyApprover } = useUserRoles();

  const {
    targetMembership,
    suggestedCostCenter,
    suggestedCostObject,
    suggestedGeneralLedgerAccount,
    accountsPayableNumberInfo,
    accountPayableNumberField,
  } = fieldOptions;

  const form = useForm<ReimbursementFormValues>({ defaultValues: values });

  const isApproverAndCaseInReview =
    isOnlyApprover && reimbursementStatus === ReimbursementCaseStatus.Review;

  const isNotDraftOrCheck =
    reimbursementStatus !== ReimbursementCaseStatus.Draft &&
    reimbursementStatus !== ReimbursementCaseStatus.Check;

  const showReimbursmentItemSplitBookings =
    isApproverAndCaseInReview || isNotDraftOrCheck;

  return (
    <HookFormProvider {...form}>
      <FormLayout readonly>
        <HookFormTextField
          name="title"
          readOnly
          label={t('reimbursementView.rightSection.form.title.label')}
          ariaLabel={t('reimbursementView.rightSection.form.title.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.title.placeholder'
          )}
          isLoading={isLoading}
        />
        <HookFormUsersField
          name="targetMembership"
          readOnly
          label={t('reimbursementView.rightSection.form.targetMember.label')}
          placeholder={t('teams.createTeam.teamMembers.field.placeholder')}
          emptyListPlaceholder={t(
            'reimbursementView.rightSection.form.targetMember.noUserFound'
          )}
          {...targetMembership?.options}
          isLoading={isLoading}
        />
        <IbanField
          label={t('reimbursementView.rightSection.form.iban.label')}
          isLoading={isLoading}
        />
        {accountPayableNumberField.isVisible && (
          <HookFormTextField
            name="accountsPayableNumber"
            readOnly
            label={t(
              'reimbursementView.rightSection.form.accountsPayableNumber.label'
            )}
          />
        )}
        {accountsPayableNumberInfo.isVisible && (
          <MissingAccountsPayableNumberCard />
        )}

        {suggestedCostCenter?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostCenter"
            readOnly
            label={t('reimbursementView.rightSection.form.costCenter.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costCenter.placeholder'
            )}
            {...suggestedCostCenter?.options}
            isLoading={isLoading}
          />
        )}
        {suggestedCostObject?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostObject"
            readOnly
            label={t('reimbursementView.rightSection.form.costObject.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costObject.placeholder'
            )}
            {...suggestedCostCenter?.options}
            isLoading={isLoading}
          />
        )}
        {suggestedGeneralLedgerAccount?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedGeneralLedgerAccount"
            readOnly
            label={t(
              'reimbursementView.rightSection.form.generalLedgerAccount.label'
            )}
            placeholder={t(
              'reimbursementView.rightSection.form.generalLedgerAccount.placeholder'
            )}
            {...suggestedGeneralLedgerAccount?.options}
            isLoading={isLoading}
          />
        )}
        <HookFormTextareaField
          name="note"
          readOnly
          label={t('reimbursementView.rightSection.form.note.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.note.placeholder'
          )}
          infoTooltip={t('reimbursementView.rightSection.form.note.tooltip')}
          isLoading={isLoading}
        />
        {showReimbursmentItemSplitBookings && splits}
      </FormLayout>
    </HookFormProvider>
  );
};
