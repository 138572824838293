import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { useTranslation } from 'react-i18next';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';

export const OutgoingInvoices = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { outgoingInvoicesPromo } = usePromotionContent();

  return (
    <SettingsLayout
      title={t('outgoingInvoices.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <div className="relative h-96">
        <PromotionsProvider promotions={[outgoingInvoicesPromo]}>
          <PromotionCard
            promotion={outgoingInvoicesPromo}
            closable={false}
            trackOption={{ eventLocation: 'settings' }}
          />
        </PromotionsProvider>
      </div>
    </SettingsLayout>
  );
};
