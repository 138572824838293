import { useCurrentUser } from 'providers/CurrentUserProvider';
import { formatDateRange } from 'views/Reimbursement/utils/formatDateTime';

interface DateTimeRangeDisplayProps {
  startDateTime: string;
  endDateTime: string;
}

export const DateTimeRangeDisplay = ({
  startDateTime,
  endDateTime,
}: DateTimeRangeDisplayProps) => {
  const locale = useCurrentUser()?.locale;
  const formattedRange = formatDateRange(startDateTime, endDateTime, {
    locale,
  });

  return (
    <span className="text-sm font-medium whitespace-nowrap block">
      {formattedRange}
    </span>
  );
};
