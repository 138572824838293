import {
  ReimbursementCaseItemAddedEventDataFragment,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const expenseItemTypeTranslations: Partial<
  Record<ReimbursementItemType, string>
> = {
  GENERAL_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
  MILEAGE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.milage',
  PER_DIEM:
    'reimbursementView.leftSection.reimbursementItems.placeholder.perdiem',
};

export const ReimbursementCaseItemAdded = ({
  properties,
}: ReimbursementCaseItemAddedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const itemType = properties.expenseItemType;

  const itemTypeTranslationKey = itemType
    ? expenseItemTypeTranslations[itemType]
    : undefined;

  if (!itemTypeTranslationKey) {
    return;
  }

  const translatedItemType = t(itemTypeTranslationKey);

  return (
    <span>
      {t('timeline.itemCreated', { expenseType: translatedItemType })}
    </span>
  );
};
