import { Flex, Tag, Text } from '@candisio/design-system';
import {
  animationVariants,
  transition,
} from 'components/NewPromotions/toolkit/animation';
import { Promotion } from 'components/NewPromotions/types';
import { motion } from 'motion/react';

const MotionText = motion.create(Text);

export const PromotionLabel = ({ label }: { label: Promotion['label'] }) => {
  if (typeof label === 'object' && label !== null && 'text' in label)
    return (
      <Tag color={label.color ?? 'purple'} variant="secondary">
        {label.text}
      </Tag>
    );

  return label;
};

export const PromotionHeader = ({ promotion }: { promotion: Promotion }) => {
  const { label, title, subtitle } = promotion;

  return (
    <Flex padding="0 space20" direction="column" gap="space8">
      {label && <PromotionLabel label={label} />}
      <MotionText
        color="gray800"
        fontSize="xlarge"
        lineHeight="space24"
        width="100%"
        overflowWrap="break-word"
        transition={transition}
        variants={animationVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {title}
      </MotionText>
      {subtitle && (
        <MotionText
          color="gray600"
          fontSize="basic"
          lineHeight={1.4}
          width="100%"
          overflowWrap="break-word"
          transition={transition}
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {subtitle}
        </MotionText>
      )}
    </Flex>
  );
};
