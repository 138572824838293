import { Grid, ScrollBox, Text, TruncatedText } from '@candisio/design-system';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { ReimbursementItemStatus } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { mappedTypesToItems } from '../LeftSection/ReimbursementItem';
import styles from './ReimbursementItemsSummary.module.css';

interface ReimbursementItemsSummaryProps {
  reimbursementItems: ReimbursementItemForList[];
}

export const ReimbursementItemsSummary = ({
  reimbursementItems,
}: ReimbursementItemsSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { setSelectedItemId } = useReimbursementFormsContext();
  const { documentMoneyFormatter } = useMoneyFormatter();

  const handleClick = (id: string) => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    setSelectedItemId(id);
  };

  return (
    <ScrollBox
      borderBottomRadius="medium"
      scrollDirection="y"
      scrollbarGutter="auto"
      maxHeight="250px"
    >
      <ul className={styles.list}>
        {reimbursementItems.map(item => {
          const { id, type, reason, totalAmount, status } = item;
          const itemType = mappedTypesToItems[type];

          const formattedTotalAmount = documentMoneyFormatter({
            amount: totalAmount?.amount ?? 0,
            currency: totalAmount?.currency ?? DocumentCurrency.Eur,
            precision: totalAmount?.precision ?? 2,
          });
          const text = reason ? reason : t(itemType.placeholder);
          const isExcluded = status === ReimbursementItemStatus.Excluded;

          const Icon = itemType.icon;

          return (
            <li key={id}>
              <button
                className={styles.item}
                onClick={() => handleClick(item.id)}
              >
                <Grid
                  templateColumns="auto 1fr auto"
                  alignItems="center"
                  gap="space12"
                >
                  <Icon />
                  <TruncatedText fontSize="basic">{text}</TruncatedText>
                  <Text
                    justifySelf="end"
                    fontSize="basic"
                    textDecoration={isExcluded ? 'line-through' : undefined}
                  >
                    {formattedTotalAmount}
                  </Text>
                </Grid>
              </button>
            </li>
          );
        })}
      </ul>
    </ScrollBox>
  );
};
