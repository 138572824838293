import { Flex } from '@candisio/design-system';
import { InfoPopupButton } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { getPlaceholderBookingAccount } from 'views/Settings/CreditCards/CreditCardExportingCard/CreditCardExportingForm/BookingAccountForm/BookingAccountForm.helper';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { useDatevProvisionsAccountValidation } from '../../hooks/useDatevProvisionsAccountValidation';

interface OtherAssetsAccountNumberProps {
  onSubmit: ({
    otherAssetsAccountNumber,
  }: {
    otherAssetsAccountNumber: string | undefined;
  }) => void;
  otherAssetsAccountNumber: string | undefined;
}

export const OtherAssetsAccountNumber = ({
  onSubmit,
  otherAssetsAccountNumber,
}: OtherAssetsAccountNumberProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    schema,
    errorMessages,
    maxLength: accountLength,
  } = useDatevProvisionsAccountValidation('otherAssetsAccountNumber');

  const form = useForm({
    defaultValues: { otherAssetsAccountNumber },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({
        t,
        label: 'provisions.setupCard.otherAssetsAccountLabel',
      }),
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    mode: 'onSubmit',
  });

  const handleSubmit = ({
    otherAssetsAccountNumber,
  }: {
    otherAssetsAccountNumber: string | undefined;
  }) => {
    if (!otherAssetsAccountNumber) {
      onSubmit({ otherAssetsAccountNumber: '' });
    } else {
      onSubmit({ otherAssetsAccountNumber });
    }

    form.resetField('otherAssetsAccountNumber', {
      defaultValue: otherAssetsAccountNumber,
    });
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end"
      >
        <div className="grid grid-flow-col items-end gap-1">
          <HookFormTextField
            label={t('provisions.setupCard.otherAssetsAccountLabel')}
            name="otherAssetsAccountNumber"
            placeholder={t('provisions.setupCard.accountPlaceholder', {
              accountNumber: getPlaceholderBookingAccount(accountLength),
            })}
            clearable={false}
          />
          <div className="pb-2">
            <InfoPopupButton
              message={t('provisions.setupCard.otherAssetsAccountInfo')}
            />
          </div>
        </div>
        <QuickSaveButtons
          isFormDirty={isFormDirty}
          resetField={() => form.resetField('otherAssetsAccountNumber')}
        />
      </Flex>
    </FormProvider>
  );
};
