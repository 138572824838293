import { Button, Flex, Heading } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { UploadDocumentMenuButton } from 'components/UploadDocumentMenuButton';
import { UserRole } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';

type DocumentsHeaderProps = {
  startProcessingLink: string;
  organizationSlug: string;
  documentsLength: number;
};

export const DocumentsHeader = ({
  documentsLength,
  organizationSlug,
  startProcessingLink,
}: DocumentsHeaderProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDocumentUploadDispatch();
  const { showEcmSensitiveDocuments } = useEcm();

  const onUploadClick = () => dispatch('showModal');

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      paddingRight="space32"
      paddingLeft="space32"
      paddingTop="space20"
      paddingBottom="space16"
    >
      <Flex alignItems="center" gap="space16">
        <Heading as="h1">{t('inbox.title')}</Heading>
        <UserRoleAccessWrapper
          allowedRoles={[
            UserRole.Admin,
            UserRole.Requester,
            UserRole.Accountant,
          ]}
          mode="hidden"
        >
          <Button
            size="small"
            variant="primary"
            disabled={documentsLength === 0}
            onClick={() => history.push(startProcessingLink)}
          >
            {t('approvals.actions.startApproving')}
          </Button>
        </UserRoleAccessWrapper>
      </Flex>
      <Flex gap="space16" alignItems="center">
        <UserRoleAccessWrapper
          allowedRoles={[
            UserRole.Admin,
            UserRole.Requester,
            UserRole.Accountant,
          ]}
          mode="hidden"
        >
          <Button
            variant="secondary"
            size="small"
            onClick={() =>
              history.push(
                `/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}`
              )
            }
          >
            {t('inbox.actions.import')}
          </Button>
          {showEcmSensitiveDocuments ? (
            <UploadDocumentMenuButton />
          ) : (
            <Button
              size="small"
              icon="plus"
              variant="primary"
              onClick={onUploadClick}
              data-cy="open-upload-modal-button"
            >
              {t('inbox.actions.upload')}
            </Button>
          )}
        </UserRoleAccessWrapper>
      </Flex>
    </Flex>
  );
};
