import { Grid, Switch, Text } from '@candisio/design-system';
import {
  ControllerRenderProps,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';

interface ReimbursementSwitchFieldProps {
  isDisabled?: boolean;
  readOnlyReason?: string;
  name: UseControllerProps['name'];
  control?: UseControllerProps['control'];
  label: string;
  onChange?: () => void;
}

export const ReimbursementSwitchField = ({
  name,
  control,
  isDisabled,
  label,
  onChange: onChangeProp,
}: ReimbursementSwitchFieldProps) => {
  const { field } = useController({ name, control });

  const { value, onChange } = field as unknown as ControllerRenderProps<
    ReimbursementItemsFormOutput['reimbursementItems'][number],
    'isItemExcluded'
  >;
  return (
    <Grid autoFlow="column" justifySelf="end" gap="space8" alignItems="center">
      <Text fontSize="basic">{label}</Text>
      <Switch
        label={label}
        disabled={isDisabled}
        name={name}
        aria-label={name}
        checked={value}
        onChange={isChecked => {
          onChange(isChecked);
          onChangeProp?.();
        }}
      />
    </Grid>
  );
};
