import { Button, InfoPanel, useTheme } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ExpenseExportNotAvailableBannerProps {
  onHideExpenseExportBanner: () => void;
}

export const ExpenseExportNotAvailableBanner = ({
  onHideExpenseExportBanner,
}: ExpenseExportNotAvailableBannerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const { colors } = useTheme();
  return (
    <InfoPanel
      variant="information"
      title={t('expenseBanner.title')}
      style={{ border: `1px solid ${colors.blue700}` }}
    >
      <div className="grid grid-cols-[1fr_auto] align-center">
        <div className="flex flex-col">
          <span className="text-[0.75rem]">{t('expenseBanner.paragraph')}</span>
        </div>
      </div>
      <Button
        top={-20}
        right={-1}
        position="absolute"
        icon="close"
        size="xsmall"
        variant="tertiary"
        onClick={onHideExpenseExportBanner}
      />
    </InfoPanel>
  );
};
