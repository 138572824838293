import {
  FieldContainer,
  FieldContainerProps,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { TimeInput } from 'components/TimeField/TimeInput';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface HookFormTimeFieldProps<TFormValues extends FieldValues>
  extends Omit<FieldContainerProps, 'onChange'> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean;
  /** Field label */
  label?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Is field disabled? */
  disabled?: boolean;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** It indicates how to show the time */
  hourCycle?: 24 | 12;
  /** callback invoked on change */
  onChange?: (value: string | null) => void;
  /** Callback fired when the user clears the input value via clear button */
  onClear?: () => void;
}

export const HookFormTimeField = <TFormValues extends FieldValues>({
  name,
  control,
  isLoading,
  label,
  readOnly: readOnlyProp,
  disabled,
  variant: variantProp,
  onChange: onChangeProp,
  onClear,
  ...restProps
}: HookFormTimeFieldProps<TFormValues>) => {
  const [t] = useTranslation();
  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, onBlur } = field;

  const { labelProps } = useLabel({
    label,
    'aria-label': label ?? restProps['aria-label'],
  });

  const handleChange = (value: string | null) => {
    onChange(value);
    onChangeProp?.(value);
  };

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;
  const readOnly = readOnlyProp || formState.isSubmitting;

  const variant = hasError ? 'error' : variantProp;

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      readOnly={readOnly}
      variant={variant}
      isLoading={isLoading}
      aria-invalid={hasError}
      {...(mergeProps(labelProps, restProps) as Omit<
        FieldContainerProps,
        'color'
      >)}
    >
      <TimeInput
        label={label ?? restProps['aria-label']}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        variant={variant}
        isDisabled={readOnly || disabled}
        errorMessage={errorMessage}
        clearLabel={t('common.clear')}
        onClear={onClear}
      />
    </FieldContainer>
  );
};
