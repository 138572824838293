import { TransactionAmount } from 'generated-types/graphql.types';
import { RequestedLimitChangeProps } from './RequestedLimitChange';

const isLimitChanged = (
  originalLimit: TransactionAmount,
  newLimit: TransactionAmount
): boolean => {
  return originalLimit.value !== newLimit.value;
};

export const getRequestedLimitChange = ({
  limit,
  transactionLimit,
  pendingRequest,
}: RequestedLimitChangeProps) => {
  if (pendingRequest?.__typename !== 'CardLimitChangeRequest') {
    return { originalLimit: undefined, newLimit: undefined };
  }

  // Handle cases where both limits exist for PhysicalCard or VirtualCard
  if (
    limit &&
    transactionLimit &&
    pendingRequest.requestedLimit &&
    pendingRequest.requestedTransactionLimit
  ) {
    const recurrentLimitChanged = isLimitChanged(
      limit,
      pendingRequest.requestedLimit
    );

    // Case 1: Both changed - show recurrent limit change
    // Case 2: Only recurrent changed - show recurrent limit change
    if (recurrentLimitChanged) {
      return {
        originalLimit: limit,
        newLimit: pendingRequest.requestedLimit,
      };
    }

    const transactionLimitChanged = isLimitChanged(
      transactionLimit,
      pendingRequest.requestedTransactionLimit
    );

    // Case 3: Only transaction limit changed - show transaction limit change
    if (transactionLimitChanged) {
      return {
        originalLimit: transactionLimit,
        newLimit: pendingRequest.requestedTransactionLimit,
      };
    }

    // Case 4: No changes - show recurrent limit
    return {
      originalLimit: limit,
      newLimit: pendingRequest.requestedLimit,
    };
  }

  // Handle single limit changes for SingleUseVirtualCard
  if (transactionLimit && pendingRequest?.requestedTransactionLimit) {
    return {
      originalLimit: transactionLimit,
      newLimit: pendingRequest.requestedTransactionLimit,
    };
  }

  return {
    originalLimit: limit,
    newLimit: pendingRequest?.requestedLimit,
  };
};
