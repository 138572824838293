import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { HospitalityExpenseInvoiceData } from './HospitalityExpenseInvoiceData';
import { extractedDataHospitalitySummary } from './utils/extractedDataSummary';
import { HospitalityFieldNames } from './utils/generateHospitalityFormFieldNames';
import {
  ExtractionStatus,
  ExtractionStatusType,
} from './utils/invoiceDataExtractionHelpers';
import styles from './ReimbursementItemsSummary.module.css';

interface ExtractedHospitalityExpenseInvoiceDataProps {
  fields: HospitalityFieldNames;
  isExcluded: boolean;
  formIndex: number;
  isExpenseFormEditable: boolean;
  extractedData?: ReturnType<typeof extractedDataHospitalitySummary>;
  extractionStatus: ExtractionStatusType;
  onUpdateTotalAmount: (index: number) => void;
  onUpdateHospitality: (index: number) => () => Promise<void>;
  onSetValue: UseFormSetValue<ReimbursementItemsFormOutput>;
}
export const ExtractedHospitalityExpenseInvoiceData = ({
  fields,
  isExcluded,
  formIndex,
  extractionStatus,
  extractedData,
  onSetValue,
  onUpdateTotalAmount,
  onUpdateHospitality,
  isExpenseFormEditable,
}: ExtractedHospitalityExpenseInvoiceDataProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const handleEditClick = () => {
    onSetValue(fields.isExtractedDataAccepted, false);

    void onUpdateHospitality(formIndex)();
  };

  const canEditInvoiceData = isExpenseFormEditable && !isExcluded;

  if (extractionStatus === ExtractionStatus.FULL_EXTRACTION) {
    return (
      <HospitalityExpenseInvoiceData
        extractedData={extractedData}
        onEditClick={handleEditClick}
        canEditInvoiceData={canEditInvoiceData}
      />
    );
  }

  return (
    <div className="grid gap-2">
      <div className={styles.extractedData}>
        {!extractedData?.location && (
          <HookFormTextField
            name={fields.location}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.location.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.location.placeholder'
            )}
            onClear={onUpdateHospitality(formIndex)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        )}
        {!extractedData?.expenseDate && (
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.date.label'
            )}
            onClear={onUpdateHospitality(formIndex)}
            onBlur={onUpdateHospitality(formIndex)}
          />
        )}

        {!extractedData?.invoiceNumber && (
          <HookFormTextField
            name={fields.invoiceNumber}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.invoiceNumber.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.invoiceNumber.placeholder'
            )}
            onClear={onUpdateHospitality(formIndex)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        )}
        {!extractedData?.receiptAmount && (
          <HookFormAmountField
            name={fields.receiptAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            onClear={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
        )}

        {!extractedData?.tipAmount && (
          <HookFormAmountField
            name={fields.tipAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            onClear={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
        )}

        {!extractedData?.totalAmount && (
          <HookFormAmountField
            name={fields.totalAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
            )}
            readOnly
            currency="EUR"
            minValue={0}
          />
        )}
      </div>
      <HospitalityExpenseInvoiceData
        extractedData={extractedData}
        onEditClick={handleEditClick}
        canEditInvoiceData={canEditInvoiceData}
      />
    </div>
  );
};
