import { ApproversAccessCardContainer } from 'components/DocumentAccess/ApproversAccessCardContainer';
import { BuiltInRolesAccessCardContainer } from 'components/DocumentAccess/BuiltInRolesAccessCardContainer';
import { CardHolderAccessCardContainer } from 'components/DocumentAccess/CardHolderAccessCardContainer';
import { ContractAccessCardContainer } from 'components/DocumentAccess/ContractAccessCardContainer';
import { ResponsiblePersonAccessCardContainer } from 'components/DocumentAccess/ResponsiblePersonAccessCardContainer';
import { SensitiveContractAccessCardContainer } from 'components/DocumentAccess/SensitiveContractAccessCardContainer';
import { UploaderAccessCardContainer } from 'components/DocumentAccess/UploaderAccessCardContainer';
import {
  DocumentType,
  EcmStorageFormInitialDataQuery,
  GetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import { ManageAccessContainer } from './ManageAccess/ManageAccessContainer';
import { useCanModifyAccess } from './toolkit/hooks/useCanModifyAccess';

interface DocumentAccessSectionsProps {
  documentType: DocumentType;
  formId: string;
  globalDocumentId: string;
  isSensitiveDocument: boolean;
  onCloseDrawer: () => void;
  readOnly?: boolean;
  setSubmitting: (isSubmitting: boolean) => void;
  accessModificationPermissions:
    | NonNullable<
        GetDocumentForDraftQuery['getDocument']
      >['accessModificationPermissions']
    | NonNullable<
        EcmStorageFormInitialDataQuery['getAggregatedEcmDocument']
      >['accessModificationPermissions'];
}

export const DocumentAccessSections = ({
  documentType,
  formId,
  globalDocumentId,
  isSensitiveDocument,
  onCloseDrawer,
  setSubmitting,
  accessModificationPermissions,
}: DocumentAccessSectionsProps) => {
  const { canModifyDocumentAccess } = useCanModifyAccess({
    accessModificationPermissions,
  });

  const [modifyEcmDocumentEditAccessFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.modifyEcmDocumentEditAccess,
  ]);

  const accessSections = useMemo(() => {
    switch (documentType) {
      case DocumentType.Invoice:
        return InvoiceAccessSections(globalDocumentId);

      case DocumentType.Contract:
        if (isSensitiveDocument) {
          return modifyEcmDocumentEditAccessFF
            ? SensitiveContractAccessSections(globalDocumentId)
            : SensitiveContractAccessSectionsDeprecated(globalDocumentId);
        }
        return modifyEcmDocumentEditAccessFF
          ? ContractAccessSections(globalDocumentId)
          : ContractAccessSectionsDeprecated(globalDocumentId);

      default:
        return GenericEcmAccessSections(globalDocumentId);
    }
  }, [
    documentType,
    isSensitiveDocument,
    globalDocumentId,
    modifyEcmDocumentEditAccessFF,
  ]);

  return (
    <div className="flex flex-col gap-4">
      <ManageAccessContainer
        formId={formId}
        globalDocumentId={globalDocumentId}
        onCloseDrawer={onCloseDrawer}
        setSubmitting={setSubmitting}
        documentType={documentType}
        readOnly={!canModifyDocumentAccess}
      />
      {accessSections}
    </div>
  );
};

const InvoiceAccessSections = (globalDocumentId: string) => (
  <>
    <ApproversAccessCardContainer globalDocumentId={globalDocumentId} />
    <CardHolderAccessCardContainer globalDocumentId={globalDocumentId} />
    <UploaderAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const SensitiveContractAccessSectionsDeprecated = (
  globalDocumentId: string
) => (
  <>
    <SensitiveContractAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const SensitiveContractAccessSections = (globalDocumentId: string) => (
  <>
    <ResponsiblePersonAccessCardContainer globalDocumentId={globalDocumentId} />
    <UploaderAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const ContractAccessSectionsDeprecated = (globalDocumentId: string) => (
  <>
    <ContractAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const ContractAccessSections = (globalDocumentId: string) => (
  <>
    <ResponsiblePersonAccessCardContainer globalDocumentId={globalDocumentId} />
    <UploaderAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const GenericEcmAccessSections = (globalDocumentId: string) => (
  <>
    <UploaderAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);
