import { Flex } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useDeferredValue, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { SearchScopeSelect } from './SearchScopeSelect';
import { SearchTypeSelect } from './SearchTypeSelect';
import { useSearchFilter } from './useSearchFilter';
import { SearchOverlayUpsell } from './SearchFilterUpsell';
import { SearchScope, SearchType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface AdvancedSearchFieldFilterOverlayESIndexProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlayESIndex({
  onApply,
  onShowPromoModal,
}: AdvancedSearchFieldFilterOverlayESIndexProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const [search] = useSearchParams();
  const query = useDeferredValue(search.get(queryParameter) ?? '');

  const { searchType, setSearchType, applySearchInputFilters } =
    useSearchFilter();
  const [hasSearchTypeUpdated, setHasSearchTypeUpdated] = useState(false);

  const onSearchTypeChange = (value: string) => {
    setSearchType(value);
    setHasSearchTypeUpdated(true);
  };

  useEffect(() => {
    if (!hasSearchTypeUpdated) {
      return;
    }
    applySearchInputFilters();
    setHasSearchTypeUpdated(false);
    onApply();
  }, [hasSearchTypeUpdated, applySearchInputFilters, onApply]);

  const onSearchScopeChange = (item: string) => {
    if (item === SearchScope.EntireDocument) {
      onShowPromoModal();
      return;
    }
    onApply();
  };

  const searchTypeItems = [
    {
      id: SearchType.PrefixMatch,
      name: t('searchFilter.popover.fields.searchType.prefixSearch'),
    },
    {
      id: SearchType.ExactMatch,
      name: t('searchFilter.popover.fields.searchType.exactSearch'),
    },
  ];

  const searchScopeItems = [
    {
      id: SearchScope.DataFields,
      name: t('searchFilter.popover.fields.searchScope.fieldSearch'),
    },
    {
      id: SearchScope.EntireDocument,
      name: t('searchFilter.popover.fields.searchScope.fullTextSearch'),
      upgrade: true,
    },
  ];

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space14">
        <SearchTypeSelect
          items={searchTypeItems}
          query={query}
          value={searchType}
          onChange={onSearchTypeChange}
        />
        <SearchScopeSelect
          items={searchScopeItems}
          value={SearchScope.DataFields}
          onChange={onSearchScopeChange}
        />
      </Flex>
      <SearchOverlayUpsell openModal={onShowPromoModal} />
    </Flex>
  );
}
