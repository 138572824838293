import { Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { DeleteReimbursementItemAction } from './DeleteReimbursementItem';
import { ReimbursementSwitchField } from './ReimbursementsSwitchField';
import { BooleanMap } from './utils/useReimbursementItemsFormActions';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

interface ReimbursementItemFooterProps {
  isExcludedFieldOptions: {
    isVisible: boolean;
    isReadOnly: boolean;
    showInfoIsExcluded: boolean;
  };
  formIndex: number;
  onUpdateExpense: (index: number) => () => Promise<void>;
  isExcludedInfoVisible: boolean;
  canManageReimbursementItems: boolean;
  isDeletingForm: BooleanMap;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  expenseId: string;
  onTriggerValidation: UseFormTrigger<ReimbursementItemsFormOutput>;
}

export const ReimbursementItemFooter = ({
  formIndex,
  isExcludedFieldOptions,
  onUpdateExpense,
  canManageReimbursementItems,
  isExcludedInfoVisible,
  isDeletingForm,
  onRemoveForm,
  expenseId,
  onTriggerValidation,
}: ReimbursementItemFooterProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { isReimbursementUpdatePending } = useReimbursementFormsContext();
  const {
    formMethods: { clearErrors },
  } = useReimbursementSplitBookingHelpers();

  return (
    <div className="grid content-start px-6">
      {isExcludedFieldOptions.isVisible && (
        <ReimbursementSwitchField
          isDisabled={
            isExcludedFieldOptions.isReadOnly || isReimbursementUpdatePending
          }
          name={`reimbursementItems.${formIndex}.isItemExcluded`}
          onChange={() => {
            onUpdateExpense(formIndex)();
            clearErrors(`reimbursementItemBookings.${formIndex}`);
            void onTriggerValidation(`reimbursementItems.${formIndex}`);
          }}
          label={t(
            'reimbursementView.middleSection.form.general.excludeItem.label'
          )}
        />
      )}

      {(isExcludedInfoVisible || canManageReimbursementItems) && (
        <Grid alignItems="center" autoFlow="column">
          {isExcludedInfoVisible && (
            <Text fontSize="basic" justifySelf="start">
              {t(
                'reimbursementView.middleSection.form.general.expenseIsExcludedInfo'
              )}
            </Text>
          )}
          {canManageReimbursementItems && (
            <DeleteReimbursementItemAction
              loading={isDeletingForm[formIndex]}
              onDelete={onRemoveForm(formIndex, expenseId)}
            />
          )}
        </Grid>
      )}
    </div>
  );
};
