import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';
import { MIN_SCALE, ZOOM_STEP } from '../constants';

export const ZoomOutButton = () => {
  const [t] = useTranslation();
  const { usePdfView, setPdfView } = usePdfViewProvider();
  const scale = usePdfView('scale');
  const isDisabled = scale <= MIN_SCALE;

  return (
    <Button
      icon="zoomOut"
      label={t('document.zoomOut')}
      onClick={() =>
        setPdfView('scale', Math.max(MIN_SCALE, scale - ZOOM_STEP))
      }
      disabled={isDisabled}
      variant="tertiary"
      size="small"
    />
  );
};
