import { Grid, Skeleton } from '@candisio/design-system';
import {
  DocumentViewSwitcher,
  DocumentViewSwitcherProps,
} from 'components/DocumentViewer/DocumentViewSwitcher';
import { DownloadSection } from 'components/DocumentViewer/Download/DownloadSection';
import { XmlViewer } from 'components/DocumentViewer/XmlViewer/XmlViewer';
import { MobileAppPromotionBanner } from 'components/MobileAppPromotionBanner/MobileAppPromotionBanner';
import mobileAppPromoQRcode from 'components/MobileAppPromotionBanner/media/Document_processing_banner_QR_code.jpg';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import isEqual from 'lodash/isEqual';
import { memo, useMemo } from 'react';
import { useFilesSelected } from 'views/Inbox/DocumentProcessing/components/useFilesSelected';
import { useMobileAppPromoBanner } from 'views/Inbox/DocumentProcessing/useMobileAppPromotionBanner';
import { AttachmentSection } from './AttachmentSection';
import { PdfInvoiceSkeleton } from './PdfViewer/PdfInvoiceSkeleton';
import { PdfViewer } from './PdfViewer/PdfViewer';
import { PDFDetails } from './utils';

export interface DocumentViewerProps {
  documentId?: string;
  attachments?: PDFDetails[];
  canAttachFiles?: CanAddAttachments;
  // @TODO TGE-51 simplify how we pass and use files with PdfViewer and Attachments
  documentFile?: PDFDetails;
  selectedFile?: PDFDetails;
  /** Handler function that is invoked whenever we select a different file to show
   * in `PdfViewer`. E.g. selecting/clicking on different attachment and/or main file.
   *
   * Commonly we pass `setSelectedPdf` from `useAttachments` here.
   */
  onSelectDocument?: (value: PDFDetails) => void;
  view?: DocumentViewSwitcherProps['view'];
  setView?: DocumentViewSwitcherProps['setView'];
  hideAttachmentSection?: boolean;
  isLoadingPdf?: boolean;
}

const isPDF = (pdfDetails?: {
  name?: string;
  url?: string;
}): pdfDetails is { name: string; url: string } =>
  !!pdfDetails && Boolean(pdfDetails.name && pdfDetails.url);

export const DocumentViewer = memo<DocumentViewerProps>(
  ({
    documentId,
    attachments,
    canAttachFiles = { fromCandis: false, fromDisk: false },
    documentFile,
    selectedFile,
    onSelectDocument,
    hideAttachmentSection,
    isLoadingPdf,
    view = 'pdf',
    setView,
  }) => {
    // @TODO TGE-51 simplify how we pass and use files with PdfViewer and Attachments
    const pdfFile = useMemo(
      () => selectedFile ?? documentFile,
      [selectedFile, documentFile]
    );

    const { onAttachFiles, isAttachingFiles } = useFilesSelected(
      attachments,
      documentFile,
      documentId
    );

    const xmlUrl = documentFile?.eInvoice?.url;

    const showXmlViewer = Boolean(xmlUrl && view && setView);

    const { showMobileAppPromoBanner } = useMobileAppPromoBanner({
      documentId,
    });

    if (isLoadingPdf || !documentFile || !isPDF(pdfFile)) {
      return (
        <Grid gap="space10" templateRows="auto 1fr" height="100%">
          <Skeleton height="space32" width="100%" />
          <PdfInvoiceSkeleton />
        </Grid>
      );
    }

    const downloadSection = (
      <DownloadSection
        documentId={documentId ?? ''}
        documentFile={documentFile}
        attachments={attachments}
      />
    );

    return view === 'xml' && showXmlViewer ? (
      <XmlViewer
        xmlUrl={xmlUrl}
        additionalControls={
          <DocumentViewSwitcher view={view} setView={setView} />
        }
        downloadSection={downloadSection}
      />
    ) : (
      <PdfViewer
        documentId={documentId}
        mobileAppPromo={
          showMobileAppPromoBanner && (
            <MobileAppPromotionBanner
              fontSize="basic"
              qrCode={mobileAppPromoQRcode}
              bannerHeight="110px"
              imageHeight="110px"
              imageWidth="150px"
              localStorageKey="mobile_app_promotion_banner_document_processing"
            />
          )
        }
        attachments={attachments}
        documentFile={documentFile}
        selectedFile={selectedFile}
        onSelectDocument={onSelectDocument}
        downloadSection={downloadSection}
        additionalControls={
          showXmlViewer && (
            <DocumentViewSwitcher view={view} setView={setView} />
          )
        }
        attachmentSection={
          !hideAttachmentSection && (
            <AttachmentSection
              canAttachFiles={canAttachFiles}
              isAttachingFiles={isAttachingFiles}
              onAttachFiles={onAttachFiles}
              documentId={documentId ?? ''}
            />
          )
        }
      />
    );
  },
  isEqual
);
