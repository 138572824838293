import clsx from 'clsx';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../DaysStep.module.css';
import { Button } from 'react-aria-components';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';
import { DashedBorder } from 'components/DashedBorder/DashedBorder';
import { SelectionVariant } from '../../utils/getMealsSelectorVariant';

interface MultiSelectorButtonProps {
  displayValue: ReactNode;
  id: string;
  isDisabled?: boolean;
  onChange?: (params: SelectAllColumnPerDiemParams) => void;
  onGetMultiSelectorState: (
    params: GetMultiSelectorStatePerDiemParams
  ) => SelectionVariant;
}

export const MultiSelectorButton = ({
  displayValue,
  id,
  isDisabled = false,
  onChange,
  onGetMultiSelectorState,
}: MultiSelectorButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const isDisplayValueString = typeof displayValue === 'string';
  const variant = isDisplayValueString ? 'small' : 'regular';

  const status = onGetMultiSelectorState({ id });

  const handleChange = () => {
    if (status === 'partial' || status === 'none') {
      onChange?.({ value: true, id });
      return;
    }
    onChange?.({ value: false, id });
  };

  return (
    <div className="relative flex">
      <Button
        id={id}
        isDisabled={isDisabled}
        data-selection={status}
        onPress={handleChange}
        className={clsx(
          styles['toggle-button'],
          styles[`toggle-button--${variant}`],
          {
            [styles['disabled']]: isDisabled,
          }
        )}
      >
        {isDisplayValueString ? (
          <span className="text-basic">{t(displayValue)}</span>
        ) : (
          displayValue
        )}
      </Button>
      {status === 'partial' && (
        <DashedBorder
          className={clsx(
            styles['dashed-border'],
            isDisabled && styles['disabled']
          )}
        />
      )}
    </div>
  );
};
