import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { ReimbursementItemsSummary } from './ReimbursementItemsSummary';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { ReimbursementItemSummaryCard } from './ReimbursementItemSummaryCard';

interface ExpenseDetailsCardProps {
  reimbursementItems?: ReimbursementItemForList[];
}

export const ExpenseDetailsCard = ({
  reimbursementItems = [],
}: ExpenseDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();

  const { transactionAmountFormatter } = useMoneyFormatter();

  const filteredReimbursementItems = reimbursementItems.filter(item =>
    [
      ReimbursementItemType.GeneralExpense,
      ReimbursementItemType.HospitalityExpense,
    ].includes(item.type)
  );

  // XXX : This value should be coming from BE since amounts could be in different currencies.
  // it will be fixed in here -> https://candis.atlassian.net/jira/software/projects/TA/boards/64/backlog?selectedIssue=TA-5219
  const totalAmount = filteredReimbursementItems.reduce(
    (acc, value) => acc + (value.totalAmount?.amount ?? 0),
    0
  );

  const formattedTotalAmount = transactionAmountFormatter({
    precision: 2,
    currency: 'EUR',
    value: totalAmount,
  });

  const count = filteredReimbursementItems.length ?? undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!count) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
  };

  return (
    <ReimbursementItemSummaryCard
      count={count}
      description={t(
        'reimbursementView.middleSection.emptyDetails.description'
      )}
      title={t('reimbursementView.middleSection.emptyDetails.title')}
      formattedTotalAmount={count > 0 ? formattedTotalAmount : undefined}
      icon="receipt"
      iconColor="green"
      isClickable={!!count}
      onClick={handleClick}
      customListItemsWithCta={
        !!filteredReimbursementItems.length ? (
          <ReimbursementItemsSummary
            reimbursementItems={filteredReimbursementItems}
          />
        ) : undefined
      }
    />
  );
};
