import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { PerDiemDetailsCard } from './PerDiemDetailsCard';
import { ExpenseDetailsCard } from './ExpenseDetailsCard';
import { TravelPromotion } from './TravelPromotion/TravelPromotion';
import { MilageDetailsCard } from './MilageDetailsCard';

interface ReimbursementItemSummaryContainerProps {
  reimbursementItems?: ReimbursementItemForList[];
}

export const ReimbursementItemSummaryContainer = ({
  reimbursementItems = [],
}: ReimbursementItemSummaryContainerProps) => {
  const { canUsePerDiemReimbursement, canUseMilageReimbursement } =
    useReimbursement();

  const isTravelSectionVisible =
    canUseMilageReimbursement || canUsePerDiemReimbursement;

  return (
    <div className="grid gap-4">
      <ExpenseDetailsCard reimbursementItems={reimbursementItems} />
      {isTravelSectionVisible ? (
        <>
          {canUsePerDiemReimbursement && (
            <PerDiemDetailsCard reimbursementItems={reimbursementItems} />
          )}
          {canUseMilageReimbursement && (
            <MilageDetailsCard reimbursementItems={reimbursementItems} />
          )}
        </>
      ) : (
        <TravelPromotion />
      )}
    </div>
  );
};
