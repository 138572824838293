import { PropsWithChildren } from 'react';
import styles from './power-search-toolbar.module.css';
import clsx from 'clsx';
import { ToolbarAreaSearch } from './ToolbarAreaSearch';
import { ToolbarAreaActiveFilters } from './ToolbarAreaActiveFilters';
import { ToolbarAreaTools } from './ToolbarAreaTools';

interface PowerSearchToolbarProps extends PropsWithChildren {
  className?: string;
}

const PowerSearchToolbar = ({
  className,
  ...props
}: PowerSearchToolbarProps) => {
  return (
    <div className={clsx(styles.powerSearchToolbar, className)} {...props} />
  );
};

PowerSearchToolbar.AreaSearch = ToolbarAreaSearch;
PowerSearchToolbar.AreaActiveFilters = ToolbarAreaActiveFilters;
PowerSearchToolbar.AreaTools = ToolbarAreaTools;

export { PowerSearchToolbar };
