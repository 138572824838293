import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { ExtractedHospitalityExpenseInvoiceData } from './ExtractedHospitalityExpenseInvoiceData';
import { ExtractionLoader } from './GeneralExpenseInvoiceDataFields';
import { extractedDataHospitalitySummary } from './utils/extractedDataSummary';
import { HospitalityFieldNames } from './utils/generateHospitalityFormFieldNames';
import {
  ExtractionStatus,
  ExtractionStatusType,
} from './utils/invoiceDataExtractionHelpers';

export interface ExtractedData {
  invoiceNumber?: string;
  location?: string;
  expenseDate?: string;
  totalAmount?: number | null;
  receiptAmount?: number | null;
  tipAmount?: number | null;
}
interface HospitalityExpenseInvoiceDataFieldsProps {
  fields: HospitalityFieldNames;
  isLoadingInvoiceData: boolean;
  isExcluded: boolean;
  formIndex: number;
  totalAmount?: number | null;
  isExpenseFormEditable: boolean;
  extractedData?: ExtractedData;
  extractionStatus: ExtractionStatusType;
  onUpdateTotalAmount: (index: number) => void;
  onUpdateHospitality: (index: number) => () => Promise<void>;
  onSetValue: UseFormSetValue<ReimbursementItemsFormOutput>;
}
export const HospitalityExpenseInvoiceDataFields = ({
  fields,
  isExcluded,
  isLoadingInvoiceData,
  formIndex,
  extractionStatus,
  extractedData,
  onSetValue,
  onUpdateTotalAmount,
  onUpdateHospitality,
  isExpenseFormEditable,
  totalAmount,
}: HospitalityExpenseInvoiceDataFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  if (isLoadingInvoiceData) {
    return <ExtractionLoader />;
  }

  if (extractionStatus === ExtractionStatus.NO_EXTRACTION) {
    return (
      <>
        <HookFormTextField
          name={fields.location}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.hospitality.location.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.location.placeholder'
          )}
          onClear={onUpdateHospitality(formIndex)}
          inputProps={{
            onBlur: onUpdateHospitality(formIndex),
          }}
        />
        <HookFormDatePickerField
          name={fields.expenseDate}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.hospitality.date.label'
          )}
          onClear={onUpdateHospitality(formIndex)}
          onBlur={onUpdateHospitality(formIndex)}
        />
        <HookFormTextField
          name={fields.invoiceNumber}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.hospitality.invoiceNumber.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.invoiceNumber.placeholder'
          )}
          onClear={onUpdateHospitality(formIndex)}
          inputProps={{
            onBlur: onUpdateHospitality(formIndex),
          }}
        />
        <HookFormAmountField
          name={fields.receiptAmount}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
          )}
          currency="EUR"
          minValue={0}
          onBlur={() => {
            onUpdateTotalAmount(formIndex);
            onUpdateHospitality(formIndex)();
          }}
          onClear={() => {
            onUpdateTotalAmount(formIndex);
            onUpdateHospitality(formIndex)();
          }}
        />
        <HookFormAmountField
          name={fields.tipAmount}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.hospitality.tipAmount.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
          )}
          currency="EUR"
          minValue={0}
          onBlur={() => {
            onUpdateTotalAmount(formIndex);
            onUpdateHospitality(formIndex)();
          }}
          onClear={() => {
            onUpdateTotalAmount(formIndex);
            onUpdateHospitality(formIndex)();
          }}
        />
        <HookFormAmountField
          name={fields.totalAmount}
          label={t(
            'reimbursementView.middleSection.form.hospitality.totalAmount.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
          )}
          readOnly
          currency="EUR"
          minValue={0}
        />
      </>
    );
  }

  const formattedData = extractedDataHospitalitySummary(
    totalAmount,
    extractedData
  );

  return (
    <ExtractedHospitalityExpenseInvoiceData
      onSetValue={onSetValue}
      extractionStatus={extractionStatus}
      extractedData={formattedData}
      fields={fields}
      formIndex={formIndex}
      isExcluded={isExcluded}
      onUpdateHospitality={onUpdateHospitality}
      onUpdateTotalAmount={onUpdateTotalAmount}
      isExpenseFormEditable={isExpenseFormEditable}
    />
  );
};
