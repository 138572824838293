import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import styles from './RequestedLimitChange.module.css';
import { CardIssuerCardEdgeDataFragment } from 'generated-types/graphql.types';
import { getRequestedLimitChange } from './getRequestedLimitChange';

export type RequestedLimitChangeProps = {
  limit?: CardIssuerCardEdgeDataFragment['node']['limit'];
  transactionLimit?: CardIssuerCardEdgeDataFragment['node']['transactionLimit'];
  pendingRequest?: CardIssuerCardEdgeDataFragment['node']['pendingRequest'];
};

export const RequestedLimitChange = ({
  limit,
  transactionLimit,
  pendingRequest,
}: RequestedLimitChangeProps) => {
  const { transactionAmountFormatter } = useMoneyFormatter();

  const { originalLimit, newLimit } = getRequestedLimitChange({
    limit,
    transactionLimit,
    pendingRequest,
  });

  return (
    <div className={styles.wrapper}>
      {originalLimit && (
        <span className={styles.amount}>
          {transactionAmountFormatter(originalLimit)}
        </span>
      )}
      {newLimit && <span>{transactionAmountFormatter(newLimit)}</span>}
    </div>
  );
};
