import { Grid, ScrollBox } from '@candisio/design-system';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import { DocumentHistoryComponent } from 'containers/document-summary/DocumentSummarySection';
import { useTranslation } from 'react-i18next';
import {
  ExpandingDrawer,
  MAX_DRAWER_WIDTH,
} from 'views/DocumentDetails/ExpandingDrawer';
import { PurchaseOrderSectionContainer } from 'views/DocumentDetails/components/PurchaseOrderSection/PurchaseOrderSectionContainer';
import { TransactionSection } from 'views/DocumentDetails/components/TransactionSection';
import { useInvoiceDocumentPermissions } from 'views/Inbox/DocumentProcessing/useInvoiceDocumentPermissions';
import { useInboxContext } from '../Context';
import { AttachmentsSegment } from './components/AttachmentsSegment';
import { DocumentRelationsSegment } from './components/DocumentRelationsSegment';
import { DocumentSummaryInformationAdapter } from './components/DocumentSummaryInformation';

export interface LeftSectionProps {
  setSelectedRelationId?: (id: string) => void;
  selectedRelationId?: string | undefined;
}

export const LeftSection = ({
  selectedRelationId,
  setSelectedRelationId,
}: LeftSectionProps) => {
  const [t] = useTranslation();

  const {
    globalDocumentId,
    documentId,
    mainDocumentFile,
    documentStatus,
    documentAmount,
    contactItem,
    contactName,
    transaction,
    accountsPayableNumber,
    useDocumentNavigationResult: {
      listNavigatorTitle,
      gotoNextDocument,
      gotoPrevDocument,
      handleBackToList,
      isNavigationLoading,
    },
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
    isDocumentDataLoading,
    isDocumentFileLoading,
    canUseStoredContactInfo,
    showPurchaseOrderSection,
    canAddTransaction,
    view,
    setView,
  } = useInboxContext();

  const { canAddRelationship } = useInvoiceDocumentPermissions({
    id: documentId,
    skip: !documentId,
  });

  const attachmentsTitle = t('document.tabs.oldAttachments.title');
  const relationsTitle = t('document.tabs.relationships.title');

  const transactionSection = (
    <TransactionSection
      loading={
        transaction ? isDocumentDataLoading || isDocumentFileLoading : false
      }
      documentId={documentId}
      transaction={dataToTransactionDetails(transaction)}
      canAddTransaction={canAddTransaction}
      documentStatus={documentStatus}
    />
  );

  return (
    <>
      <LeftSectionOuterWrapper>
        <ListNavigator
          backToListText={t('document.backToDocumentList')}
          arrowLeftTooltip={t('document.previousDocument')}
          arrowRightTooltip={t('document.nextDocument')}
          listNavigatorCounter={listNavigatorTitle}
          onBackToList={handleBackToList}
          onNext={gotoNextDocument}
          onPrev={gotoPrevDocument}
          loading={isNavigationLoading}
        />
        <LeftSectionContentWrapper>
          {/*
        // cursed: Chrome browser renders scrollbars outside component's border
        // we add static ScrollBox to reserve same gutter space as for
        // scrollable DocumentSummarySection 
        */}
          <ScrollBox scrollDirection="none" scrollbarGutter="stable">
            <DocumentSummaryInformationAdapter
              transactionSection={transactionSection}
            />
          </ScrollBox>
          <ScrollBox
            scrollDirection="y"
            scrollbarGutter="stable"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div className={'gap-2 flex flex-col min-h-full'}>
              {showPurchaseOrderSection && (
                <PurchaseOrderSectionContainer
                  loading={isDocumentDataLoading}
                  documentId={documentId}
                  documentStatus={documentStatus}
                  documentAmount={documentAmount}
                  contactName={
                    canUseStoredContactInfo
                      ? contactName
                      : contactItem?.children
                  }
                  accountsPayableNumber={
                    canUseStoredContactInfo
                      ? accountsPayableNumber
                      : contactItem?.accountsPayableNumber
                  }
                />
              )}
              <DocumentHistoryComponent documentId={documentId} />
            </div>
          </ScrollBox>
        </LeftSectionContentWrapper>
      </LeftSectionOuterWrapper>

      <Grid
        key={documentId}
        maxWidth={MAX_DRAWER_WIDTH}
        height="100cqh"
        overflow="hidden"
      >
        <ExpandingDrawer drawer="ATTACHMENTS" title={attachmentsTitle}>
          <AttachmentsSegment
            documentId={documentId}
            documentFile={mainDocumentFile}
            attachments={attachments}
            attachPermissions={attachPermissions}
            onAttachmentClick={setSelectedPdf}
            selectedPdf={selectedPdf}
            view={view}
            setView={setView}
          />
        </ExpandingDrawer>
        <ExpandingDrawer drawer="RELATIONSHIPS" title={relationsTitle}>
          <DocumentRelationsSegment
            globalDocumentId={globalDocumentId}
            selectedRelationId={selectedRelationId}
            setSelectedRelationId={setSelectedRelationId}
            readOnly={!canAddRelationship}
          />
        </ExpandingDrawer>
      </Grid>
    </>
  );
};
