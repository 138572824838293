import { Spinner, SpinnerProps } from '@candisio/design-system';
import { useDeferredState } from 'hooks/useDeferredState';
import { useEffect } from 'react';

type DeferredSpinnerProps = {
  size?: SpinnerProps['size'];
  color?: SpinnerProps['color'];
  delay?: number;
  className?: string;
};

export const DeferredSpinner = ({
  size = 24,
  color,
  delay = 250,
  className,
}: DeferredSpinnerProps) => {
  const [isVisible, setVisible] = useDeferredState(false);

  useEffect(() => {
    setVisible(true, delay);
  }, [setVisible, delay]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={className}>
      <Spinner size={size} color={color} />
    </div>
  );
};
