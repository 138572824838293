import { useApolloClient } from '@apollo/client';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ContactRelationshipType,
  useCreateContactMutation,
  useUpdateContactMutation,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContactDetails,
  ContactDetailsProps,
} from 'views/Contacts/ContactDetails/ContactDetails';
import { getGraphQLValidationErrorMessage } from 'views/Contacts/ContactDetails/ContactForm/getGraphQLValidationErrorMessage';
import { useCheckAccountsNumber } from 'views/Contacts/ContactDetails/useCheckAccountsNumber';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { useCreatePaymentCondition } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/useCreatePaymentCondition';
import { useContactRefetchQueries } from '../../queries/contacts';
import { contactFragment } from '../queries';
import { ContactFormOutput } from './ContactForm/contactFormSchema';
import { useActivateContacts } from './useActivateContacts';
import { useArchiveContacts } from './useArchiveContacts';
import { useCheckContactName } from './useCheckContactName';
import { useContactFormDefaultValues } from './useContactFormDefaultValues';
import { useContactMutationInput } from './useContactMutationInput';
import { useGetTeamMemberByContactNameQuery } from './useGetTeamMemberByContactName';
import { useNextFreeAccountsPayableNumber } from './useNextFreeAccountsPayableNumber';
import { usePaymentConditionItems } from './usePaymentConditionItems';
import { useGetContactEmployeeTypeByMembershipId } from './useGetContactEmployeeTypeByMembershipId';
import { CREATE_REIMBURSEMENT_ERROR } from 'views/Reimbursement/hooks/useCreateReimbursement';

export interface ContactDetailsContainerProps {
  /** Unique id of contact to display, leave undefined for new contact */
  contactId?: string;
  /** Is contact details drawer open? */
  isOpen?: ContactDetailsProps['isOpen'];
  /** Called when contact details drawer should close */
  onClose?: ContactDetailsProps['onClose'];
}

/** Handles data fetching for ContactDetails component  */
export const ContactDetailsContainer = ({
  contactId,
  isOpen,
  onClose,
}: ContactDetailsContainerProps) => {
  const client = useApolloClient();
  const { success, error } = useToastMessage();
  const [t] = useTranslation();
  const { isAdmin, isAccountant } = useUserRoles();

  const { archiving, handleArchive } = useArchiveContacts();
  const { activating, handleActivate } = useActivateContacts();
  const { users, loading } = useUsersFieldOptions({
    status: 'Active',
  });

  const fieldOptions = {
    teamMembers: {
      items: users,
      loading,
    },
  };

  const { queries: contactRefetchQueries, evictPaginationResults } =
    useContactRefetchQueries({ contactId });

  const { handleGetTeamMemberByContactName } =
    useGetTeamMemberByContactNameQuery();

  const { handleGetContactEmployeeTypeByMembershipId } =
    useGetContactEmployeeTypeByMembershipId();

  const [createContact, { loading: creating }] = useCreateContactMutation({
    refetchQueries: contactRefetchQueries,
    onCompleted: () => {
      evictPaginationResults();
    },
  });

  const {
    defaultValues,
    legacyDueDateOffset,
    loading: loadingDefaultValues,
    contact,
  } = useContactFormDefaultValues({ contactId });

  const [updateContact, { loading: updating }] = useUpdateContactMutation({
    refetchQueries: contactRefetchQueries,
    onCompleted: ({ updateContact }) => {
      // Replace old contact cache id with newly updated one
      // We have to do this because of cache id being id+name
      if (
        contact &&
        updateContact &&
        updateContact.relationshipType !== ContactRelationshipType.Employee
      ) {
        const oldContactCacheId = client.cache.identify(contact);
        client.cache.writeFragment({
          id: oldContactCacheId,
          data: updateContact,
          fragment: contactFragment,
        });
      }

      if (
        contact &&
        updateContact &&
        updateContact.relationshipType === ContactRelationshipType.Employee
      ) {
        client.cache.evict({ fieldName: 'contactsPagination' });
      }
    },
  });

  const { checkAccountsReceivableNumber, checkAccountsPayableNumber } =
    useCheckAccountsNumber({
      originalPayableValue: defaultValues.accountsPayableNumber,
      originalReceivableValue: defaultValues.accountsReceivableNumber,
      contactId,
    });

  const checkContactName = useCheckContactName({
    originalValue: defaultValues.name,
    contactId,
  });

  const { items: paymentConditionItems, loading: loadingPaymentCondtionItems } =
    usePaymentConditionItems();

  const {
    nextFreeAccountsPayableNumber,
    loading: loadingNextFreeAccountsPayableNumber,
  } = useNextFreeAccountsPayableNumber();

  const { getMutateContactInput } = useContactMutationInput();

  const handleSubmit = async (formOutput: ContactFormOutput) => {
    const input = getMutateContactInput(formOutput, legacyDueDateOffset);

    if (contactId) {
      const { data, errors } = await updateContact({
        variables: { id: contactId, input },
      });

      const hasDuplicateContactError = (errors ?? []).some(error =>
        error.message.includes(
          CREATE_REIMBURSEMENT_ERROR.CONTACT_NAME_DUPLICATE
        )
      );
      if (hasDuplicateContactError) {
        error(t('settings.contacts.details.edit.errors.duplicate'));
      }

      if (data?.updateContact?.__typename === 'Contact') {
        success(t('settings.contacts.messages.updated'));

        onClose?.();
      } else {
        const validationErrorMessage = getGraphQLValidationErrorMessage(
          errors || []
        );

        error(
          validationErrorMessage ?? t('settings.contacts.messages.updateFailed')
        );
      }
    } else {
      const { data, errors } = await createContact({ variables: { input } });

      if (data?.createContact.__typename === 'ContactCreated') {
        success(t('settings.contacts.messages.created'));

        onClose?.();
      } else {
        const validationErrorMessage = getGraphQLValidationErrorMessage(
          errors || []
        );

        error(validationErrorMessage ?? 'Creating contact failed');
      }
    }
  };

  const { create: createPaymentCondition } = useCreatePaymentCondition();

  const handleCreatePaymentCondition = async (
    values: PaymentConditionsFormOutput
  ) => {
    const result = await createPaymentCondition(values);
    if (result.status === 'success') {
      success(t('settings:paymentConditions.messages.created'));

      return result.id;
    } else {
      error(t('settings:paymentConditions.messages.createFailed'));
    }
  };

  const contactName = defaultValues.companyName ?? defaultValues.name;

  const handleContactArchive = useCallback(async () => {
    if (!contactId) return;

    await handleArchive([contactId], contactName);
    onClose?.();
  }, [contactId, contactName, handleArchive, onClose]);

  const handleContactActivate = useCallback(async () => {
    if (!contactId || !contact?.isArchived) return;

    await handleActivate([contactId], contactName);
    onClose?.();
  }, [contact?.isArchived, contactId, contactName, handleActivate, onClose]);

  return (
    <ContactDetails
      contactId={contactId}
      checkContactName={checkContactName}
      checkAccountsPayableNumber={checkAccountsPayableNumber}
      checkAccountsReceivableNumber={checkAccountsReceivableNumber}
      onGetTeamMemberByContactName={handleGetTeamMemberByContactName}
      onGetContactEmployeeTypeByMembershipId={
        handleGetContactEmployeeTypeByMembershipId
      }
      action={contactId ? 'edit' : 'create'}
      defaultValues={defaultValues}
      isOpen={isOpen}
      fieldOptions={fieldOptions}
      legacyDueDateOffset={legacyDueDateOffset}
      isContactDataLoading={
        loadingDefaultValues ||
        loadingNextFreeAccountsPayableNumber ||
        loadingPaymentCondtionItems
      }
      onClose={onClose}
      onCreatePaymentCondition={
        isAdmin || isAccountant ? handleCreatePaymentCondition : undefined
      }
      onSubmit={handleSubmit}
      onArchive={handleContactArchive}
      archiving={archiving}
      paymentConditionItems={paymentConditionItems}
      submitting={creating || updating}
      suggestedAccountsPayableNumber={nextFreeAccountsPayableNumber}
      isActive={!contact?.isArchived}
      onActivate={handleContactActivate}
      activating={activating}
    />
  );
};
