import { Button, Text } from '@candisio/design-system';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ActionBar } from 'components/Sidebar/ActionBar';
import {
  ConnectionStatus,
  useSapB1SettingsQuery,
} from 'generated-types/graphql.types';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { useSap } from 'orgConfig/sap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ConfigureButton } from 'views/Integrations/Export/toolkit/components/ConfigureButton';
import { useIsExporting } from 'views/Integrations/Export/toolkit/hooks/useIsExporting';
import { hasTransaction } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntity } from 'views/Integrations/Export/types';
import { SapB1ConfigureSapButton } from './SapB1ConfigurationButton';

type SapB1ExportButtonProps = {
  loading: boolean;
  onExport: () => void;
  entities: ExportEntity[];
  hasExportableEntity: boolean;
};

export const SapB1ExportButton = ({
  onExport,
  loading,
  entities,
  hasExportableEntity,
}: SapB1ExportButtonProps) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { isExporting } = useIsExporting();
  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);

  const { data: sapB1Credentials } = useGetSapB1Credentials();

  const { shouldUseSapUdfCheck: isSapUdfCheckEnabled } = useSap();
  const { data: sapB1Settings, loading: settingsLoading } =
    useSapB1SettingsQuery();

  const sapB1ConnectedSuccessfully =
    isSapUdfCheckEnabled &&
    sapB1Settings?.sapB1Settings?.connectionDetails?.connectionStatus ===
      ConnectionStatus.ConnectedSuccessfully;

  const isSapB1Configured = isSapUdfCheckEnabled
    ? sapB1ConnectedSuccessfully
    : Boolean(sapB1Credentials?.sapB1Credentials?.companyDbId);

  const noExportableEntitiesError = !hasExportableEntity && isExportTriggered;

  const handleExport = () => {
    setIsExportTriggered(true);
    if (hasExportableEntity) {
      void onExport();
    }
  };

  if (isExporting || settingsLoading) {
    let progressText;
    if (isExporting) {
      progressText = t('export.exportForm.actionBar.exporting');
    } else {
      progressText = t('export.exportForm.actionBar.loading');
    }

    return (
      <ActionBar>
        <ProgressBar completion={0}>{progressText}</ProgressBar>
      </ActionBar>
    );
  }

  const creditCardTransactionExists = entities.some(hasTransaction);
  if (
    creditCardTransactionExists &&
    !sapB1Settings?.sapB1Settings?.creditCardId
  ) {
    return (
      <ActionBar>
        <ConfigureButton
          message={t('credit-cards:creditCardExportingSap.missingCreditCardId')}
          buttonLabel={t(
            'credit-cards:creditCardExportingSap.accountNumbers.creditCardConfigLink'
          )}
          loading={false}
          onClick={() =>
            navigate(
              `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
            )
          }
        />
      </ActionBar>
    );
  }

  return (
    <ActionBar>
      {noExportableEntitiesError && (
        <Text color="red500" fontSize="xsmall" textAlign="center">
          {t('export.exportForm.actionBar.noExportableDocuments')}
        </Text>
      )}
      {!isSapUdfCheckEnabled || isSapB1Configured ? (
        <Button
          onClick={isSapB1Configured ? handleExport : undefined}
          disabled={!isSapB1Configured || noExportableEntitiesError}
          data-cy="submit"
          loading={loading || isExporting}
        >
          {t('export.exportForm.actionBar.export')}
        </Button>
      ) : (
        <SapB1ConfigureSapButton />
      )}
    </ActionBar>
  );
};
