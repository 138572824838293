import {
  CardIssuerSettlementPaidSettlementsFilter,
  ExportableEntityType,
  PaymentSettlementStatus,
  ProvisionEntityType,
  useCardSettlementsReadyForExportQuery,
  useCardTransactionsNotReadyForExportQuery,
  useCardTransactionsReadyForExportQuery,
  useCountExportableContactsQuery,
  useExportQuery,
  usePaymentConditionsCountQuery,
  useProvisionsReadyForExportQuery,
  useReversalsReadyForExportQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDatev } from 'orgConfig/datev';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import {
  isSupportedInOutDocument,
  isSupportedOtherDocument,
} from 'views/utils/DocumentDirection';
import { ExportContextProps } from '../../Context';
import { ExportErrorCodes } from '../../consts';
import {
  DocumentType,
  ExportEntity,
  ExportEntityRow,
  ExportType,
} from '../../types';
import { useGetExportEntitiesRows } from '../utils/getExportEntitiesRows';
import { gqlExportToExportDetails } from '../utils/gqlExportToExportDetails';
import { mapCardSettlementToExportEntity } from '../utils/mapCardSettlementToExportEntity';
import { mapExportRows } from '../utils/mapExportRows';
import { mapReimbursementItemToExportEntity } from '../utils/mapReimbursementItemToExportEntity';
import { toDocumentExportEntity } from '../utils/toDocumentExportEntity';
import {
  documentToExportEntity,
  txnWithoutDocumentToExportEntity,
} from '../utils/toExportEntity';
import { toProvisionExportEntity } from '../utils/toProvisionExportEntity';
import { toReimbursementItemExportEntity } from '../utils/toReimbursementItemExportEntity';
import { toReversalExportEntity } from '../utils/toReversalExportEntity';
import { toSettlementExportEntity } from '../utils/toSettlementExportEntity';
import { toTransactionExportEntity } from '../utils/toTransactionExportEntity';
import {
  ExportTableDataResult,
  ExportTableDataResultDeprecated,
  useExportTableData,
  useExportTableDataDeprecated,
} from './useExportTableData';
import { useExportableEntitiesCounter } from './useExportableEntitiesCounter';
import { useReimbursementItemsReadyForExport } from './useReimbursementItemsReadyForExport';
import { useEcm } from 'orgConfig/ecm/useEcm';

const toAmountSum = (sum: number, entity: ExportEntityRow): number => {
  sum += entity.netAmount?.amount ?? 0;

  return sum;
};

const parseFailedDocuments = (documents: ExportEntityRow[]) =>
  documents
    .filter(
      document =>
        document.exportStatus?.exportError?.errorCode !==
          ExportErrorCodes.ABORTED_BECAUSE_OF_OTHER_DOCUMENT &&
        document.type !== ExportableEntityType.ReimbursementItem
    )
    .map(document => ({
      id: document.id,
      invoiceNumber: document.invoiceNumber,
    }));

const parseFailedReimbursementItems = (reimbursementItems: ExportEntityRow[]) =>
  reimbursementItems
    .filter(
      reimbursementItem =>
        reimbursementItem.exportStatus?.exportError?.errorCode !==
          ExportErrorCodes.ABORTED_BECAUSE_OF_OTHER_DOCUMENT &&
        reimbursementItem.type === ExportableEntityType.ReimbursementItem
    )
    .map(reimbursementItem => ({
      id: reimbursementItem.id,
      invoiceNumber: reimbursementItem.invoiceNumber,
    }));

export const useExportDataGigaFactory = ({
  exportId,
  exportType,
  isReExport,
  timeframe,
  includeEntitiesWithoutDoc,
  selectedProvisions,
}: ExportContextProps) => {
  const { exportProvisionsFF } = useDatev(); // BDS-checked

  const [
    bdsExportPaymentConditionsAndContactsFF,
    exportProvisionsWithDATEVFormatFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.bdsExportPaymentConditionsAndContacts,
    FEATURE_FLAGS.exportProvisionsDatevFormatInternal,
  ]);

  const {
    refetch: refetchCounterQuery,
    loading: loadingCountsExportableEntities,
    readyToExportEntitiesCount,
    notReadyToExportEntitiesCount,
    provisionsCount,
  } = useExportableEntitiesCounter({});

  const { showConsistentSortAndFiltering } = useEcm();

  const exportTableData = useExportTableData(!showConsistentSortAndFiltering);
  const exportTableDataDeprecated = useExportTableDataDeprecated(
    showConsistentSortAndFiltering
  );

  let paginatedDocumentsTableData:
    | ExportTableDataResult
    | ExportTableDataResultDeprecated;

  if (showConsistentSortAndFiltering) {
    paginatedDocumentsTableData = exportTableData;
  } else {
    paginatedDocumentsTableData = exportTableDataDeprecated;
  }
  const documentExportEntities = paginatedDocumentsTableData.list.map(
    documentToExportEntity
  );
  const loadingExportTableData = paginatedDocumentsTableData.isLoadingMore;

  const {
    refetch: refetchCardTransactionsNotReadyForExportQuery,
    data: cardTransactionsNotReadyForExport,
    loading: loadingCardTransactionsNotReady,
  } = useCardTransactionsNotReadyForExportQuery();

  const transactionsNotReadyForExport = Array<ExportEntity>().concat(
    ...(cardTransactionsNotReadyForExport?.cardTransactionsNotReadyForExport?.associatedCardTransactions?.map(
      documentToExportEntity
    ) ?? []),
    ...(cardTransactionsNotReadyForExport?.cardTransactionsNotReadyForExport?.unassociatedCardTransactions?.map(
      txnWithoutDocumentToExportEntity
    ) ?? [])
  );

  const {
    data: readyToExportCardSettlements,
    loading: loadingCardSettlementsReady,
    refetch: refetchCardSettlementsReadyForExportQuery,
  } = useCardSettlementsReadyForExportQuery({
    variables: {
      limit: 5000,
      statuses: [PaymentSettlementStatus.Paid],
      paidSettlements:
        CardIssuerSettlementPaidSettlementsFilter.ExcludeRecentMostFromOpenStatements,
    },
  });

  const readyToExportCardSettlementsExportDocument =
    readyToExportCardSettlements?.cardSettlementsReadyForExport?.node?.map(
      mapCardSettlementToExportEntity
    ) ?? [];

  const {
    data: notReadyToExportCardSettlements,
    loading: loadingCardSettlementsNotReady,
    refetch: refetchNotReadyToExportCardSettlementsQuery,
  } = useCardSettlementsReadyForExportQuery({
    variables: {
      limit: 5000,
      statuses: [
        PaymentSettlementStatus.Deferred,
        PaymentSettlementStatus.Pending,
        PaymentSettlementStatus.Planned,
        PaymentSettlementStatus.ReadyForPmt,
        PaymentSettlementStatus.Paid,
      ],
      paidSettlements:
        CardIssuerSettlementPaidSettlementsFilter.IncludeOnlyRecentMostFromOpenStatements,
    },
  });

  const notReadyToExportCardSettlementsExportDocument =
    notReadyToExportCardSettlements?.cardSettlementsReadyForExport?.node?.map(
      mapCardSettlementToExportEntity
    ) ?? [];

  const {
    data: cardTransactionsReadyForExport,
    loading: loadingCardTransactions,
    refetch: refetchCardTransactionsReadyForExportQuery,
  } = useCardTransactionsReadyForExportQuery({});

  const allCreditCardTransactionsData =
    cardTransactionsReadyForExport?.cardTransactionsReadyForExport;

  const transactionsReadyForExport = Array<ExportEntity>().concat(
    ...(allCreditCardTransactionsData?.associatedCardTransactions?.map(
      documentToExportEntity
    ) ?? []),
    ...(allCreditCardTransactionsData?.unassociatedCardTransactions?.map(
      txnWithoutDocumentToExportEntity
    ) ?? [])
  );

  const {
    refetch: refetchProvisions,
    data: provisionsReadyForExportQueryResult,
    loading: loadingProvisions,
  } = useProvisionsReadyForExportQuery({
    variables: {
      filters: {
        types: [ProvisionEntityType.Document, ProvisionEntityType.Transaction],
      },
    },
  });

  const {
    refetch: refetchReversals,
    data: reversalsReadyForExportQueryResult,
    loading: loadingReversals,
  } = useReversalsReadyForExportQuery({
    variables: {
      filters: {
        types: [ProvisionEntityType.Document, ProvisionEntityType.Transaction],
      },
    },
  });

  const {
    data: paymentConditionsCount,
    loading: loadingCountPaymentConditions,
  } = usePaymentConditionsCountQuery({
    variables: { filter: { isArchived: false } },
    skip: !bdsExportPaymentConditionsAndContactsFF,
  });

  const { data: contactsCount, loading: loadingCountContacts } =
    useCountExportableContactsQuery({
      skip: !bdsExportPaymentConditionsAndContactsFF,
    });

  const {
    data: reimbursementItemsReadyForExportQueryResult,
    loading: isReimbursementItemsReadyForExportLoading,
    refetch: refetchReimbursementItemsReadyForExportQuery,
  } = useReimbursementItemsReadyForExport();

  const reimbursementItemsReadyForExport =
    reimbursementItemsReadyForExportQueryResult.map(
      mapReimbursementItemToExportEntity
    );

  const countExportableContacts = contactsCount?.countExportableContacts ?? 0;
  const countExportablePaymentConditions =
    paymentConditionsCount?.paymentConditionsCount ?? 0;

  const coreDataCount = {
    paymentConditions: {
      count: countExportablePaymentConditions,
      loading: loadingCountPaymentConditions,
    },
    contacts: {
      count: countExportableContacts,
      loading: loadingCountContacts,
    },
  };

  const allProvisions =
    provisionsReadyForExportQueryResult?.provisionsReadyForExport?.node?.map(
      toProvisionExportEntity({
        isDisabled: !exportProvisionsFF,
        exportableEntitiesInfo: [],
      })
    ) ?? [];

  const allReversals =
    reversalsReadyForExportQueryResult?.reversalsReadyForExport?.node?.map(
      toReversalExportEntity({
        isDisabled: !exportProvisionsFF,
        exportableEntitiesInfo: [],
      })
    ) ?? [];

  const entitiesNotReadyForExport = transactionsNotReadyForExport.concat(
    notReadyToExportCardSettlementsExportDocument
  );

  const { data: exportData, loading: loadingExportById } = useExportQuery({
    variables: { id: exportId ?? '' },
    skip: !exportId,
    fetchPolicy: 'cache-and-network',
  });

  const exportDetails = exportData?.export
    ? gqlExportToExportDetails(exportData.export)
    : null;

  let documents: ExportEntity[] = [];
  let transactions: ExportEntity[] = [];
  let settlements: ExportEntity[] = [];
  let provisions: ExportEntity[] = [];
  let reversals: ExportEntity[] = [];
  let reimbursementItems: ExportEntity[] = [];

  if (exportId && exportData?.export) {
    if (exportData.export.documents)
      documents = exportData.export.documents.map(
        toDocumentExportEntity({
          clientNumber: exportData.export.clientNumber,
          consultantNumber: exportData.export.consultantNumber,
        })
      );

    if (exportData.export.transactions)
      transactions = exportData.export.transactions.map(
        toTransactionExportEntity({
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          exportableEntitiesInfo: exportData.export.exportableEntityInfo!,
        })
      );

    if (exportData.export.cardSettlements)
      settlements = exportData.export.cardSettlements.map(
        toSettlementExportEntity({
          // biome-ignore lint/style/noNonNullAssertion: <explanation>
          exportableEntitiesInfo: exportData.export.exportableEntityInfo!,
        })
      );

    if (exportData.export.provisions)
      provisions = exportData.export.provisions.map(
        toProvisionExportEntity({
          isDisabled: false,
          exportableEntitiesInfo: exportData.export.exportableEntityInfo ?? [],
        })
      );

    if (exportData.export.reversals)
      reversals = exportData.export.reversals.map(
        toReversalExportEntity({
          isDisabled: false,
          exportableEntitiesInfo: exportData.export.exportableEntityInfo ?? [],
        })
      );

    if (exportData.export) {
      reimbursementItems = exportData.export.reimbursementItems.map(
        toReimbursementItemExportEntity({
          exportableEntitiesInfo: exportData.export?.exportableEntityInfo ?? [],
        })
      );
    }
  }

  const entitiesForReExport = {
    documents,
    transactions,
    settlements,
    provisions,
    reversals,
    reimbursementItems,
    all: [
      ...documents,
      ...transactions,
      ...settlements,
      ...provisions,
      ...reversals,
      ...reimbursementItems,
    ],
  };

  const entitiesForNewExport = {
    documents: documentExportEntities,
    transactions: transactionsReadyForExport,
    settlements: readyToExportCardSettlementsExportDocument,
    provisions: allProvisions,
    reversals: allReversals,
    reimbursementItems: reimbursementItemsReadyForExport,
    all: [
      ...documentExportEntities,
      ...transactionsReadyForExport,
      ...readyToExportCardSettlementsExportDocument,
      ...allProvisions,
      ...allReversals,
      ...reimbursementItemsReadyForExport,
    ],
  };

  const relevantEntities = exportId
    ? entitiesForReExport.all
    : entitiesForNewExport.all;

  const exportRowEntities = useGetExportEntitiesRows(
    relevantEntities,
    exportType,
    timeframe
  );

  const {
    documentEntities,
    transactionEntities,
    settlementEntities,
    provisionEntities,
    reversalEntities,
    reimbursementItemsEntities,
  } = mapExportRows(exportRowEntities);

  const notReadyExportRowEntities = useGetExportEntitiesRows(
    entitiesNotReadyForExport,
    exportType,
    timeframe
  );

  const {
    transactionEntities: notReadyTransactionsAsExportRows,
    settlementEntities: notReadySettlementEntitiesAsExportRows,
  } = mapExportRows(notReadyExportRowEntities);

  const isAlreadyExported = !!exportId || isReExport;

  const documentProvisions = provisionEntities.filter(
    ety =>
      ety.provisionEntityType === ProvisionEntityType.Document &&
      selectedProvisions.includes(ety.id)
  );

  const transactionProvisions = provisionEntities.filter(
    ety =>
      ety.provisionEntityType === ProvisionEntityType.Transaction &&
      selectedProvisions.includes(ety.id)
  );

  const documentReversals = reversalEntities.filter(
    ety => ety.provisionEntityType === ProvisionEntityType.Document
  );

  const transactionReversals = reversalEntities.filter(
    ety => ety.provisionEntityType === ProvisionEntityType.Transaction
  );

  const readyToExportEntitiesCountByType = {
    [DocumentType.Document]: isReExport
      ? documentEntities.length
      : paginatedDocumentsTableData.totalCount,
    [DocumentType.CardTransaction]: transactionEntities.length,
    [DocumentType.CardSettlement]: settlementEntities.length,
    [DocumentType.DocumentProvision]: documentProvisions.length,
    [DocumentType.TransactionProvision]: transactionProvisions.length,
    [DocumentType.DocumentReversal]: documentReversals.length,
    [DocumentType.TransactionReversal]: transactionReversals.length,
    [DocumentType.Contacts]: contactsCount?.countExportableContacts ?? 0,
    [DocumentType.PaymentConditions]:
      paymentConditionsCount?.paymentConditionsCount ?? 0,
    [DocumentType.ReimbursementItems]: reimbursementItemsEntities.length,
  };

  const exportableDocuments = exportRowEntities?.filter(
    entity => entity.type === ExportableEntityType.Document
  );

  const readyForExportDocuments =
    readyToExportEntitiesCountByType[DocumentType.Document];

  const exportAll =
    !isAlreadyExported && exportableDocuments.length < readyForExportDocuments;

  const unsupportedDocuments = relevantEntities.filter(document =>
    exportType === ExportType.DATEV_BDS
      ? !isSupportedOtherDocument(document)
      : !isSupportedInOutDocument(document)
  );

  const onlyUnsupportedDatevDocumentsAvailable =
    relevantEntities.length === unsupportedDocuments.length;

  const failedDocuments = parseFailedDocuments(relevantEntities);

  const failedReimbursementItems =
    parseFailedReimbursementItems(relevantEntities);

  const selectableProvisionIds = provisionEntities
    .filter(({ isDisabled }) => !isDisabled)
    .map(({ id }) => id);

  const counts = {
    provisionsCount,
    readyToExportEntitiesCount,
    notReadyToExportEntitiesCount,
    readyToExportSettlementsCount:
      readyToExportCardSettlementsExportDocument.length,
  };

  const amounts = {
    provisions: provisionEntities.reduce(toAmountSum, 0),
    reverals: reversalEntities.reduce(toAmountSum, 0),
  };

  const documentIds = relevantEntities
    .filter(({ type }) => type === ExportableEntityType.Document)
    .map(({ id }) => id);

  const isTransactionWithoutDocument = ({ type }: ExportEntity) =>
    type === ExportableEntityType.CardTransaction;

  const relevantTransactionsIds = relevantEntities
    .filter(isTransactionWithoutDocument)
    .map(({ id }) => id);

  const reimbursementItemsIds = relevantEntities
    .filter(({ type }) => type === ExportableEntityType.ReimbursementItem)
    .map(({ id }) => id);

  let transactionIds =
    !exportId || includeEntitiesWithoutDoc ? relevantTransactionsIds : [];

  const relevantCardSettlementsIds = relevantEntities
    .filter(({ type }) => type === ExportableEntityType.CardSettlement)
    .map(({ id }) => id);

  const cardSettlementIds =
    !exportId || includeEntitiesWithoutDoc ? relevantCardSettlementsIds : [];

  const shouldIncludeProvisions =
    exportProvisionsFF &&
    (exportType === ExportType.DATEV_BDS ||
      (exportProvisionsWithDATEVFormatFF &&
        exportType === ExportType.DATEV_CSV));

  const provisionIds = shouldIncludeProvisions ? selectedProvisions : [];

  const reversalIds = shouldIncludeProvisions
    ? relevantEntities
        .filter(({ type }) => type === ExportableEntityType.ProvisionReversal)
        .map(({ id }) => id)
    : [];

  const refetchAll = useMemo(() => {
    return [
      paginatedDocumentsTableData.refetch,
      refetchCardTransactionsReadyForExportQuery,
      refetchCardTransactionsNotReadyForExportQuery,
      refetchCardSettlementsReadyForExportQuery,
      refetchNotReadyToExportCardSettlementsQuery,
      refetchReimbursementItemsReadyForExportQuery,
      refetchProvisions,
      refetchReversals,
      refetchCounterQuery,
    ];
  }, [
    paginatedDocumentsTableData.refetch,
    refetchCardTransactionsReadyForExportQuery,
    refetchCardTransactionsNotReadyForExportQuery,
    refetchCardSettlementsReadyForExportQuery,
    refetchNotReadyToExportCardSettlementsQuery,
    refetchReimbursementItemsReadyForExportQuery,
    refetchProvisions,
    refetchReversals,
    refetchCounterQuery,
  ]);

  return {
    paginatedDocumentsTableData,
    exportEntities: {
      ready: { all: relevantEntities },
      notReady: entitiesNotReadyForExport,
    },
    exportRowEntities: {
      ready: {
        all: exportRowEntities,
        documentEntities,
        transactionEntities,
        settlementEntities,
        provisionEntities,
        reversalEntities,
        reimbursementItemsEntities,
      },
      notReady: {
        all: [
          ...notReadyTransactionsAsExportRows,
          ...notReadySettlementEntitiesAsExportRows,
        ],
        notReadyTransactionsAsExportRows,
        notReadySettlementEntitiesAsExportRows,
      },
    },
    exportEntitiesIds: {
      documentIds,
      transactionIds,
      cardSettlementIds,
      provisionIds,
      reversalIds,
      reimbursementItemsIds,
    },
    entitiesForReExport,
    isLoading: {
      any:
        loadingCardSettlementsNotReady ||
        loadingCardSettlementsReady ||
        loadingCardTransactions ||
        loadingCardTransactionsNotReady ||
        loadingCountContacts ||
        loadingCountPaymentConditions ||
        loadingCountsExportableEntities ||
        loadingExportById ||
        loadingExportTableData ||
        loadingProvisions ||
        isReimbursementItemsReadyForExportLoading ||
        loadingReversals,
      isReimbursementItemsReadyForExportLoading,
      loadingCardSettlementsNotReady,
      loadingCardSettlementsReady,
      loadingCardTransactions,
      loadingCardTransactionsNotReady,
      loadingCountContacts,
      loadingCountPaymentConditions,
      loadingCountsExportableEntities,
      loadingExportById,
      loadingExportTableData,
      loadingProvisions,
      loadingReversals,
    },
    counts,
    isReExport,
    amounts,
    coreDataCount,
    readyToExportEntitiesCountByType,
    onlyUnsupportedDatevDocumentsAvailable,
    exportAll,
    exportDetails,
    exportData,
    failedDocuments,
    failedReimbursementItems,
    selectableProvisionIds,
    refetch: {
      all: refetchAll,
    },
  };
};
