import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.css';

export const Header = ({
  totalCount,
  loading,
}: { totalCount: number; loading: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <div className={styles.header}>
      <h2 className={styles.title}>
        {t('insights.missingInvoicesWidget.title')}
      </h2>
      {!loading && <p className={styles.number}>{totalCount}</p>}
    </div>
  );
};
