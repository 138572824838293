import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';
import { goToPage } from '../utils';

type Props = {
  disabled?: boolean;
};

export const PrevPageButton = ({ disabled }: Props) => {
  const [t] = useTranslation();
  const { usePdfView } = usePdfViewProvider();
  const currentPage = usePdfView('page');
  const viewerId = usePdfView('viewerId');

  return (
    <Button
      data-cy="page-control-prev"
      disabled={disabled || currentPage <= 1}
      icon="arrowUpCircle"
      label={t('document.prevPagePdf')}
      onClick={() => goToPage(viewerId, currentPage - 1)}
      variant="tertiary"
      size="small"
    />
  );
};
