import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import styles from './LimitRequestContentTile.module.css';
import { usePath } from 'hooks/usePath';
import { NavLink } from 'react-router-dom-v5-compat';
import { Tag } from '@candisio/design-system';
import { CardInfo } from '../shared/CardInfo/CardInfo';
import { Category } from '../shared/Category/Category';
import { categoryTranslationKeys } from 'views/CreditCards/CardManagerView/components/CreditCardsTable/Cells/CategoryCell';
import { CardIssuerCardEdgeDataFragment } from 'generated-types/graphql.types';
import { RequestedLimitChange } from './RequestedLimitChange/RequestedLimitChange';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const LimitRequestContentTile = ({
  request,
}: {
  request: CardIssuerCardEdgeDataFragment['node'];
}) => {
  const {
    cardholder,
    category,
    id,
    limit,
    refNum,
    pendingRequest,
    transactionLimit,
  } = request;

  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const href = usePath({
    pathname: '/credit_cards/dashboard/cards/:creditCardId',
    params: {
      creditCardId: id,
    },
  });

  const name = `${cardholder.firstName} ${cardholder.lastName}`;

  return (
    <SwimlaneTile asChild variant="white" data-cy="limitRequestTile">
      <NavLink to={href} className={styles.column}>
        <Tag color="yellow" variant="secondary">
          {t('insights.swimlanes.limitRequests.newLimit')}
        </Tag>
        <div className={styles.details}>
          <CardInfo name={name} refNum={refNum} />
          {category && (
            <Category category={categoryTranslationKeys[category]} />
          )}
          <RequestedLimitChange
            limit={limit}
            transactionLimit={transactionLimit}
            pendingRequest={pendingRequest}
          />
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};
