import { PowerSearchFieldContainer } from 'components/PowerSearchField/PowerSearchFieldContainer';
import { PowerSearchToolbar } from 'components/PowerSearchToolbar/PowerSearchToolbar';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import styles from 'components/PowerSearchToolbar/power-search-toolbar.module.css';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';

interface EcmContractsToolbarProps {
  searchQuery: string;
  onSearchChange: (searchQuery: string) => void;
  configurationsTable: Configuration[];
  isLoadingConfigurations?: boolean;
  onUpdateConfigurations: (values: Configuration[]) => void;
  onResetTableConfigurations?: () => void;
}

export const EcmContractsToolbar = ({
  searchQuery,
  onSearchChange,
  configurationsTable,
  isLoadingConfigurations,
  onUpdateConfigurations,
  onResetTableConfigurations,
}: EcmContractsToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  return (
    <PowerSearchToolbar>
      <PowerSearchToolbar.AreaSearch>
        <PowerSearchFieldContainer
          className={styles.powerSearchToolbarSearchField}
          clearTooltip={t('search.clearTooltip')}
          placeholder={t('search.placeholder')}
          initialValue={searchQuery}
          onQueryChange={onSearchChange}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
      </PowerSearchToolbar.AreaSearch>
      <PowerSearchToolbar.AreaTools>
        <ConfigurationsMenu
          configurationItems={configurationsTable}
          isLoading={isLoadingConfigurations}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetConfigurations={onResetTableConfigurations}
        />
      </PowerSearchToolbar.AreaTools>
      <PowerSearchToolbar.AreaActiveFilters
        hasFilters={false}
      ></PowerSearchToolbar.AreaActiveFilters>
    </PowerSearchToolbar>
  );
};
