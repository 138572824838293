import { LinkProps, Text } from '@candisio/design-system';
import {
  IndicatorStatus,
  PulseIndicator,
} from 'components/PulseIndicator/PulseIndicator';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { Children, ReactNode, forwardRef } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import { NavLink as ReactRouterLink } from 'react-router-dom-v5-compat';
import styles from './Navlink.module.css';
import clsx from 'clsx';

export interface NavLinkProps
  extends Pick<
      RouterNavLinkProps,
      'exact' | 'isActive' | 'location' | 'replace' | 'strict' | 'to'
    >,
    Omit<LinkProps, 'href' | 'ref'> {
  to: string;
  requiresUpgrade?: boolean;
}

interface NavLinkLabelProps {
  children: ReactNode;
}

interface NavLinkIndicatorProps {
  status?: IndicatorStatus;
  source?: string;
}

const Divider = () => <hr className={styles.divider} />;

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, to, requiresUpgrade, className, ...restProps }, ref) => {
    return (
      <ReactRouterLink
        ref={ref}
        to={to}
        data-highlight={requiresUpgrade}
        className={clsx(styles.link, className)}
        {...restProps}
      >
        {children}
        {requiresUpgrade && <AddOnIcon />}
      </ReactRouterLink>
    );
  }
);

export const NavLinkLabel = ({ children }: NavLinkLabelProps) => (
  <Text fontSize="small">{children}</Text>
);

export const NavLinkIndicator = ({
  status = 'default',
  source,
}: NavLinkIndicatorProps) => <PulseIndicator status={status} source={source} />;

export const NavLinkGroup = ({
  children,
  showDivider = true,
}: {
  children: ReactNode;
  showDivider?: boolean;
}) => {
  const validChildren = Children.toArray(children).filter(Boolean);
  const hasValidChildren = Children.count(validChildren) > 0;

  return (
    <>
      {hasValidChildren && children}
      {showDivider && hasValidChildren && <Divider />}
    </>
  );
};
