import { Button } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApprovalFieldData,
  useReimbursementFormsContext,
} from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';

const TRANSLATION = {
  ERROR:
    'reimbursementView.rightSection.reviewfooterAction.requestApproveToast.error',
  SUCCESS:
    'reimbursementView.rightSection.reviewfooterAction.requestApproveToast.success',
  REQUEST: 'reimbursementView.rightSection.reviewfooterAction.request',
  REQUEST_AGAIN:
    'reimbursementView.rightSection.reviewfooterAction.requestAgain',
};
interface RequestApprovalActionProps {
  getExpenses: UseFormGetValues<ReimbursementItemsFormOutput>;
  reimbursement?: Reimbursement;
  isSubmitForApprovalPending: boolean;
  isFastApprovePending: boolean;
  onCycleReimbursments: () => void;
  onSubmitForApproval: (
    values: ApprovalFieldData
  ) => Promise<'error' | 'success'>;
}

export const RequestApprovalAction = ({
  getExpenses,
  reimbursement,
  onCycleReimbursments,
  isFastApprovePending,
  isSubmitForApprovalPending,
  onSubmitForApproval,
}: RequestApprovalActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { error } = useToastMessage();
  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);

  const {
    triggerAllValidations,
    setApprovalMode,
    lastModifiedReimbursementItem,
    setHasReimbursementErrors,
    approvalData,
    isReimbursementUpdatePending,
  } = useReimbursementFormsContext();

  const { triggerBookingsValidation } = useReimbursementSplitBookingHelpers();

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });

  const updateExpenseItems = async () => {
    if (!lastModifiedReimbursementItem) return;

    const expense =
      getExpenses('reimbursementItems')[lastModifiedReimbursementItem];
    const updateFn = {
      general: updateGeneralExpense,
      hospitality: updateHospitalityExpense,
      perDiem: null,
      mileage: null,
    }[expense?.reimbursementItemType];

    if (updateFn) await updateFn(expense);
  };

  const handleClick = async () => {
    setApprovalMode('requestApproval');
    updateExpenseItems();

    const submitResponse = await onSubmitForApproval(approvalData);

    if (submitResponse === 'error') {
      triggerAllValidations();
      triggerBookingsValidation();

      setHasReimbursementErrors(true);

      error(t(TRANSLATION.ERROR));

      return;
    }

    setHasReimbursementErrors(false);
    successMessageWithDeepLink({
      key: TRANSLATION.SUCCESS,
    });
    onCycleReimbursments();
  };

  const isLoading =
    isUpdateHospitalityExpensePending ||
    isUpdateGeneralExpensePending ||
    isSubmitForApprovalPending;

  const isCaseRejected =
    reimbursement?.status === ReimbursementCaseStatus.Rejected;

  return (
    <Button
      color="blue"
      onClick={handleClick}
      loading={isLoading}
      disabled={
        isLoading || isReimbursementUpdatePending || isFastApprovePending
      }
    >
      {t(isCaseRejected ? TRANSLATION.REQUEST_AGAIN : TRANSLATION.REQUEST)}
    </Button>
  );
};
