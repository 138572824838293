import clsx from 'clsx';
import { ToggleButton } from 'react-aria-components';
import styles from '../DaysStep.module.css';
import { ReactNode } from 'react';

interface ButtonItemProps {
  displayValue: ReactNode;
  id: string;
  isDisabled?: boolean;
}
export const ButtonItem = ({
  displayValue,
  id,
  isDisabled = false,
}: ButtonItemProps) => {
  return (
    <ToggleButton
      id={id}
      isDisabled={isDisabled}
      className={clsx(
        styles['toggle-button'],
        styles[`toggle-button--regular`],
        {
          [styles['disabled']]: isDisabled,
        }
      )}
    >
      {displayValue}
    </ToggleButton>
  );
};
