import {
  DocumentCurrency,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import styles from './ReimbursementItem.module.css';
import clsx from 'clsx';
import { Icon, TruncatedText } from '@candisio/design-system/next';

interface ReimbursementItemProps {
  reimbursementItem: ReimbursementItemForList;
  onClick: () => void;
}

export const mappedTypesToItems: Record<
  ReimbursementItemType,
  {
    placeholder: string;
    icon: () => ReactNode;
  }
> = {
  GENERAL_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: () => (
      <div className={clsx(styles.icon, styles.green)}>
        <Icon icon="receipt" className={styles.iconComponent} />
      </div>
    ),
  },
  HOSPITALITY_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: () => (
      <div className={clsx(styles.icon, styles.pink)}>
        <Icon icon="hospitality" className={styles.iconComponent} />
      </div>
    ),
  },
  PER_DIEM: {
    placeholder: 'reimbursementView.middleSection.form.perDiem.title',
    icon: () => (
      <div className={clsx(styles.icon, styles.blue)}>
        <Icon icon="calendarDays" className={styles.iconComponent} />
      </div>
    ),
  },
  MILEAGE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.milage',
    icon: () => (
      <div className={clsx(styles.icon, styles.yellow)}>
        <Icon icon="travel" className={styles.iconComponent} />
      </div>
    ),
  },
};

export const ReimbursementItem = ({
  reimbursementItem,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { visibleItemId } = useReimbursementFormsContext();
  const { documentMoneyFormatter } = useMoneyFormatter();
  const { id, type, reason, totalAmount, status } = reimbursementItem;
  const { icon: Icon, placeholder } = mappedTypesToItems[type];
  const text = reason ? reason : t(placeholder);

  const formattedAmount = documentMoneyFormatter({
    precision: totalAmount?.precision ?? 2,
    amount: totalAmount?.amount ?? 0,
    currency: totalAmount?.currency ?? DocumentCurrency.Eur,
  });

  const isItemVisible = visibleItemId === id;

  return (
    <div
      className={styles.item}
      data-highlighted={isItemVisible}
      onClick={onClick}
    >
      <div className="grid grid-cols-[auto_auto] items-center justify-items-start gap-[0.625rem]">
        <div className="flex items-center gap-1">
          <Icon />
          <TruncatedText className="text-basic">{text}</TruncatedText>
        </div>
        <TruncatedText
          className={clsx('justify-self-end text-basic', {
            'line-through': status === ReimbursementItemStatus.Excluded,
          })}
        >
          {formattedAmount}
        </TruncatedText>
      </div>
    </div>
  );
};
