import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import styles from './CopyButton.module.css';
import { Icon } from '@candisio/design-system/next';
import { useToastMessage } from 'components/Toast/useToastMessage';

export const CopyButton = ({
  emailList,
}: { emailList: (string | undefined)[] }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success } = useToastMessage();

  const copyToClipboard = async () => {
    const emailsToCopy = emailList.filter(Boolean).join('\n');

    await navigator.clipboard.writeText(emailsToCopy);
    success(t('common:common.copiedToClipboard'));
  };

  return (
    <button
      className={styles.copyButton}
      onClick={copyToClipboard}
      type="button"
    >
      {t('insights.missingInvoicesWidget.copyButton')}
      <Icon icon="copy" />
    </button>
  );
};
