import { Heading } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { FileUpload } from './FileUpload';
import { UploadHistoryList } from './UploadHistoryList';

export const MenuDocumentUploadPopoverContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { setLayoutValue } = useAppLayout();

  return (
    <>
      <header className="flex items-center justify-between gap-1">
        <Heading as="h3">{t('documentUpload.header')}</Heading>
        <IntercomLink
          iconPosition="left"
          id="document-upload-feedback"
          onClick={() => {
            setLayoutValue('docUploadPopover', { open: false });
          }}
          translation={t('documentUpload.feedbackButton')}
          icon="megaphone"
        />
      </header>
      <FileUpload />
      <span className="text-small text-gray-600">
        {t('documentUpload.info')}
      </span>
      <UploadHistoryList />
    </>
  );
};
