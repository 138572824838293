import { PropsWithChildren } from 'react';
import styles from './power-search-toolbar.module.css';
import clsx from 'clsx';

interface ToolbarAreaToolsProps extends PropsWithChildren {
  className?: string;
}

export const ToolbarAreaTools = ({
  className,
  ...props
}: ToolbarAreaToolsProps) => {
  return (
    <div
      className={clsx(styles.powerSearchToolbarTools, className)}
      {...props}
    />
  );
};
