import {
  AddFileUploadMutationFn,
  DocumentFile,
  DocumentFileUploadUrlMutationFn,
  FileState,
  UpdateFileUploadMutationFn,
  UploadFileItem,
} from 'generated-types/graphql.types';
import { uploadFile } from 'utils/upload';
import { v4 as uuidV4 } from 'uuid';
import { isSupportedFile } from '../../utils';

export const MAX_FILE_SIZE_MB = 20;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

type FileUploadDependencies = {
  onUploaded: (fileId: string) => Promise<{ documentId?: string }>;
  documentFileUploadUrl: DocumentFileUploadUrlMutationFn;
} & (
  | {
      addFile: AddFileUploadMutationFn;
      updateFile: UpdateFileUploadMutationFn;
    }
  | {
      addFile?: undefined;
      updateFile?: undefined;
    }
);

export const fileUploadUtil = async (
  file: File,
  isXmlDocumentUploadAllowed: boolean,
  {
    onUploaded,
    addFile,
    documentFileUploadUrl,
    updateFile,
  }: FileUploadDependencies
): Promise<{
  file?: Pick<DocumentFile, 'id' | 'url' | 'name'> | null;
  errorMessage?: string;
}> => {
  const fileMetadata: UploadFileItem = {
    id: uuidV4(),
    createdAt: new Date().toISOString(),
    fileName: file.name,
    state: FileState.Uploading,
    documentId: null,
    fileId: null,
    errorDetails: null,
    isDeleted: false,
  };

  // save local upload item to track status on the view
  await addFile?.({
    variables: { file: fileMetadata },
  });

  if (!isSupportedFile(file, isXmlDocumentUploadAllowed)) {
    const message = 'uploads.errors.wrongFileType';
    await updateFile?.({
      variables: {
        file: {
          ...fileMetadata,
          state: FileState.Error,
          errorDetails: message,
          fileId: uuidV4(),
        },
      },
    });

    return { errorMessage: message };
  }

  let fileId;

  try {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('uploads.errors.fileSize');
    }

    // request url to upload file to S3
    const { data } = await documentFileUploadUrl({
      variables: {
        options: {
          contentType: file.type,
          fileName: file.name,
          size: file.size,
        },
      },
    });

    if (!data?.documentFileUploadUrl?.id) {
      throw new Error('uploads.errors.uploadFailed');
    }

    await updateFile?.({
      variables: {
        file: {
          ...fileMetadata,
          fileId: data.documentFileUploadUrl.id,
        },
      },
    });

    await uploadFile(
      file,
      data.documentFileUploadUrl.postOptions || [],
      data.documentFileUploadUrl.url
    );

    fileId = data.documentFileUploadUrl.id;

    const { documentId } = await onUploaded(fileId);

    await updateFile?.({
      variables: {
        file: {
          ...fileMetadata,
          state: FileState.Uploaded,
          documentId,
          fileId,
          errorDetails: null,
        },
      },
    });

    return {
      file: data.documentFileUploadUrl.file,
    };
  } catch (e) {
    const message = (e as Error).message ?? 'uploads.errors.baseError';

    await updateFile?.({
      variables: {
        file: {
          ...fileMetadata,
          state: FileState.Error,
          errorDetails: message,
          fileId: fileId ?? uuidV4(),
        },
      },
    });

    return {
      errorMessage: message,
    };
  }
};
