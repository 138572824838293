import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { useCallback, useState } from 'react';
import { UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useDeleteExpense } from 'views/Reimbursement/hooks/useDeleteExpense';
import { useExtractGeneralInvoiceData } from 'views/Reimbursement/hooks/useExtractGeneralInvoiceData';
import { useExtractHospitalityInvoiceData } from 'views/Reimbursement/hooks/useExtractHospitalityInvoiceData';
import { useReimbursementUpdatePending } from 'views/Reimbursement/hooks/useReimbursementUpdatePending';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { FieldNames } from './generateGeneralExpenseFormFieldNames';
import { HospitalityFieldNames } from './generateHospitalityFormFieldNames';
import { useInvoiceDataFieldsUpdates } from './invoiceDataExtractionHelpers';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';
import { useUpdatePerDiemItem } from 'views/Reimbursement/hooks/useUpdatePerDiem';
import { useAddPerDiemSegment } from 'views/Reimbursement/hooks/useAddPerDiemSegment';
import { PerDiemStep } from 'generated-types/graphql.types';
import { mapDay } from 'views/Reimbursement/utils/mapDay';
import { useEditPerDiemSegment } from 'views/Reimbursement/hooks/useEditPerDiemSegment';

export type BooleanMap = { [key: string]: boolean };
interface UseReimbursementItemsFormActionsParams {
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
  createExpenseInvoice: (file: File) => Promise<ExpenseInvoice | undefined>;
  onRemoveForm: UseFieldArrayRemove;
}

export const useReimbursementItemsFormActions = ({
  createExpenseInvoice,
  onRemoveForm,
  formMethods,
}: UseReimbursementItemsFormActionsParams) => {
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<BooleanMap>({});
  const [isExtractingInvoiceData, setIsExtractingInvoiceData] =
    useState<BooleanMap>({});
  const [isDeletingForm, setIsDeletingForm] = useState<BooleanMap>({});
  const { setValue, getValues, watch, trigger } = formMethods;

  const { updateLastModifiedReimbursementItem } =
    useReimbursementFormsContext();

  const { updateHospitalityExpense, isUpdateHospitalityExpensePending } =
    useUpdateHospitalityExpense();
  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense();

  const { extractHospitalityInvoiceData, isHospitalityInvoiceDataPending } =
    useExtractHospitalityInvoiceData();

  const { extractGeneralInvoiceData, isGeneralInvoiceDataPending } =
    useExtractGeneralInvoiceData();

  const {
    updatePerDiem,
    isUpdatePerDiemPending,
    updatePerDiemDays,
    updatePerDiemNotDebounced,
  } = useUpdatePerDiemItem();

  const { addPerDiemSegment, isAddSegmentPending } = useAddPerDiemSegment();
  const { editPerDiemSegment, isEditSegmentPending } = useEditPerDiemSegment();

  const { deleteExpense } = useDeleteExpense();
  const { updateSearchParam } = useMutateSearchParams();

  const {
    updateGeneralExpenseInvoiceDataFields,
    updateHospitalityExpenseInvoiceDataFields,
  } = useInvoiceDataFieldsUpdates({
    setValue,
  });

  const handleUpdateHospitality = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`reimbursementItems.${index}`);
      updateLastModifiedReimbursementItem(index);
      await updateHospitalityExpense(formValues);
    },
    [getValues, updateHospitalityExpense, updateLastModifiedReimbursementItem]
  );

  const handleUpdateGeneralExpense = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`reimbursementItems.${index}`);

      updateLastModifiedReimbursementItem(index);
      await updateGeneralExpense(formValues);
    },
    [getValues, updateGeneralExpense, updateLastModifiedReimbursementItem]
  );

  const handleDeleteForm = useCallback(
    (index: number, expenseId: string) => async () => {
      setIsDeletingForm(prev => ({ ...prev, [index]: true }));

      const deleted = await deleteExpense(expenseId);
      setIsDeletingForm(prev => ({ ...prev, [index]: false }));

      if (!deleted) return;
      onRemoveForm(index);
      const reimbursementItems = watch('reimbursementItems');
      const expensesLength = reimbursementItems.length;

      if (!expensesLength) {
        updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, '');
      }
    },
    [deleteExpense, onRemoveForm, updateSearchParam, watch]
  );

  const handleInvoiceChange = useCallback(
    async (file: File, index: number) => {
      setIsCreatingInvoice(prev => ({ ...prev, [index]: true }));

      const invoice = await createExpenseInvoice(file);

      if (!invoice) {
        setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));

        return;
      }

      setValue(`reimbursementItems.${index}.files`, [invoice]);

      setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));
    },

    [createExpenseInvoice, setValue]
  );

  const handleUpdateTotalAmount = useCallback(
    (index: number) => {
      const grossAmount = watch(`reimbursementItems.${index}.receiptAmount`);
      const tipAmount = watch(`reimbursementItems.${index}.tipAmount`);

      if (isNil(grossAmount) && isNil(tipAmount)) {
        setValue(`reimbursementItems.${index}.totalAmount`, null, {
          shouldValidate: true,
        });

        return;
      }

      const totalAmount = (grossAmount ?? 0) + (tipAmount ?? 0);

      setValue(`reimbursementItems.${index}.totalAmount`, totalAmount, {
        shouldValidate: true,
      });
    },
    [setValue, watch]
  );

  const extractAndProcessHospitalityInvoice = useCallback(
    async (index: number, fields: HospitalityFieldNames) => {
      setIsExtractingInvoiceData(prev => ({ ...prev, [index]: true }));

      const formValuesBeforeExtraction = getValues(
        `reimbursementItems.${index}`
      );
      updateLastModifiedReimbursementItem(index);

      // Extract data
      await extractHospitalityInvoiceData(formValuesBeforeExtraction, data => {
        setValue(fields.isExtractedDataAccepted, data.isExtractionAccepted);
        setValue(fields.extractedData, data.extractedData);
        setIsExtractingInvoiceData(prev => ({ ...prev, [index]: false }));
      });

      const formValuesAfterExtraction = getValues(
        `reimbursementItems.${index}`
      );

      // Process extracted data
      if (formValuesAfterExtraction.reimbursementItemType === 'hospitality') {
        updateHospitalityExpenseInvoiceDataFields(
          fields,
          formValuesAfterExtraction.extractedData
        );
        handleUpdateTotalAmount(index);
      }
    },
    [
      getValues,
      setValue,
      extractHospitalityInvoiceData,
      updateHospitalityExpenseInvoiceDataFields,
      handleUpdateTotalAmount,
      updateLastModifiedReimbursementItem,
    ]
  );

  const extractAndProcessGeneralInvoice = useCallback(
    async (index: number, fields: FieldNames) => {
      setIsExtractingInvoiceData(prev => ({ ...prev, [index]: true }));

      const formValuesBeforeExtraction = getValues(
        `reimbursementItems.${index}`
      );
      updateLastModifiedReimbursementItem(index);

      // Extract data
      await extractGeneralInvoiceData(formValuesBeforeExtraction, data => {
        setValue(fields.isExtractedDataAccepted, data.isExtractionAccepted);
        setValue(fields.extractedData, data.extractedData);
        setIsExtractingInvoiceData(prev => ({ ...prev, [index]: false }));
      });

      const formValuesAfterExtraction = getValues(
        `reimbursementItems.${index}`
      );

      // Process extracted data
      if (formValuesAfterExtraction.reimbursementItemType === 'general')
        updateGeneralExpenseInvoiceDataFields(
          fields,
          formValuesAfterExtraction.extractedData
        );
    },
    [
      getValues,
      setValue,
      extractGeneralInvoiceData,
      updateGeneralExpenseInvoiceDataFields,
      updateLastModifiedReimbursementItem,
    ]
  );

  const handleUpdatePerDiem = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`reimbursementItems.${index}`);

      updateLastModifiedReimbursementItem(index);
      await updatePerDiem(formValues);
    },
    [getValues, updateLastModifiedReimbursementItem, updatePerDiem]
  );

  const handleUpdatePerDiemStep = useCallback(
    async (index: number) => {
      const isAllSegmentsValid = await trigger(
        `reimbursementItems.${index}.segments`
      );

      if (!isAllSegmentsValid) {
        return;
      }
      const formValues = getValues(`reimbursementItems.${index}`);

      updateLastModifiedReimbursementItem(index);

      const response = await updatePerDiemNotDebounced(
        formValues,
        PerDiemStep.Days
      );

      if (!response) {
        return;
      }

      setValue(`reimbursementItems.${index}.currentStep`, PerDiemStep.Days);

      const mappedDays = response.days.map(mapDay);

      setValue(`reimbursementItems.${index}.days`, mappedDays);
    },
    [
      getValues,
      updateLastModifiedReimbursementItem,
      updatePerDiemNotDebounced,
      setValue,
      trigger,
    ]
  );

  const handleUpdatePerDiemDays = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`reimbursementItems.${index}`);

      updateLastModifiedReimbursementItem(index);
      await updatePerDiemDays(formValues);
    },
    [getValues, updateLastModifiedReimbursementItem, updatePerDiemDays]
  );

  const handleAddPerDiemSegment = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`reimbursementItems.${index}`);

      await addPerDiemSegment(formValues);
    },
    [getValues, addPerDiemSegment]
  );

  const handleEditPerDiemSegment = useCallback(
    async (index: number) => {
      const formValues = getValues(`reimbursementItems.${index}`);

      setValue(`reimbursementItems.${index}.currentStep`, PerDiemStep.Segments);
      await editPerDiemSegment(formValues);
    },
    [getValues, editPerDiemSegment, setValue]
  );

  const isUpdatePending =
    isGeneralInvoiceDataPending ||
    isHospitalityInvoiceDataPending ||
    isUpdateGeneralExpensePending ||
    isUpdateHospitalityExpensePending ||
    isUpdatePerDiemPending ||
    isAddSegmentPending ||
    isEditSegmentPending;

  useReimbursementUpdatePending(isUpdatePending);

  return {
    isDeletingForm,
    isCreatingInvoice,
    isExtractingInvoiceData,
    handleUpdateHospitality,
    handleDeleteForm,
    handleInvoiceChange,
    handleUpdateGeneralExpense,
    handleUpdateTotalAmount,
    extractAndProcessHospitalityInvoice,
    extractAndProcessGeneralInvoice,
    handleUpdatePerDiem,
    handleAddPerDiemSegment,
    handleUpdatePerDiemStep,
    handleUpdatePerDiemDays,
    isUpdatePerDiemPending,
    isAddSegmentPending,
    isEditSegmentPending,
    handleEditPerDiemSegment,
  };
};
