import { usePerDiemItemCalculation } from 'views/Reimbursement/hooks/usePerDiemItemCalculation';
import { usePerDiemDayCalculationColumns } from './usePerDiemDayCalculationColumns';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { PerDiemCalculationTable } from './PerDiemCalculationTable';
import { Modal, Skeleton } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface PerDiemCalculationContainerProps {
  isVisible: boolean;
  onClose: () => void;
  perDiemId: string;
}

export const PerDiemCalculationContainer = ({
  isVisible,
  onClose,
  perDiemId,
}: PerDiemCalculationContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const columns = usePerDiemDayCalculationColumns();
  const {
    perDiemDayCalculationData: data,
    isLoading,
    totalReimbursableRate,
  } = usePerDiemItemCalculation(perDiemId);

  const perDiemCalculationTable = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Modal
      width="max-content"
      minHeight="space256"
      padding="space16 space24 space24"
      title={t(
        'reimbursementView.middleSection.form.perDiem.calculationTable.title'
      )}
      closeLabel={t(
        'reimbursementView.middleSection.form.perDiem.calculationTable.closeTableCta'
      )}
      isOpen={isVisible}
      onClose={onClose}
    >
      {isLoading ? (
        <Skeleton width="56vw" height="15rem" />
      ) : (
        <PerDiemCalculationTable
          tableInstance={perDiemCalculationTable}
          totalReimbursableRate={totalReimbursableRate}
        />
      )}
    </Modal>
  );
};
