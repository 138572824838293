import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { ExpenseDetailsCardWithAction } from './ExpenseDetailsCardWithAction';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { TravelPromotion } from './TravelPromotion/TravelPromotion';
import { PerDiemDetailsCard } from './PerDiemDetailsCard';
import { MilageDetailsCard } from './MilageDetailsCard';

interface ReimbursementItemSummaryWithActionContainerProps {
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
  onCreateMileage: () => Promise<void>;
  isCreateMileagePending?: boolean;
  isPromotion?: boolean;
  creatingPerDiem: boolean;
  onCreatePerDiem: () => void;
  reimbursementItems?: ReimbursementItemForList[];
}

export const ReimbursementItemSummaryWithActionContainer = ({
  creatingExpense,
  creatingPerDiem,
  onCreatePerDiem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  onCreateMileage,
  isCreateMileagePending,
  isPromotion,
  reimbursementItems,
}: ReimbursementItemSummaryWithActionContainerProps) => {
  const { canUsePerDiemReimbursement, canUseMilageReimbursement } =
    useReimbursement();

  const isTravelSectionVisible =
    canUseMilageReimbursement || canUsePerDiemReimbursement;

  return (
    <div className="grid gap-4">
      <ExpenseDetailsCardWithAction
        creatingExpense={creatingExpense}
        onGeneralExpenseClick={onGeneralExpenseClick}
        onHospitalityExpenseClick={onHospitalityExpenseClick}
        reimbursementItems={reimbursementItems}
        isPromotion={isPromotion}
      />

      {isTravelSectionVisible ? (
        <>
          {canUsePerDiemReimbursement && (
            <PerDiemDetailsCard
              creatingPerDiem={creatingPerDiem}
              onCreatePerDiem={onCreatePerDiem}
              reimbursementItems={reimbursementItems}
            />
          )}
          {canUseMilageReimbursement && (
            <MilageDetailsCard
              reimbursementItems={reimbursementItems}
              createMileage={onCreateMileage}
              isCtaLoading={isCreateMileagePending}
            />
          )}
        </>
      ) : (
        <TravelPromotion />
      )}
    </div>
  );
};
