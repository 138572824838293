import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { availableEcmDocumentFilters } from 'components/EcmDocumentsTable/constants';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  queryParameter,
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import {
  EcmFilterInput,
  EcmSortInput,
  EcmSortOrder,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';

import { AppRouteParams, Routes } from 'models';
import { EcmDocumentsPaginationParams } from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { ComponentProps, ReactNode, useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom-v5-compat';
import { useGetEcmContractsTableConfigs } from 'views/Archive/EcmContracts/hooks/useGetEcmContractsTableConfigs';
import { generateInboxTableRowPath } from 'views/utils/generateInboxTableRowPath';
import { InboxSensitiveDocumentsEcmEmptyState } from '../EcmSensitiveDocuments/EcmInboxSensitiveDocumentsEmptyState';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { InboxContractsHeader } from './InboxContractsHeader';
import { useInboxContractsData } from './useInboxContractsData';
import {
  extractDates,
  toFormattedFilters,
} from 'views/Archive/EcmContracts/EcmContracts';
import { useDateConverter } from 'hooks/useDateConverter';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { EcmDocumentsTableV2Adapter } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/EcmDocumentsTableV2Adapter';
import { DocumentsTableLink } from 'components/DocumentsTable/next/components/DocumentsTableLink';
import { usePath } from 'hooks/usePath';
import { defaultSortByFields } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/EcmDocumentsTableV2';
import { InboxContractsToolbar } from './InboxContractsToolbar';

const sortByFields: ComponentProps<
  typeof EcmDocumentsTableV2Adapter
>['sortByFields'] = [
  ...defaultSortByFields,
  'contact',
  'documentName',
  'documentNumber',
];

export const InboxContracts = () => {
  const [wipTableRefactorForEcmAllDocumentsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.wipTableRefactorForEcmAllDocuments,
  ]);
  const [searchParams, setSearchParam] = useSearchParams();
  const { organizationSlug } = useParams<AppRouteParams>();
  const { filters, onFilter, sortBy, onSort } =
    useUrlBasedSortAndFilter<EcmDocumentsTableData>({
      availableFilters: availableEcmDocumentFilters,
    });
  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isResetPending,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useGetEcmContractsTableConfigs({ filters, sortBy });
  const { dateStringToIsoDateFilterFormat } = useDateConverter();

  const isTableFiltered = filters.length > 0;

  const formattedFilters: EcmFilterInput | undefined = !isTableFiltered
    ? undefined
    : filters.reduce<EcmFilterInput>(
        toFormattedFilters(
          dateStringToIsoDateFilterFormat,
          extractDates(dateStringToIsoDateFilterFormat)
        ),
        {}
      );

  const formattedSort: EcmSortInput | undefined =
    sortBy.length > 0
      ? sortBy.map(field => {
          return {
            [field.id]:
              typeof field.desc !== 'boolean'
                ? undefined
                : field.desc
                  ? EcmSortOrder.Desc
                  : EcmSortOrder.Asc,
          };
        })[0]
      : undefined;

  const paginationParams: EcmDocumentsPaginationParams = useMemo(
    () => ({
      filter: formattedFilters,
      sort: formattedSort,
    }),
    [formattedFilters, formattedSort]
  );

  const ecmContractsDataOptions = useMemo(
    () => ({
      params: paginationParams,
    }),
    [paginationParams]
  );

  const {
    data,
    documentsCount,
    handleDebounceSearch,
    isEmpty,
    isLoading,
    onLoadMore,
  } = useInboxContractsData(ecmContractsDataOptions);

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return (
      <InboxSensitiveDocumentsEcmEmptyState
        isTableEmpty={isEmpty}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    if (search) {
      searchParams.set(queryParameter, search);
    } else {
      searchParams.delete(queryParameter);
      searchParams.delete(searchTypeParameter);
      searchParams.delete(searchScopeParameter);
    }
    setSearchParam(searchParams, { replace: true });
  };

  const getPath = useCallback(
    ({ id, cursor }: EcmDocumentsTableData) => {
      if (!organizationSlug) {
        return '';
      }

      const { pathname, search } = generateInboxTableRowPath({
        cursor,
        documentId: id,
        organizationSlug,
        route: Routes.INBOX_CONTRACTS,
        searchParams,
      });

      return { pathname, search };
    },
    [organizationSlug, searchParams]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <>
      <InboxContractsHeader />
      <InboxViewContainer activeTab={TabView.INBOX_CONTRACTS}>
        <Flex
          direction="column"
          height="100%"
          paddingX="space32"
          paddingBottom="space24"
        >
          {wipTableRefactorForEcmAllDocumentsFF ? (
            <>
              <InboxContractsToolbar
                searchQuery={queryStringFilter}
                onSearchChange={handleSearch}
                isLoadingConfigurations={isResetPending}
                configurationsTable={configurationsTable}
                onUpdateConfigurations={handleUpdateConfigurations}
                onResetTableConfigurations={handleResetTableConfigurations}
              />
              <EcmDocumentsTableV2Adapter
                data={data}
                onLoadMore={onLoadMore}
                sortBy={sortBy}
                onSort={onSort}
                isLoading={isLoading}
                shownColumns={availableDocumentColumnIds}
                CellWrapper={InboxContractsLink}
                sortByFields={sortByFields}
                emptyState={
                  <InboxSensitiveDocumentsEcmEmptyState
                    isTableEmpty={isEmpty}
                    isTableFiltered={isTableFiltered} // TODO: use power filters
                    resetFilters={() => onFilter([])} // TODO: use power filters
                  />
                }
              />
            </>
          ) : (
            <EcmDocumentsTable
              context="contracts"
              columns={availableDocumentColumnIds}
              data={data}
              isLoading={isLoading}
              isLoadingConfigurations={isResetPending}
              defaultFilters={filters}
              isTableFiltered={isTableFiltered}
              configurationsTable={configurationsTable}
              searchQuery={queryStringFilter}
              selectedDocumentsCount={documentsCount}
              defaultSortBy={sortBy}
              onSearchChange={handleSearch}
              onUpdateConfigurations={handleUpdateConfigurations}
              onFilter={onFilter}
              onEndReached={onLoadMore}
              onSort={onSort}
              customEmptyState={customEmptyState}
              onResetTableConfigurations={handleResetTableConfigurations}
              cellWrapper={cellWrapper}
              getCellStyles={cellStyle}
              hideDownload
            />
          )}
        </Flex>
      </InboxViewContainer>
    </>
  );
};

interface InboxContractsLinkProps {
  row: EcmDocumentsTableData;
  children?: ReactNode;
}

const InboxContractsLink = ({ row, children }: InboxContractsLinkProps) => {
  const [searchParams] = useSearchParams();
  searchParams.set('cursor', row.cursor ?? '');
  const path = usePath({
    pathname: '/inbox/contracts/:documentId',
    params: {
      documentId: row.id,
    },
    search: searchParams.toString(),
  });

  return <DocumentsTableLink to={path}>{children}</DocumentsTableLink>;
};
