import { ReactNode, forwardRef } from 'react';
import {
  Key,
  ToggleButtonGroup as ToggleButtonGroupReactAria,
} from 'react-aria-components';
import styles from './DaysStep.module.css';
import clsx from 'clsx';

interface ButtonGroupProps {
  onChange?: (keys: Set<Key>) => void;
  children: ReactNode;
  value?: Array<Key>;
  className?: string;
  isDisabled?: boolean;
}

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (
    { onChange, value, children, className, isDisabled = false, ...restProps },
    ref
  ) => {
    return (
      <ToggleButtonGroupReactAria
        onSelectionChange={onChange}
        selectedKeys={(value ?? []).length ? value : []}
        disallowEmptySelection={false}
        selectionMode="multiple"
        ref={ref}
        className={clsx(styles['toggle-button-wrapper'], className)}
        isDisabled={isDisabled}
        {...restProps}
      >
        {children}
      </ToggleButtonGroupReactAria>
    );
  }
);
