import {
  PerDiemStep,
  ReimbursementItemStatusInput,
} from 'generated-types/graphql.types';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';
import moment from 'moment';
import { sanitisePerDiemDays } from './sanitizePerDiemDays';

const formatDateTime = (date?: string | null, time?: string | null) =>
  date ? `${date}, ${time || '00:00'}` : undefined;

const convertToUTCFormat = (dateTimeStr?: string): string | null => {
  if (!dateTimeStr) return null;
  return moment.utc(dateTimeStr, 'YYYY-MM-DD HH:mm').format();
};

export const sanitizePerDiemFormInput = (
  values: ReimbursementItemSchemaOutput,
  currentStep?: PerDiemStep
) => {
  if (values.reimbursementItemType !== 'perDiem') {
    return undefined;
  }

  const days =
    values.currentStep === PerDiemStep.Days
      ? sanitisePerDiemDays(values)
      : undefined;

  const segments =
    values.currentStep === PerDiemStep.Segments
      ? (values.segments || []).map(segment => ({
          id: segment.segmentId,
          locationId: segment.location ?? null,
          startAt: convertToUTCFormat(
            formatDateTime(segment.startDate, segment.startTime)
          ),
          endAt: convertToUTCFormat(
            formatDateTime(segment.endDate, segment.endTime)
          ),
        }))
      : undefined;

  return {
    days,
    segments,
    status: values.isItemExcluded
      ? ReimbursementItemStatusInput.Excluded
      : ReimbursementItemStatusInput.Included,
    currentStep: currentStep ?? values.currentStep,
  };
};
