import { CardIssuerTransactionDateFilterInput } from 'generated-types/graphql.types';
import { useTransactionsInsights } from 'views/CreditCards/hooks/useTransactionsInsights';
import { getMappedCardHolderInsights } from 'views/CreditCards/utils/getMappedCardHolderInsights';

export const useMissingInvoices = ({
  dateRangeFilters,
}: {
  dateRangeFilters?: CardIssuerTransactionDateFilterInput;
}) => {
  const { data, loading, previousData } = useTransactionsInsights({
    dateRangeFilters,
  });

  const missingInvoices = getMappedCardHolderInsights(
    loading
      ? data?.getTransactionsInsights?.cardholders.length
        ? data?.getTransactionsInsights?.cardholders
        : previousData?.getTransactionsInsights?.cardholders
      : data?.getTransactionsInsights?.cardholders
  );

  const initialLoading = loading && !data && !previousData;

  return { loading: initialLoading, missingInvoices };
};
