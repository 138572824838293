import {
  LottieAnimation,
  noresultsData,
  successData,
} from 'components/Lottie/Lottie';
import { ReactNode } from 'react';

interface InboxDocumentsEmptyStateProps {
  isTableFiltered: boolean;
  children: ReactNode;
}

export const InboxDocumentsEmptyStateLayout = ({
  isTableFiltered,
  children,
}: InboxDocumentsEmptyStateProps) => {
  return (
    <div className="grid text-gray-800 pt-26 min-h-full justify-center gap-4 items-center">
      <LottieAnimation
        options={{
          animationData: isTableFiltered ? noresultsData : successData,
        }}
      />
      <div className="flex max-w-64 items-center flex-col">{children}</div>
    </div>
  );
};
