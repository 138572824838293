import { pdfjs } from 'react-pdf';

export const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

export const intersectionOptions = {
  threshold: [0.1, 0.3, 0.5, 0.7, 0.9, 1.0],
};

export const getPageId = (prefix: string, pageNumber: number) => {
  return `${prefix}_page_${pageNumber}`;
};

export const goToPage = (viewerId: string, page: number) => {
  const pageId = getPageId(viewerId, page);
  const element = document.getElementById(pageId);

  element?.scrollIntoView({ behavior: 'smooth' });
};

export const getHighestVisiblePageNumber = (
  pageVisibility: Map<number, number>
) => {
  let currentPageNumber = 1;
  let highestVisibilityRatio = 0;

  for (const [pageNumber, visibilityRatio] of pageVisibility.entries()) {
    if (visibilityRatio <= highestVisibilityRatio) continue;

    currentPageNumber = pageNumber;
    highestVisibilityRatio = visibilityRatio;

    if (highestVisibilityRatio > 0.6) break;
  }

  return currentPageNumber;
};

export const PAGE_CHANGE_VISIBILITY_WAIT_TIME_MS = 100;
export type PdfStatus = 'idle' | 'loading' | 'loaded' | 'error';
