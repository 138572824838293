import { useEcm } from 'orgConfig/ecm/useEcm';
import { AdvancedSearchFieldFilterOverlayPromo } from './AdvancedSearchFieldFilterOverlayPromo';
import { AdvancedSearchFieldFilterOverlay } from './AdvancedSearchFieldFilterOverlay';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { AdvancedSearchFieldFilterOverlayBase } from './AdvancedSearchFieldFilterOverlayBase';
import { useEffect } from 'react';
import {
  EventLocationsBase,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { AdvancedSearchFieldFilterOverlayESIndex } from './AdvancedSearchFieldFilterOverlayESIndex';

interface AdvancedSearchFieldFilterOverlayContainerProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlayContainer({
  onApply,
  onShowPromoModal,
}: AdvancedSearchFieldFilterOverlayContainerProps) {
  const { showSearchOverlayPromo, showConsistentSortAndFiltering } = useEcm();
  const currentEntitlement = useEntitlementsFlag();

  const { track } = useAnalytics();
  useEffect(() => {
    track(TrackingEvents.FILTER_OPENED, {
      event_location: EventLocationsBase.GLOBAL_SEARCH,
    });
  }, [track]);

  const handleOnApply = () => {
    track(TrackingEvents.FILTER_APPLIED, {
      event_location: EventLocationsBase.GLOBAL_SEARCH,
    });
    onApply();
  };

  if (currentEntitlement === Entitlements.BASE) {
    return (
      <AdvancedSearchFieldFilterOverlayBase
        onApply={handleOnApply}
        onShowPromoModal={onShowPromoModal}
      />
    );
  }

  if (showSearchOverlayPromo && showConsistentSortAndFiltering) {
    return (
      <AdvancedSearchFieldFilterOverlayESIndex
        onApply={handleOnApply}
        onShowPromoModal={onShowPromoModal}
      />
    );
  }

  if (showSearchOverlayPromo) {
    return (
      <AdvancedSearchFieldFilterOverlayPromo
        onApply={handleOnApply}
        onShowPromoModal={onShowPromoModal}
      />
    );
  }

  return (
    <AdvancedSearchFieldFilterOverlay
      onShowPromoModal={onShowPromoModal}
      onApply={handleOnApply}
    />
  );
}
