import { useFlags as useLaunchDarklyFlags } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';

export const localFeatureFlagPrefix = 'candis-local-feature-flags';

// https://stackoverflow.com/a/65468588
export function useCandisFeatureFlags<T extends FEATURE_FLAGS[]>(
  flagNames: [...T],
  localStoragePrefix?: string,
  useFlags?: typeof useLaunchDarklyFlags
): { [K in keyof T]: boolean };

export function useCandisFeatureFlags<T extends FEATURE_FLAGS>(
  flagNames: T,
  localStoragePrefix?: string,
  useFlags?: typeof useLaunchDarklyFlags
): boolean;

export function useCandisFeatureFlags<
  T extends FEATURE_FLAGS[] | FEATURE_FLAGS,
>(
  flagNames: T extends Array<FEATURE_FLAGS> ? [...T] : FEATURE_FLAGS,
  localStoragePrefix = localFeatureFlagPrefix,
  useFlags = useLaunchDarklyFlags
) {
  const flags = useFlags();

  const getVariation = (flagName: FEATURE_FLAGS): boolean => {
    const localStorageKey = `${localStoragePrefix}.${flagName}`;
    const localFlagValue = localStorage.getItem(localStorageKey);
    if (localFlagValue) {
      // The local value trumps global values
      return localFlagValue === 'true';
    }

    return Boolean(flags[flagName]);
  };

  return Array.isArray(flagNames)
    ? flagNames.map(getVariation)
    : getVariation(flagNames);
}

export const entitlementsFlagName = 'candis_features.entitlements';

export const Entitlements = {
  LEGACY_DMS: 'LEGACY_DMS', // Artificial package, not yet rolled out
  LEGACY: 'LEGACY',
  BASE: 'BASE',
  PLUS: 'PLUS',
  MAX: 'MAX',
} as const;

export type Entitlements = keyof typeof Entitlements;

type AvailableFlags = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];
type LaunchDarklyFlagsType = { [flag in AvailableFlags]: boolean } & {
  [entitlementsFlagName]: Entitlements;
};

export function useLaunchDarklyDefaultEntitlement() {
  const flags = useLaunchDarklyFlags<LaunchDarklyFlagsType>();

  return flags[entitlementsFlagName];
}

const isEntitlement = (key: string | null): key is Entitlements =>
  typeof key === 'string' && Object.keys(Entitlements).includes(key);

export function useEntitlementsFlag() {
  const launchDarklyEntitlement = useLaunchDarklyDefaultEntitlement();
  const localEntitlement = localStorage.getItem(entitlementsFlagName);

  const entitlement = isEntitlement(localEntitlement)
    ? localEntitlement
    : launchDarklyEntitlement;

  return entitlement ?? Entitlements.LEGACY;
}
