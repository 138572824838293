import { ReactNode, memo } from 'react';
import { CurrentPageLabel } from './controls/CurrentPageLabel';
import { FitHeightButton } from './controls/FitHeightButton';
import { FitWidthButton } from './controls/FitWidthButton';
import { NextPageButton } from './controls/NextPageButton';
import { PrevPageButton } from './controls/PrevPageButton';
import { RotateButton } from './controls/RotateButton';
import { ZoomInButton } from './controls/ZoomInButton';
import { ZoomOutButton } from './controls/ZoomOutButton';
import { PdfStatus } from './utils';

const hasFeature = (feature: PDF_FEATURE, disabledControls?: PDF_FEATURE[]) => {
  if (!disabledControls) return true;

  return !disabledControls.includes(feature);
};

export type PDF_FEATURE =
  | 'previousPage'
  | 'nextPage'
  | 'currentPage'
  | 'zoomIn'
  | 'zoomOut'
  | 'fitPageWidth'
  | 'fitPageHeight'
  | 'rotateLeft'
  | 'downloadFiles';

export interface PdfHeaderProps {
  downloadSection?: ReactNode;
  additionalControls?: ReactNode;
  attachmentSection?: ReactNode;
  status: PdfStatus;
  totalPages?: number;
  disabledControls?: PDF_FEATURE[];
  pdfControlsWrapper?: (props: { children: ReactNode }) => JSX.Element;
}

const DefaultControlsWrapper: PdfHeaderProps['pdfControlsWrapper'] = ({
  children,
}) => <div className="flex items-center gap-1">{children}</div>;

export const PdfHeader = memo(
  ({
    downloadSection,
    attachmentSection,
    status,
    additionalControls,
    totalPages,
    disabledControls,
    pdfControlsWrapper: PdfControlsWrapper = DefaultControlsWrapper,
  }: PdfHeaderProps) => {
    return (
      <>
        <div className="flex items-center gap-1">
          {additionalControls}
          {hasFeature('previousPage', disabledControls) && (
            <PrevPageButton disabled={status !== 'loaded'} />
          )}
          {hasFeature('nextPage', disabledControls) && (
            <NextPageButton
              disabled={status !== 'loaded'}
              totalPages={totalPages}
            />
          )}
          {hasFeature('currentPage', disabledControls) &&
            status === 'loaded' && <CurrentPageLabel totalPages={totalPages} />}
        </div>
        <PdfControlsWrapper>
          {hasFeature('zoomIn', disabledControls) && <ZoomInButton />}
          {hasFeature('zoomOut', disabledControls) && <ZoomOutButton />}
          {hasFeature('fitPageWidth', disabledControls) && <FitWidthButton />}
          {hasFeature('fitPageHeight', disabledControls) && <FitHeightButton />}
          {hasFeature('rotateLeft', disabledControls) && <RotateButton />}
          {hasFeature('downloadFiles', disabledControls) && downloadSection}
          {attachmentSection}
        </PdfControlsWrapper>
      </>
    );
  }
);
