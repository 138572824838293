import { Drawer } from '@candisio/design-system';
import { CreditCardDrawer } from 'containers/credit-cards/CreditCardDrawer';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

export const CreditCardsDetailsDrawer = ({
  onClose,
}: { onClose: () => void }) => {
  const { creditCardId } = useParams<{ creditCardId: string }>();

  const isOpen = !!creditCardId;

  return (
    <Drawer width="40.5rem" isOpen={isOpen} onClose={onClose}>
      <CreditCardDrawer cardId={creditCardId ?? ''} onClose={onClose} />
    </Drawer>
  );
};
