import { PdfViewerProps } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { useGetExpenseForPreviewQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';

export const useGetExpenseForPreview = ({
  expenseId,
}: { expenseId: string }) => {
  const { data, loading } = useGetExpenseForPreviewQuery({
    variables: { id: expenseId },
  });

  const firstFile =
    data?.reimbursementItem.__typename === 'GeneralExpenseItem' ||
    data?.reimbursementItem.__typename === 'HospitalityExpenseItem' ||
    data?.reimbursementItem.__typename === 'PerDiemItem'
      ? data?.reimbursementItem.files?.[0]
      : undefined;

  const file = useMemo(() => {
    return {
      name: firstFile?.name ?? '',
      url: firstFile?.url ?? '',
      id: firstFile?.id ?? '',
    } satisfies PdfViewerProps['documentFile'];
  }, [firstFile]);

  return { file, isExpenseForPreviewLoading: loading };
};
