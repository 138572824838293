import { CreditCardsTableTab } from './CreditCardsTableTab';
import { CreditCardsIssueNewCardModal } from '../CreditCardsInsights/CreditCardsIssueNewCardModal';
import { CreditCardsDetailsDrawer } from '../CreditCardDetailsDrawer';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { OrganizationSettingsDrawer } from '../CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

export const CreditCardsTableView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate({ pathname: '..', search: location.search }, { relative: 'path' });
  };

  return (
    <CreditCardsProvider>
      <>
        <CreditCardsTableTab />
        <CreditCardsIssueNewCardModal />
        <CreditCardsDetailsDrawer onClose={goBack} />
      </>
      <OrganizationSettingsDrawer />
    </CreditCardsProvider>
  );
};
