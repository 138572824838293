import {
  Button,
  Card,
  Cell,
  Grid,
  Skeleton,
  Table,
  Tag,
  Text,
  TruncatedText,
  useModal,
} from '@candisio/design-system';
import { BDSPromotionModal } from 'components/ProductPromotions/Provisions/BDSPromotionModal';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { amountCellProps } from 'components/Table/Cells/Amount';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import {
  DocumentCurrency,
  DocumentStatus,
} from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { isNil } from 'lodash';
import { Routes } from 'models';
import moment from 'moment';
import { AnimatePresence, motion } from 'motion/react';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { Column, Row, SortingRule } from 'react-table';
import { DateFormatters } from 'utils/date_formatter';
import { sortByNumberProp } from 'utils/sorting';
import { MAX_PAYABLE_DOCUMENT_COUNT } from 'views/consts';
import { SimplifiedPaidDocument, SimplifiedPayableDocument } from '../types';
import { DocumentsExceededWarning } from './DocumentsExceededWarning';
import { DueDateWithCashDiscountCell } from './DueDateWithCashDiscountCell';
import { Header } from './Header';
import { AmountCellPayment } from './PaymentAmountCell';
import { PaymentInfoCell } from './PaymentInfoCell';
import { PaymentTableFooter } from './PaymentTableFooter';
import { PaymentTableEmptyState } from './PaymentsTableEmptyState';
import { SelectAllPayments } from './SelectAllPayments';
import { TableTitle } from './TableTitle';
import { SelectionCell, SelectionHeader } from './tableUtils';
import { PaymentTableData } from './types';
import { ExpenseExportNotAvailableBanner } from './ExpenseExportNotAvailableBanner';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';

interface PaymentTableProps {
  loading?: boolean;
  documents: SimplifiedPayableDocument[] | SimplifiedPaidDocument[];
  // current selected currency for payment
  selectedCurrency?: DocumentCurrency;
  hasProcessedPayment?: boolean;
  selectedDocuments: SimplifiedPayableDocument[];
  selectableDocuments: SimplifiedPayableDocument[];
  setSelectedDocuments: Dispatch<SetStateAction<SimplifiedPayableDocument[]>>;
  isPaid: boolean;
  onLoadMore: () => any;
  totalDocumentCount: number;
  onSort?: (sortBy: SortingRule<PaymentTableData>[]) => void;
  paymentDate?: Date;
  setExportAll: Dispatch<SetStateAction<boolean>>;
  exportAll: boolean;
  payableCount: number;
  columns?: Array<keyof PaymentTableData>;
  configurationsTable: Configuration[];
  onUpdateConfigurations: (configurations: Configuration[]) => void;
  onResetConfigurations: () => void;
}

const PAYMENTS_PROMOTION_KEY = 'payments_promotion_dismissed';

export const EXPENSE_BANNER_KEY = 'limited-support-sepa-expense';

/**
 * Base component to render payable and paid documents tables
 */
export const PaymentTable = ({
  loading = false,
  documents,
  selectedCurrency,
  hasProcessedPayment,
  selectedDocuments,
  setSelectedDocuments,
  isPaid,
  onLoadMore,
  totalDocumentCount,
  onSort,
  paymentDate,
  selectableDocuments,
  setExportAll,
  exportAll,
  payableCount,
  columns: shownColumns,
  configurationsTable,
  onUpdateConfigurations,
  onResetConfigurations,
}: PaymentTableProps) => {
  const { canUseReimbursement } = useReimbursement();
  const [isExpenseExportBannerVisible, setIsExpenseExportBannerVisible] =
    useLocalStorage(EXPENSE_BANNER_KEY, canUseReimbursement);
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const organizationSlug = useOrganizationId();

  const { bdsBought } = useDatev(); // BDS-checked
  const sap = useSap();

  const onSelectAll = useCallback(() => {
    setSelectedDocuments(exportAll ? [] : selectableDocuments);
    setExportAll(all => !all);
  }, [exportAll, selectableDocuments, setSelectedDocuments, setExportAll]);

  const handleHideExpenseExportBanner = useCallback(() => {
    setIsExpenseExportBannerVisible(false);
  }, [setIsExpenseExportBannerVisible]);

  const defaultColumn = useMemo(
    (): Partial<Column<PaymentTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      Cell,
      disableFilters: true,
    }),
    []
  );

  const columnsWithoutSelection = useMemo((): Column<PaymentTableData>[] => {
    const statusColumn: Column<PaymentTableData> = {
      accessor: 'status',
      Cell: cell => {
        const translatedStatus = t(`statuses.${cell.value?.toLowerCase()}`);

        const tagColor =
          cell.value === DocumentStatus.Approved ? 'green' : 'blue';

        return (
          <Tag color={tagColor} variant="secondary">
            {translatedStatus}
          </Tag>
        );
      },
      width: 110,
    };

    const dueDateWithCashDiscountColumn: Column<PaymentTableData> = {
      accessor: 'dueDateWithCashDiscount',
      Cell: DueDateWithCashDiscountCell,
      width: 150,
    };

    const allColumns: Column<PaymentTableData>[] = [
      ...(!isPaid ? [statusColumn] : []),
      {
        accessor: 'contact',
        Cell: cell => <TruncatedText>{cell.value}</TruncatedText>,
        minWidth: 100,
      },
      {
        accessor: 'paymentInfo',
        Cell: PaymentInfoCell,
        disableFilters: true,
        width: 130,
      },
      {
        accessor: 'invoiceNumber',
        Cell: ({ cell }) => (
          <TruncatedText charsAfterEllipsis={3}>{cell.value}</TruncatedText>
        ),
        width: 100,
      },
      {
        accessor: 'invoiceDueDate',
        Cell: cell => (
          <TruncatedText>
            {cell.value
              ? DateFormatters.compact(new Date(cell.value))
              : t('placeholder')}
          </TruncatedText>
        ),
        width: 128,
      },
      {
        Header: ({ isFilteredOrSorted }) => (
          <TruncatedText color={isFilteredOrSorted ? 'gray800' : 'gray500'}>
            {t(!isPaid ? 'table.headers.amount' : 'table.headers.paidAmount')}
          </TruncatedText>
        ),
        accessor: 'amount',
        // XXX Replace with the money formatter hook once
        // the new currency field is available on a payable document
        Cell: props => <AmountCellPayment {...props} isPaid={isPaid} />,
        sortType: sortByNumberProp('values.amount.amount.amount'),
        ...amountCellProps,
      },
      ...(!isPaid ? [dueDateWithCashDiscountColumn] : []),
    ];

    if (!shownColumns) return allColumns.filter(Boolean);

    return shownColumns
      .map(columnId => allColumns.find(col => col.accessor === columnId))
      .filter(Boolean) as Array<Column<PaymentTableData>>;
  }, [isPaid, shownColumns, t]);

  const columns: Array<Column<PaymentTableData>> = useMemo(() => {
    if (isPaid) {
      return columnsWithoutSelection;
    }

    return [
      {
        id: 'selection',
        Header: props => (
          <SelectionHeader
            selectedFlatRows={props.selectedFlatRows}
            toggleAllRowsSelected={onSelectAll}
            selectableDocuments={selectableDocuments}
          />
        ),
        Cell: ({ row }: { row: Row<PaymentTableData> }) => {
          return (
            <SelectionCell
              row={row}
              setSelectedRows={setSelectedDocuments}
              selectableDocuments={selectableDocuments}
            />
          );
        },
        width: 48,
      },
      ...columnsWithoutSelection,
    ];
  }, [
    columnsWithoutSelection,
    isPaid,
    selectableDocuments,
    setSelectedDocuments,
    onSelectAll,
  ]);

  const data = useMemo((): PaymentTableData[] => {
    return documents.map(document => {
      const documentCanHaveStatus =
        document.__typename === 'DocumentWithIncompletePaymentData' ||
        document.__typename === 'SepaExportableDocument';

      const documentCanHaveCreditorIban =
        document.__typename === 'PaidDocument' ||
        document.__typename === 'SepaExportableDocument';

      const dueDateWithCashDiscount =
        document.__typename === 'SepaExportableDocument' &&
        !isNil(document.discountAmount) &&
        !isNil(document.discountPercentage) &&
        !isNil(document.discountPaymentDate)
          ? {
              discountAmount: document.discountAmount,
              discountPercentage: document.discountPercentage,
              discountPaymentDate: new Date(document.discountPaymentDate),
            }
          : undefined;

      const discountAmount =
        document.__typename === 'PaidDocument' &&
        paymentDate !== undefined &&
        !isNil(document.discountAmount) &&
        !isNil(document.discountPaymentDate) &&
        moment(paymentDate).isSameOrBefore(document.discountPaymentDate, 'day')
          ? document.discountAmount
          : undefined;

      return {
        ...(!isPaid && { selectedCurrency }),
        ...(!isPaid && documentCanHaveStatus && { status: document.status }),
        id: document.id,
        contact: document.contact,
        creditorIban: documentCanHaveCreditorIban
          ? document.creditorIban
          : undefined,
        invoiceNumber: document.invoiceNumber,
        invoiceDueDate: document.invoiceDueDate
          ? new Date(document.invoiceDueDate)
          : undefined,
        amount: {
          amount: document.amount,
          discountAmount,
        },
        document: document,
        dueDateWithCashDiscount,
        paymentInfo: {
          bankAccountNumber: undefined,
          iban: documentCanHaveCreditorIban ? document.creditorIban : undefined,
          swiftCode: documentCanHaveCreditorIban
            ? (document.creditorSwiftCode ?? undefined)
            : undefined,
        },
      };
    });
  }, [documents, paymentDate, isPaid, selectedCurrency]);

  const title = isPaid
    ? t('headers.paid', { count: data.length })
    : t('headers.payable', { count: totalDocumentCount });

  const showBdsPromotionModal = !bdsBought && !sap.isActiveIntegration;

  const selectedDocumentIds = selectedDocuments?.map(document => document.id);
  const showEmptyState = !loading && data.length === 0;
  const showFooterHelpLink = !isPaid && !showEmptyState && data.length > 0;

  const navigateToDocument = useCallback(
    (document: Row<PaymentTableData>): void => {
      if (!document) return;
      const { pathname: from, search } = location;
      const pathname = `/${organizationSlug}${Routes.ARCHIVE}/${document.id}`;

      navigate({ pathname }, { state: { from, search } });
    },
    [location, organizationSlug, navigate]
  );

  const emptyState = () => (
    <PaymentTableEmptyState hasProcessedPayment={hasProcessedPayment} />
  );

  return (
    <Grid
      gap="space16"
      templateRows="auto 1fr"
      overflow="hidden"
      key={`payments-table-configurations-${(shownColumns ?? []).join('-')}`}
    >
      <Grid gap="space16">
        <Grid
          alignItems="center"
          autoFlow="column"
          justifyContent="space-between"
          paddingTop="space20"
        >
          <TableTitle loading={loading}>{title}</TableTitle>
          {data.length > MAX_PAYABLE_DOCUMENT_COUNT && (
            <DocumentsExceededWarning />
          )}

          <ConfigurationsMenu
            configurationItems={configurationsTable}
            onUpdateConfigurations={onUpdateConfigurations}
            onResetConfigurations={onResetConfigurations}
            isLoading={loading}
          />
        </Grid>
        {isExpenseExportBannerVisible && (
          <ExpenseExportNotAvailableBanner
            onHideExpenseExportBanner={handleHideExpenseExportBanner}
          />
        )}
        {!isExpenseExportBannerVisible && showBdsPromotionModal && (
          <PromotionSection />
        )}
        <AnimatePresence initial={false}>
          {!isPaid && selectedDocuments?.length > 0 && payableCount !== 1 && (
            <motion.div
              initial={{ opacity: 0, height: '0px' }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: '0px' }}
              transition={{ duration: 0.25, ease: 'easeInOut' }}
            >
              {!loading ? (
                <SelectAllPayments
                  selectedCount={selectedDocuments.length}
                  totalCount={totalDocumentCount}
                  payableCount={payableCount}
                  onSelectAll={onSelectAll}
                  exportAll={exportAll}
                />
              ) : (
                <Skeleton height="space48" width="100%" />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Grid>

      <Grid position="relative">
        <Table
          columns={columns}
          data={data}
          defaultColumn={defaultColumn}
          height="100%"
          isLoading={loading}
          onEndReached={onLoadMore}
          onRowClick={navigateToDocument}
          selectedRowIds={selectedDocumentIds}
          showDefaultEmptyState={false}
          onSort={onSort}
          tableFooter={showFooterHelpLink ? <PaymentTableFooter /> : null}
          customEmptyState={emptyState}
        />
      </Grid>
    </Grid>
  );
};

const PromotionSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const { triggerProps, isOpen, close } = useModal();
  const [dismissed, setDismissed] = useLocalStorage(
    PAYMENTS_PROMOTION_KEY,
    false
  );

  if (dismissed) return null;

  const dismissMessage = () => setDismissed(true);

  return (
    <Card background="purple100" padding="space12 space16">
      <Grid
        paddingLeft="space8"
        templateColumns="1fr auto auto"
        alignItems="center"
        gap="space4"
      >
        <Grid>
          <Text fontWeight="semibold" fontSize="basic">
            {t('add-on.title')}
          </Text>
          <Text fontSize="small"> {t('add-on.description')}</Text>
        </Grid>
        <Button color="purple" {...triggerProps}>
          {t('add-on.button')}
        </Button>
        <Button
          icon="close"
          variant="tertiary"
          label={t('add-on.buttonTooltip')}
          size="small"
          onClick={dismissMessage}
        />
      </Grid>
      {isOpen && (
        <BDSPromotionModal
          utmSource={PROVISIONS_UTM_SOURCE.PAYMENT_TABLE}
          isOpen={isOpen}
          close={close}
        />
      )}
    </Card>
  );
};
