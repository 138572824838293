import { Icon } from '@candisio/design-system';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import styles from './OverviewIssueCardTile.module.css';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  CREDIT_CARD_SEARCH_PARAM_ON_VIEW,
  ISSUE_NEW_CARD_HASH,
} from 'containers/credit-cards/constants';
import { useUserRoles } from 'hooks/useUserRoles';

export const OverviewIssueCardTile = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const { isCardManager } = useUserRoles();

  const openIssueNewCardModal = () => {
    const searchParams = new URLSearchParams();
    searchParams.set(CREDIT_CARD_SEARCH_PARAM_ON_VIEW, 'true');

    navigate({
      hash: ISSUE_NEW_CARD_HASH,
      search: searchParams.toString(),
    });
  };

  return (
    <SwimlaneTile asChild variant="gray">
      <button data-cy="issueNewCardTile" onClick={openIssueNewCardModal}>
        <span className={styles.content}>
          <Icon icon="plus" size={16} />
          {isCardManager
            ? t('ccRequestForm.issueNewCardCTA')
            : t('ccRequestForm.requestNewCardCTA')}
        </span>
      </button>
    </SwimlaneTile>
  );
};
