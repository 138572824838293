import { Day } from '../Day';
import { useState } from 'react';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';
import { ReimbursementItemFormPerDiemDay } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { CollapsibleDaysList } from './CollapsibleDaysList';
import { ItemSummary } from './ItemSummary';
import { SelectionVariant } from '../../utils/getMealsSelectorVariant';

interface ReducedDaysProps {
  formIndex: number;
  days: (ReimbursementItemFormPerDiemDay & { id: string })[];
  onSelectAllColumn: (params: SelectAllColumnPerDiemParams) => void;
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
  onGetMultiSelectorState: (
    params: GetMultiSelectorStatePerDiemParams
  ) => SelectionVariant;
  isDisabled: boolean;
}

export const ReducedDays = ({
  formIndex,
  days,
  isDisabled,
  onSelectAllColumn,
  onUpdatePerDiemDays,
  onGetMultiSelectorState,
}: ReducedDaysProps) => {
  const [isMiddleDaysHidden, setIsMiddleDaysHidden] = useState(true);

  const firstDayIndex = 0;
  const secondDayIndex = 1;
  const lastDayIndex = days.length - 1;
  const firstDay = days?.[firstDayIndex];
  const lastDay = days?.[lastDayIndex];

  const daysInTheMiddle = days.slice(secondDayIndex, lastDayIndex);

  return (
    <>
      <Day
        key={firstDay.id}
        date={firstDay.date}
        formIndex={formIndex}
        dayIndex={firstDayIndex}
        onUpdatePerDiemDays={onUpdatePerDiemDays}
        isDisabled={isDisabled}
      />

      {isMiddleDaysHidden ? (
        <ItemSummary
          collapsibleDays={daysInTheMiddle}
          isDisabled={isDisabled}
          onSetIsMiddleDayHidden={setIsMiddleDaysHidden}
          onSelectAllColumn={({ id, value }) =>
            onSelectAllColumn({
              id,
              value,
              startIndex: secondDayIndex,
              endIndex: lastDayIndex,
            })
          }
          onGetMultiSelectorState={({ id }) =>
            onGetMultiSelectorState({
              id,
              startIndex: secondDayIndex,
              endIndex: lastDayIndex,
            })
          }
        />
      ) : (
        <CollapsibleDaysList
          collapsibleDays={daysInTheMiddle}
          formIndex={formIndex}
          isDisabled={isDisabled}
          onUpdatePerDiemDays={onUpdatePerDiemDays}
          onSetIsMiddleDayHidden={setIsMiddleDaysHidden}
        />
      )}

      <Day
        key={lastDay.id}
        date={lastDay.date}
        formIndex={formIndex}
        dayIndex={lastDayIndex}
        onUpdatePerDiemDays={onUpdatePerDiemDays}
        isDisabled={isDisabled}
      />
    </>
  );
};
