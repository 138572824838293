import { useMissingInvoices } from '../../hooks/useMissingInvoices';

export const useMissingInvoicesList = () => {
  const { missingInvoices, loading } = useMissingInvoices({
    dateRangeFilters: undefined,
  });

  const totalCount = missingInvoices.reduce(
    (acc, user) => acc + user.invoicesWithoutTransaction,
    0
  );

  const emailList = missingInvoices.map(
    user => user.cardholder.membershipEmail
  );

  return { totalCount, emailList, missingInvoices, loading };
};
