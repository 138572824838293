import { CustomEmptyStateProps, Link } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { InboxDocumentsEmptyStateLayout } from '../InboxDocuments/InboxDocumentsEmptyState/InboxDocumentsEmptyStateLayout';

interface InboxDocumentsEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

const TableIsEmpty = () => {
  const { isOnlyApprover } = useUserRoles();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );

  const dispatch = useDocumentUploadDispatch();
  const { setLayoutValue } = useAppLayout();
  const handleShowSensitiveModal = () => dispatch('showSensitiveModal');

  return (
    <div className="flex flex-col items-center gap-4">
      <p
        className="text-center w-48 min-w-40 text-basic font-medium"
        style={{
          wordBreak: 'break-word',
        }}
      >
        {t('inbox.sensitiveDocuments.table.emptyState.allDoneInbox')}
      </p>
      {!isOnlyApprover && (
        <p className="text-center m-0">
          <Trans
            i18nKey="inbox.sensitiveDocuments.table.emptyState.upload"
            t={t}
          >
            You can
            <Link
              as="button"
              onClick={() =>
                documentUploadReworkFF
                  ? setLayoutValue('docUploadPopover', {
                      open: true,
                      isRestrictedContent: true,
                    })
                  : handleShowSensitiveModal
              }
            >
              upload
            </Link>
            new sensitive documents.
          </Trans>
        </p>
      )}
    </div>
  );
};

const TableIsFilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <>
      <span className="text-center whitespace-pre-wrap">
        {t('inbox.sensitiveDocuments.table.emptyState.filtered')}
      </span>
      <Link as="button" onClick={() => resetFilters()}>
        {t('inbox.sensitiveDocuments.table.emptyState.resetFilters')}
      </Link>
    </>
  );
};

export const InboxSensitiveDocumentsEcmEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: InboxDocumentsEmptyStateProps) => {
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  return (
    <InboxDocumentsEmptyStateLayout isTableFiltered={isTableFiltered}>
      {isFilteredAndEmpty ? (
        <TableIsFilteredAndEmpty resetFilters={resetFilters} />
      ) : (
        <TableIsEmpty />
      )}
    </InboxDocumentsEmptyStateLayout>
  );
};
