import { PowerSearchInput } from './components/PowerSearchInput';
import { PowerSearchFieldContainerProps } from './types';
import { useState } from 'react';
import { PowerSearchPopover } from './components/PowerSearchPopover';
import styles from './power-search-field-container.module.css';
import { usePowerSearchInputsState } from './hooks/usePowerSearchActions';
import { useFocusWithin } from 'react-aria';
import { useSearchTypesLabel } from './hooks/useSearchTypesLabel';
import { PowerSearchToggles } from './components/PowerSearchToggles';
import { SearchScope, SearchType } from 'generated-types/graphql.types';
import { ToggleButtonGroupProps } from 'components/ToggleButtonGroup/ToggleButtonGroup';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { SearchFilterUpsellModal } from 'components/AdvancedSearchFieldFilterOverlay/SearchFilterUpsellModal';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  EventLocationsBase,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';

export const PowerSearchFieldContainer = (
  props: PowerSearchFieldContainerProps
) => {
  const {
    placeholder,
    clearTooltip,
    initialValue,
    inputMaxLength,
    readOnly,
    onQueryChange,
  } = props;

  const currentEntitlement = useEntitlementsFlag();
  const isLegacy = currentEntitlement === Entitlements.LEGACY;
  const isBase = currentEntitlement === Entitlements.BASE;

  const [searchQuery, setSearchQuery] = useState(initialValue ?? '');
  const {
    searchType,
    searchScope,
    setSearchTypeChange,
    setSearchScopeChange,
    applySearchInputs,
    resetSearchInputs,
  } = usePowerSearchInputsState();

  const handleOnValueChange = (value: string) => {
    setSearchQuery(value);
    onQueryChange(value);
    if (value === '') {
      resetSearchInputs();
    }
  };

  const handleSearchTypeChange = (type: string) => {
    if (isLegacy && type !== SearchType.Contains) {
      openPromoModal();
      return;
    }
    setSearchTypeChange(type);
    // don't trigger network request if searchQuery is empty
    if (searchQuery) {
      applySearchInputs({ type: type as SearchType });
    }
  };

  const handleSearchScopeChange: ToggleButtonGroupProps['onChange'] = value => {
    const scope = value[0] as SearchScope;
    if (!isFullTextSearchAvailable && scope === SearchScope.EntireDocument) {
      openPromoModal();
      return;
    }
    setSearchScopeChange(scope);
    // don't trigger network request if searchQuery is empty
    if (searchQuery) {
      applySearchInputs({ scope });
    }
  };

  const [hasFocus, setHasFocus] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: setHasFocus,
  });
  const contextualPlaceholder = useSearchTypesLabel()[searchType];
  const showContextualPlaceholder = hasFocus && !!contextualPlaceholder;

  const { track } = useAnalytics();
  const shouldShowUpsellModal = isLegacy || isBase;
  const isFullTextSearchAvailable = !(isLegacy || isBase);
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const openPromoModal = () => {
    close();
    setIsPromoModalOpen(true);

    track(TrackingEvents.PROMOTION_MODAL_OPENED, {
      feature: '',
      event_location: EventLocationsBase.GLOBAL_SEARCH,
    });
  };
  const closePromoModal = () => {
    setIsPromoModalOpen(false);
  };

  return (
    <>
      <div
        className={styles['power-search-field-container']}
        {...focusWithinProps}
      >
        <PowerSearchInput
          value={searchQuery}
          onValueChange={handleOnValueChange}
          placeholder={
            showContextualPlaceholder ? contextualPlaceholder : placeholder
          }
          clearTooltip={clearTooltip}
          inputMaxLength={inputMaxLength}
          readOnly={readOnly}
        />

        <PowerSearchPopover
          query={searchQuery}
          searchType={searchType}
          onSearchTypeChange={handleSearchTypeChange}
        />
      </div>
      <PowerSearchToggles
        searchScope={searchScope}
        onSearchScopeChange={handleSearchScopeChange}
        isFullTextSearchAvailable={isFullTextSearchAvailable}
      />

      {shouldShowUpsellModal && (
        <SearchFilterUpsellModal
          isOpen={isPromoModalOpen}
          onClose={closePromoModal}
          isBaseEntitlement={isBase}
        />
      )}
    </>
  );
};
