import { usePath } from 'hooks/usePath';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardsDetailsDrawer } from 'views/CreditCards/CreditCardDetailsDrawer';

export const SwimlaneCreditCardDrawer = () => {
  const navigate = useNavigate();

  const path = usePath({
    pathname: '/credit_cards/dashboard/:creditCardId?',
  });

  const goBack = () => {
    navigate(path);
  };

  return <CreditCardsDetailsDrawer onClose={goBack} />;
};
