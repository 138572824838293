import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  EcmDocumentsTable,
  EcmDocumentsTableProps,
  defaultSortByFields,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { DocumentPreviewForExpenseContainer } from 'containers/DocumentPreviewForExpense/DocumentPreviewForExpenseContainer';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { useEcmDocumentsDataWithExpenses } from 'containers/document-relationships/useEcmDocumentsDataWithExpenses';
import { DocumentType } from 'generated-types/graphql.types';
import { AppRouteParams, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';
import { memo, useCallback, useMemo, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { generatePath } from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { getRawContentMatchesCount } from 'components/WithHighlightsCell/getRawContent';
import { EcmDocumentsTableV2Adapter } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/EcmDocumentsTableV2Adapter';
import { EcmDocumentsToolbar } from './EcmDocumentsToolbar';
import { customPaginationAllDocumentsFilterHooks } from 'components/EcmDocumentsTable/hooks/customPaginationAllDocumentsFilterHooks';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { EcmDocumentsEmptyState } from './EcmDocumentsEmptyState';

type Props = RouteComponentProps<{ organizationSlug: string }>;

interface PreviewDocument {
  id: string;
  type: string | undefined;
  reimbursementCaseId?: string;
}

const TableRowOverlay = memo(
  ({
    data,
    onQuickViewClick,
    previewDocumentId,
  }: {
    data: EcmDocumentsTableData;
    previewDocumentId: string | null;
    onQuickViewClick: (data: EcmDocumentsTableData) => void;
  }) => {
    if (data.selected) return <></>; // TODO: Fix DS type to allow to return null
    const rawContentHighlightCount = getRawContentMatchesCount(data.highlights);
    const isSelected = previewDocumentId
      ? data.id === previewDocumentId || data.invoiceId === previewDocumentId
      : false;
    return (
      <DocumentTableRowActionsOverlay
        data={data}
        onQuickViewClick={onQuickViewClick}
        isSelected={isSelected}
        previewHighlightsCount={rawContentHighlightCount}
      />
    );
  }
);

export const EcmDocuments = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const [wipTableRefactorForEcmAllDocumentsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.wipTableRefactorForEcmAllDocuments,
  ]);
  const { canUseReimbursement } = useReimbursement();
  const {
    showDocumentPreview,
    showDocumentPreviewPromo,
    showConsistentSortAndFiltering,
  } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const [previewDocument, setPreviewDocument] = useState<
    PreviewDocument | undefined
  >(undefined);

  const previewDocumentId = previewDocument?.id ?? null;

  const {
    availableDocumentColumnIds,
    searchQuery,
    searchParams,
    data,
    isLoading,
    filters,
    isTableFiltered,
    configurationsTable,
    totalCount,
    isSavingConfigurations,
    isResetPending,
    customEmptyState,
    sortBy,
    handleSearchChange,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    onFilter,
    onLoadMore,
    onSort,
  } = useEcmDocumentsDataWithExpenses({
    filterParamsSource: 'url',
    previewDocumentId,
  });

  const getTableRowDocumentType = useCallback(
    (document: EcmDocumentsTableData) => {
      if (
        document === undefined ||
        document.documentType === DocumentType.Invoice
      ) {
        return 'invoice';
      }

      return 'ecmDocument';
    },
    []
  );

  const onQuickViewClick = useCallback(
    (data: EcmDocumentsTableData) => {
      const isEcmDocument = getTableRowDocumentType(data) === 'ecmDocument';

      setPreviewDocument({
        id: isEcmDocument ? data.id : (data.invoiceId ?? ''),
        type: data.documentType,
        reimbursementCaseId: data.reimbursementCaseId,
      });
    },
    [getTableRowDocumentType]
  );

  const documentPreviewRowOverlay: EcmDocumentsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => (
              <TableRowOverlay
                data={data}
                onQuickViewClick={onQuickViewClick}
                previewDocumentId={previewDocumentId}
              />
            )
          : undefined,
      [showPreview, onQuickViewClick, previewDocumentId]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
      documentType,
      reimbursementCaseId,
    }: {
      documentId: string | null;
      cursor: string | null;
      documentType: string | undefined;
      reimbursementCaseId?: string;
    }): void => {
      if (!documentId) return;

      let pathname = `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${documentId}`;
      const params = new URLSearchParams();

      if (cursor) params.set('cursor', cursor);

      const isReimbursementItemType =
        documentType === 'EXPENSE_REIMBURSEMENT_ITEM' ||
        documentType === 'TRAVEL_REIMBURSEMENT_ITEM';

      if (isReimbursementItemType) {
        params.set(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
        pathname = `/${organizationSlug}${Routes.ECM_DOCUMENTS}${Routes.REIMBURSEMENTS}/${reimbursementCaseId}/${documentId}`;
      }

      if (isEcmDocumentId(documentId)) params.set('isInvoice', 'true');

      const path = `${pathname}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocument(undefined);
  }, []);

  const cursor =
    data.find(
      d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
    )?.cursor ?? null;

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
          promotionLocation={DocumentPreviewEventLocations.ARCHIVE}
          documentId={previewDocumentId}
        />
      );
    }

    if (showDocumentPreview) {
      const isExpenseReimbursementItem =
        previewDocument?.type === 'EXPENSE_REIMBURSEMENT_ITEM';

      const isTravelExpenseReimbursementItem =
        previewDocument?.type === 'TRAVEL_REIMBURSEMENT_ITEM';

      const showDocumentExpensesPreview =
        isTravelExpenseReimbursementItem || isExpenseReimbursementItem;

      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          closePreviewDrawer={closePreview}
          documentPreviewView={
            showDocumentExpensesPreview
              ? DocumentPreviewForExpenseContainer
              : undefined
          }
          openDocument={() =>
            openDocument({
              documentId: previewDocumentId,
              cursor,
              documentType: previewDocument?.type,
              reimbursementCaseId: previewDocument?.reimbursementCaseId,
            })
          }
          documentId={previewDocumentId}
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    previewDocument,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({
      id,
      cursor,
      documentType,
      invoiceId,
      reimbursementCaseId,
    }: EcmDocumentsTableData) => {
      if (!organizationSlug) return '';

      const isInvoice = documentType === DocumentType.Invoice;

      const documentId = invoiceId && isInvoice ? invoiceId : id;

      const isReimbursementItem = !!reimbursementCaseId;

      let pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
        { organizationSlug, documentId }
      );

      if (isInvoice) {
        searchParams.set('isInvoice', 'true');
      } else {
        searchParams.delete('isInvoice');
      }

      if (cursor) searchParams.set('cursor', cursor);

      if (isReimbursementItem) {
        searchParams.set(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
        pathname = generatePath(
          `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}${Routes.REIMBURSEMENTS}/:reimbursementId/:reimbursementItemId`,
          {
            organizationSlug,
            reimbursementId: reimbursementCaseId,
            reimbursementItemId: id,
          }
        );
      }

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, searchParams]
  );

  const cellWrapper = useMemo(() => {
    const linkProps = {
      state: {
        from: `/${organizationSlug}${Routes.ECM_DOCUMENTS}`,
        search: searchParams.toString(),
      },
    };

    return getCellWrapperLink(getPath, linkProps);
  }, [getPath, organizationSlug, searchParams]);

  const cellStyle = () => ({ padding: 'unset' });

  const sortByFields: (keyof EcmDocumentsTableData)[] =
    showConsistentSortAndFiltering
      ? [
          ...defaultSortByFields,
          'contact',
          'documentNumber',
          'documentName',
          'amount',
        ]
      : defaultSortByFields;

  const customPaginationFilterHooks = canUseReimbursement
    ? customPaginationAllDocumentsFilterHooks
    : undefined;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}
      rightSide={documentPreviewDrawer}
    >
      {wipTableRefactorForEcmAllDocumentsFF ? (
        <>
          <EcmDocumentsToolbar
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
            configurationsTable={configurationsTable}
            isLoadingConfigurations={isSavingConfigurations || isResetPending}
            onUpdateConfigurations={handleUpdateConfigurations}
            onResetTableConfigurations={handleResetTableConfigurations}
          />
          <EcmDocumentsTableV2Adapter
            data={data}
            onLoadMore={onLoadMore}
            sortBy={sortBy}
            onSort={onSort}
            rowOverlay={documentPreviewRowOverlay}
            isLoading={isLoading}
            shownColumns={availableDocumentColumnIds}
            sortByFields={sortByFields}
            emptyState={
              <EcmDocumentsEmptyState
                isTableEmpty={!isLoading && totalCount === 0}
                isTableFiltered={isTableFiltered} // TODO: Use power filters state here
                resetFilters={() => onFilter([])} // TODO: Use power filters method here
              />
            }
          />
        </>
      ) : (
        <EcmDocumentsTable
          context="documents"
          columns={availableDocumentColumnIds}
          data={data}
          isLoading={isLoading}
          defaultFilters={filters}
          isTableFiltered={isTableFiltered}
          configurationsTable={configurationsTable}
          searchQuery={searchQuery}
          selectedDocumentsCount={totalCount}
          defaultSortBy={sortBy}
          isLoadingConfigurations={isSavingConfigurations || isResetPending}
          customPaginationFilterHooks={customPaginationFilterHooks}
          onSearchChange={handleSearchChange}
          onUpdateConfigurations={handleUpdateConfigurations}
          onFilter={onFilter}
          onEndReached={onLoadMore}
          onSort={onSort}
          customEmptyState={customEmptyState}
          onResetTableConfigurations={handleResetTableConfigurations}
          rowOverlay={documentPreviewRowOverlay}
          cellWrapper={cellWrapper}
          getCellStyles={cellStyle}
          sortByFields={sortByFields}
        />
      )}
    </ArchiveViewLayout>
  );
};
