import {
  DocumentCurrency,
  Locale,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { faker } from 'utils/faker';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const availableFiltersAndSorts = [
  'approver',
  'createdAt',
  'grossAmount',
  'isPaid',
  'paidAt',
  'requesterOfTheReimbursement',
  'firstSubmittedForReviewAt',
  'lastApprovedByMembership',
  'status',
  'approvalRequestedAt',
  'requester',
  'approvers',
  'title',
  'lastClosedAt',
];

export const reimbursementTitlesEN: string[] = [
  'Team Building Event',
  'Office Supplies Purchase',
  'Conference Registration',
  'Client Meeting Lunch',
  'Software Subscription Renewal',
  'Training Workshop Fee',
  'Business Travel Expenses',
  'Equipment Upgrade',
  'Marketing Campaign Costs',
  'Annual Membership Fee',
];

export const reimbursementTitlesDE: string[] = [
  'Teambuilding-Event',
  'Büromaterialkauf',
  'Konferenzanmeldung',
  'Mittagessen beim Kundentermin',
  'Erneuerung der Software-Abonnements',
  'Gebühren für Schulungsworkshop',
  'Geschäftsreisekosten',
  'Ausrüstungs-Upgrade',
  'Kosten für Marketingkampagne',
  'Jahresmitgliedsbeitrag',
];

export const getReimbursementSampleData = (
  locale: Locale,
  status: ReimbursementCaseStatus
): ReimbursementTableRow[] => {
  return Array.from({ length: 25 }, (_, index) => ({
    id: faker.uuid(),
    isPaid: true,
    paidAt: faker.date(),
    createdAt: faker.date(),
    lastApprovedByMembership: {
      firstName: faker.indexedFirstNameFromEnd(index),
      lastName: faker.indexedLastNameFromEnd(index),
      membershipId: faker.uuid(),
    },
    requesterOfTheReimbursement: {
      membershipId: faker.uuid(),
      firstName: faker.indexedFirstName(index),
      lastName: faker.indexedLastName(index),
    },
    status,
    title:
      locale === Locale.De
        ? reimbursementTitlesDE[index % reimbursementTitlesDE.length]
        : reimbursementTitlesEN[index % reimbursementTitlesEN.length],
    iban: `DE45 ${faker.number(1000, 9999)} 5678 9012 3456 78`,
    grossAmount: {
      amount: faker.number(),
      currency: DocumentCurrency.Eur,
      precision: 2,
    },
  }));
};

export const getFirstReimbursementLink = ({
  searchParams,
  reimbursementCursor,
  reimbursementId,
  organizationSlug,
}: {
  searchParams: URLSearchParams;
  reimbursementId: string | undefined;
  reimbursementCursor: string | undefined;
  organizationSlug: string | null;
}) => {
  if (!organizationSlug || !reimbursementId || !reimbursementCursor) {
    return undefined;
  }
  const existingSearchParams = new URLSearchParams(searchParams);

  const firstReimbursementPathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.REIMBURSEMENTS}/:reimbursementId?`,
    { organizationSlug: organizationSlug, reimbursementId }
  );

  existingSearchParams.append('cursor', reimbursementCursor);
  const firstReimbursementLink = `${firstReimbursementPathname}?${existingSearchParams.toString()}`;

  return firstReimbursementLink;
};
