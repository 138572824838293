import { CustomEmptyStateProps, Link } from '@candisio/design-system';
import {
  LottieAnimation,
  noDocumentsInTable,
  noresultsData,
} from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface EcmDocumentsEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
}

export const EcmDocumentsEmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: EcmDocumentsEmptyStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const hasNoFilterResults = isTableFiltered && isTableEmpty;

  return (
    <div className="grid gap-4">
      <LottieAnimation
        options={{
          animationData: hasNoFilterResults
            ? noresultsData
            : noDocumentsInTable,
        }}
      />
      <div className="flex max-w-64 items-center flex-col">
        {hasNoFilterResults ? (
          <>
            <p className="text-center whitespace-pre-wrap m-0">
              {t('emptyState.noFilterResults')}
            </p>
            <Link as="button" onClick={() => resetFilters()}>
              {t('emptyState.resetFilters')}
            </Link>
          </>
        ) : (
          <p className="text-center whitespace-pre-wrap m-0">
            {t('emptyState.noDocumentsFound')}
          </p>
        )}
      </div>
    </div>
  );
};
