import { TruncatedText } from '@candisio/design-system/next';
import clsx from 'clsx';

interface TableHeaderProps {
  text: string;
  className?: string;
}

export const TableHeader = ({ text, className }: TableHeaderProps) => {
  return (
    <TruncatedText className={clsx('text-[0.875rem]', className)}>
      {text}
    </TruncatedText>
  );
};
