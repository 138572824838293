import { Icon } from '@candisio/design-system/next';
import clsx from 'clsx';
import { MouseEvent, ReactNode } from 'react';
import styles from './PerDiem/PerDiem.module.css';
import { Button } from '@candisio/design-system';

interface ReimbursementItemSummaryCardProps {
  isClickable: boolean;
  formattedTotalAmount?: string;
  title: string;
  ctaTranslation?: boolean;
  icon: 'calendarDays' | 'travel' | 'receipt';
  iconColor: 'blue' | 'yellow' | 'green';
  description: string;
  isLoading?: boolean;
  isCtaDisabled?: boolean;
  count?: number;
  customListItemsWithCta?: ReactNode;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  onAddNew?: () => void;
}

export const ReimbursementItemSummaryCard = ({
  isClickable,
  formattedTotalAmount,
  title,
  description,
  ctaTranslation,
  isCtaDisabled,
  isLoading = false,
  count = 0,
  icon,
  iconColor,
  customListItemsWithCta,
  onClick,
  onAddNew,
}: ReimbursementItemSummaryCardProps) => {
  const isAddActionVisible =
    !!onAddNew && !!ctaTranslation && !customListItemsWithCta;

  return (
    <div className="grid gap-[1px] content-start">
      <div
        className={styles.card}
        data-clickable={isClickable}
        data-rounded-corners={isAddActionVisible ? 'top' : undefined}
        data-squared-corners={!!customListItemsWithCta ? 'bottom' : undefined}
        onClick={onClick}
      >
        <div className="grid grid-cols-[auto_1fr_auto] gap-6 place-items-start">
          <div
            className={clsx(
              styles.iconWrapper,
              styles[`iconWrapper--${iconColor}`]
            )}
          >
            <Icon icon={icon} className="h-5 w-5" />
          </div>
          <div className="grid gap-2 justify-self-start">
            <div className="flex gap-[0.125rem] justify-start">
              <span className="text-[1.125rem] font-medium">{title}</span>
              {count > 0 && (
                <span className="text-[1.125rem] font-medium">({count})</span>
              )}
            </div>
            <span className="text-basic">{description}</span>
          </div>
          {formattedTotalAmount && (
            <span className="text-basic font-medium">
              {formattedTotalAmount}
            </span>
          )}
        </div>
      </div>
      {customListItemsWithCta}
      {isAddActionVisible && (
        <div
          className={clsx('grid place-items-center', styles.card)}
          data-rounded-corners="bottom"
        >
          <Button
            icon="plus"
            iconPosition="left"
            size="small"
            onClick={onAddNew}
            disabled={isCtaDisabled || isLoading}
            variant={isCtaDisabled ? 'tertiary' : 'primary'}
          >
            {ctaTranslation}
          </Button>
        </div>
      )}
    </div>
  );
};
