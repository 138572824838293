import { Grid, Text } from '@candisio/design-system';
import { InfoPopupButton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DeliveryAddressComboBoxField } from './DeliveryAddressComboBoxField';
import { DeliveryAddressTextField } from './DeliveryAddressTextField';
import { SameAddressCheckbox } from './SameAddressCheckbox';

export const DeliveryAddress = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space12">
      <div className="flex items-center gap-1">
        <Text fontSize="basic" fontWeight="semibold">
          {t('insights.organizationSettingsDrawer.form.cardDeliveryAddress')}
        </Text>
        <InfoPopupButton
          message={t(
            'insights.organizationSettingsDrawer.form.cardDeliveryAddressTooltip'
          )}
        />
      </div>
      <SameAddressCheckbox />
      <Grid templateColumns="2fr 1fr" gap="space8">
        <DeliveryAddressTextField
          name="deliveryAddressStreet"
          label={t(
            'insights.organizationSettingsDrawer.form.fields.deliveryAddressStreet.label'
          )}
        />
        <DeliveryAddressTextField
          name="deliveryAddressNumber"
          label={t(
            'insights.organizationSettingsDrawer.form.fields.deliveryAddressNumber.label'
          )}
        />
      </Grid>
      <DeliveryAddressTextField
        name="deliveryAddressAdditionalInfo"
        label={t(
          'insights.organizationSettingsDrawer.form.fields.deliveryAddressAdditionalInfo.label'
        )}
      />
      <Grid gap="space8">
        <DeliveryAddressTextField
          name="deliveryAddressZipcode"
          label={t(
            'insights.organizationSettingsDrawer.form.fields.deliveryAddressZipCode.label'
          )}
        />
        <DeliveryAddressTextField
          name="deliveryAddressCity"
          label={t(
            'insights.organizationSettingsDrawer.form.fields.deliveryAddressCity.label'
          )}
        />
      </Grid>
      <DeliveryAddressComboBoxField
        name="deliveryAddressCountry"
        label={t(
          'insights.organizationSettingsDrawer.form.fields.deliveryAddressCountry.label'
        )}
      />
    </Grid>
  );
};
