import { Button, Card, Grid, TruncatedText } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApprovalFieldData,
  useReimbursementFormsContext,
} from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { SendBackToEmployeeForm } from '../SendBackToEmploy/SendBackToEmployeeForm';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { ApproveNowAction } from './ApproveNowAction';
import { ErrorSection } from './ErrorSection';
import { FastApproveReturn } from './FooterActionBox';
import { RequestApprovalAction } from './RequestApprovalAction';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { FooterHeader } from './FooterHeader';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

interface RejectedStatusFooterActionBoxProps {
  createdById?: string;
  isFastApprovePending: boolean;
  isRequestAmendmentPending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  getExpenses: UseFormGetValues<ReimbursementItemsFormOutput>;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
  reimbursement?: Reimbursement;
  onCycleReimbursments: () => void;
  reimbursementItems: ReimbursementItemForList[];
  isSubmitForApprovalPending: boolean;
  onSubmitForApproval: (
    values: ApprovalFieldData
  ) => Promise<'error' | 'success'>;
}

export const MIN_COLUMN_WIDTH = 151;
export const MIN_COLUMN_WIDTH_OTHER = 191;
const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const RejectedStatusFooterActionBox = ({
  createdById,
  isFastApprovePending,
  isRequestAmendmentPending,
  isSubmitForApprovalPending,
  onSubmitForApproval,
  onRequestAmendment,
  onFastApprove,
  getExpenses,
  reimbursement,
  onCycleReimbursments,
  reimbursementItems,
}: RejectedStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);
  const { hasReimbursementErrors, isReimbursementUpdatePending } =
    useReimbursementFormsContext();
  const { hasBookingsErrors } = useReimbursementSplitBookingHelpers();

  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove;

  if (isOnlyApprover || isOnlyRequester) return null;

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  const isErrorSectionVisible = hasReimbursementErrors || hasBookingsErrors;

  if (isCommentFormVisible) {
    return (
      <SendBackToEmployeeForm
        onSetIsFormVisible={setIsCommentFormVisible}
        onRequestAmendment={onRequestAmendment}
        isRequestAmendmentPending={isRequestAmendmentPending}
      />
    );
  }

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Grid gap="space8">
        <FooterHeader
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
        />
        {isErrorSectionVisible && <ErrorSection />}
        <Grid gap="space8">
          {canRequestApproval && (
            <RequestApprovalAction
              getExpenses={getExpenses}
              reimbursement={reimbursement}
              onCycleReimbursments={onCycleReimbursments}
              isFastApprovePending={isFastApprovePending}
              isSubmitForApprovalPending={isSubmitForApprovalPending}
              onSubmitForApproval={onSubmitForApproval}
            />
          )}
          <Grid
            gap="space8"
            templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}
          >
            {canFastApprove && (
              <ApproveNowAction
                btnVariant="secondary"
                onFastApprove={onFastApprove}
                getExpenses={getExpenses}
                isFastApprovePending={isFastApprovePending}
                isSubmitForApprovalPending={isSubmitForApprovalPending}
              />
            )}
            <Button
              color="blue"
              variant="secondary"
              disabled={isReimbursementUpdatePending || isFastApprovePending}
              onClick={() => setIsCommentFormVisible(true)}
            >
              <TruncatedText>{t(backTextKey)}</TruncatedText>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
