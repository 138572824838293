import { PropsWithChildren } from 'react';
import styles from './power-search-toolbar.module.css';
import clsx from 'clsx';

interface ToolbarAreaActiveFiltersProps extends PropsWithChildren {
  hasFilters: boolean;
  className?: string;
}

export const ToolbarAreaActiveFilters = ({
  hasFilters,
  className,
  ...props
}: ToolbarAreaActiveFiltersProps) => {
  return (
    <div
      className={clsx(styles.powerSearchToolbarActiveFilters, className)}
      data-active-filters={hasFilters}
      {...props}
    />
  );
};
