import {
  ReimbursementCaseStatus,
  useReimbursementCaseByIdQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useMemo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementRouteParams } from './useReimbursementNavigation';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { APPROVING_EDIT_MODE } from '../context/ReimbursementFormsProvider';

export interface UIConfigProps {
  isExpenseItemFormEditable: boolean;
  isReimbursementFormEditable: boolean;
  canManageReimbursementItems: boolean;
  isPerDiemFormEditable: boolean;
}

export const defaultUIConfig: UIConfigProps = {
  isExpenseItemFormEditable: false,
  isReimbursementFormEditable: false,
  canManageReimbursementItems: false,
  isPerDiemFormEditable: false,
};

interface UIConfigMapParams {
  isOwner: boolean;
  canManageReimbursements: boolean;
  isApprovingEditMode: boolean;
}

// defines UI config for reimbursement statuses based on ownership and management roles.
export const reimbursementUIConfigMap: Record<
  ReimbursementCaseStatus,
  (params: UIConfigMapParams) => UIConfigProps
> = {
  DRAFT: ({ isOwner }) => ({
    isReimbursementFormEditable: isOwner,
    isExpenseItemFormEditable: isOwner,
    canManageReimbursementItems: isOwner,
    isPerDiemFormEditable: isOwner,
  }),
  REVIEW: ({ canManageReimbursements }) => ({
    isReimbursementFormEditable: canManageReimbursements,
    isExpenseItemFormEditable: canManageReimbursements,
    canManageReimbursementItems: false,
    isPerDiemFormEditable: canManageReimbursements,
  }),
  CHECK: ({ isOwner }) => ({
    isReimbursementFormEditable: isOwner,
    isExpenseItemFormEditable: isOwner,
    canManageReimbursementItems: isOwner,
    isPerDiemFormEditable: isOwner,
  }),
  REJECTED: ({ canManageReimbursements, isOwner }) => ({
    isReimbursementFormEditable: canManageReimbursements,
    isExpenseItemFormEditable: canManageReimbursements,
    canManageReimbursementItems: false,
    isPerDiemFormEditable: canManageReimbursements && isOwner,
  }),
  APPROVED: () => defaultUIConfig,
  APPROVING: ({ isApprovingEditMode, canManageReimbursements }) => ({
    ...defaultUIConfig,
    isReimbursementFormEditable: isApprovingEditMode && canManageReimbursements,
  }),
  ARCHIVED: () => defaultUIConfig,
  EXPORTED: () => defaultUIConfig,
  EXPORTING: () => defaultUIConfig,
  PARTIALLY_EXPORTED: () => defaultUIConfig,
};

export const useReimbursementUIConfig = () => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();
  const { canUseReimbursement } = useReimbursement();
  const currentUserId = useCurrentUser()?.id ?? '';
  const { isAccountant, isAdmin } = useUserRoles();
  const { searchParams } = useMutateSearchParams();

  const isApprovingEditMode =
    searchParams.get(APPROVING_EDIT_MODE.PARAM) === APPROVING_EDIT_MODE.VALUE;

  const { data, loading } = useReimbursementCaseByIdQuery({
    variables: { id: reimbursementId ?? '' },
    skip: !reimbursementId || !canUseReimbursement,
  });

  const { reimbursementCaseById } = data ?? {};
  const { createdByMembership, status } = reimbursementCaseById ?? {};

  const isOwner = createdByMembership?.id === currentUserId;

  const canManageReimbursements = isAdmin || isAccountant;

  const uiConfig = useMemo(() => {
    if (loading || !status) {
      return defaultUIConfig;
    }

    const config = reimbursementUIConfigMap[status];

    return config({ isOwner, canManageReimbursements, isApprovingEditMode });
  }, [loading, status, isOwner, canManageReimbursements, isApprovingEditMode]);

  return uiConfig;
};
