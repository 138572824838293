import { Locale, Maybe } from 'generated-types/graphql.types';
import moment from 'moment';

export const DATE_TIME_FORMAT_DE = {
  /** Mo, 12. Jan. */
  WEEKDAY_SHORT_DATE: 'dd, DD. MMM',
  /** 12. Jan. 2025 */
  DATE_WITH_YEAR: 'DD. MMM YYYY',
  /** 12. Jan., 08:00 */
  DATE_WITH_TIME: 'DD. MMM, HH:mm',
} as const;

export const DATE_TIME_FORMAT_EN = {
  /** Mon, Jan 12 */
  WEEKDAY_SHORT_DATE: 'ddd, MMM DD',
  /** Jan 12, 2025 */
  DATE_WITH_YEAR: 'MMM DD, YYYY',
  /** Jan 12, 08:00 */
  DATE_WITH_TIME: 'MMM DD, HH:mm',
} as const;

export const getDateTimeFormat = (locale: Maybe<Locale>) => {
  return locale === Locale.De ? DATE_TIME_FORMAT_DE : DATE_TIME_FORMAT_EN;
};

interface DateTimeFormatOptions {
  formatKey?: keyof typeof DATE_TIME_FORMAT_EN;
  locale?: Maybe<Locale>;
  useUtc?: boolean;
}

export const formatDateTime = (
  dateTime: string,
  options: DateTimeFormatOptions = {}
): string => {
  const {
    formatKey = 'DATE_WITH_TIME',
    locale = Locale.En,
    useUtc = true,
  } = options;

  const dateTimeFormat = getDateTimeFormat(locale);
  const format = dateTimeFormat[formatKey];

  moment.locale(locale?.toLowerCase() === 'de' ? 'de' : 'en');

  return useUtc
    ? moment(dateTime).utc().format(format)
    : moment(dateTime).format(format);
};

export const formatDateRange = (
  startDateTime: string,
  endDateTime: string,
  options: DateTimeFormatOptions = {}
): string => {
  const formattedStart = formatDateTime(startDateTime, {
    formatKey: 'DATE_WITH_TIME',
    ...options,
  });
  const formattedEnd = formatDateTime(endDateTime, {
    formatKey: 'DATE_WITH_TIME',
    ...options,
  });

  return `${formattedStart} – ${formattedEnd}`;
};
