import {
  DocumentCurrency,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { MouseEvent } from 'react';
import { isNil } from 'lodash';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { ReimbursementItemSummaryCard } from './ReimbursementItemSummaryCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface MilageDetailsCardProps {
  reimbursementItems?: ReimbursementItemForList[];
  isCtaLoading?: boolean;
  createMileage?: () => void;
}

export const MilageDetailsCard = ({
  createMileage,
  isCtaLoading,
  reimbursementItems = [],
}: MilageDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { transactionAmountFormatter } = useMoneyFormatter();

  // Ideally this should not happen and BE would return a field with 3 different total amounts:
  // 1 - Expenses
  // 2 - PerDiem
  // 3 - Milage
  // In that way we would not need to iterate through the array to find the item
  const milage = reimbursementItems.find(
    item => item.type === ReimbursementItemType.Mileage
  );

  const isMilageFound = !isNil(milage);

  const totalAmountMilageFormatted = isMilageFound
    ? transactionAmountFormatter({
        value: milage.totalAmount?.amount ?? 0,
        precision: milage.totalAmount?.precision ?? 2,
        currency: milage.totalAmount?.currency ?? DocumentCurrency.Eur,
      })
    : undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!isMilageFound) {
      return;
    }
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
  };

  return (
    <ReimbursementItemSummaryCard
      isClickable={isMilageFound}
      formattedTotalAmount={totalAmountMilageFormatted}
      onClick={handleClick}
      iconColor="yellow"
      icon="travel"
      title={t('reimbursementView.middleSection.form.mileage.title')}
      description={t(
        'reimbursementView.middleSection.form.mileage.description'
      )}
      ctaTranslation={t(
        'reimbursementView.middleSection.form.mileage.callToAction'
      )}
      isCtaDisabled={isMilageFound}
      isLoading={isCtaLoading}
      onAddNew={createMileage}
    />
  );
};
