import { PerDiemMealsButtonGroup } from './PerDiemMealsButtonGroup/PerDiemMealsButtonGroup';
import styles from './DaysStep.module.css';
import { perDiemMealbuttons } from '../PerDiemForm';
import { generateDaysFieldNames } from '../utils/generatePerDiemFieldNames';
import { formatDateTime } from 'views/Reimbursement/utils/formatDateTime';
import { useCurrentUser } from 'providers/CurrentUserProvider';

interface DayProps {
  formIndex: number;
  dayIndex: number;
  date: string;
  isDisabled: boolean;
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
}

export const Day = ({
  date,
  dayIndex,
  formIndex,
  isDisabled,
  onUpdatePerDiemDays,
}: DayProps) => {
  const locale = useCurrentUser()?.locale;
  const fields = generateDaysFieldNames(formIndex, dayIndex);

  return (
    <li className={styles['day-item']} data-testid={`per-diem-day-${dayIndex}`}>
      <span className="text-sm text-gray-800 font-medium">
        {formatDateTime(date, {
          formatKey: 'WEEKDAY_SHORT_DATE',
          locale,
          useUtc: false,
        })}
      </span>
      <PerDiemMealsButtonGroup
        name={fields.meals}
        buttons={perDiemMealbuttons}
        onChange={() => onUpdatePerDiemDays(formIndex)()}
        isDisabled={isDisabled}
      />
    </li>
  );
};
