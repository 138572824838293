import { TabItem } from '@candisio/design-system';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalsViewProps as ApprovalsTabsContainerProps } from '../ApprovalsView';
import { ApprovalsTableContainer } from '../Invoices/ApprovalTable';
import { ApprovalReimbursementsContainer } from '../Reimbursements/Table/ApprovalReimbursementsContainer';
import { ApprovalTabs } from './AppprovalsTabs';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useApprovalTabNavigationData } from '../Invoices/hooks/useApprovalTabNavigationData';
import { useApprovalTabNavigationDataDeprecated } from '../Invoices/hooks/useApprovalTabNavigationDataDeprecated';
import { useApprovalReimbursementsCounter } from '../Reimbursements/toolkit/hooks/useApprovalReimbursementsCounter';

export const ApprovalsTabsContainer = (props: ApprovalsTabsContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  const { variant } = useReimbursementsPromotions();
  const { showConsistentSortAndFiltering } = useEcm();
  const { allDocumentsCount } = useApprovalTabNavigationData({
    skip: !showConsistentSortAndFiltering,
  });

  const { allDocumentsCount: allDocumentsCountDeprecated } =
    useApprovalTabNavigationDataDeprecated({
      skip: showConsistentSortAndFiltering,
    });

  const { allReimbursementsCount } = useApprovalReimbursementsCounter();

  const approvalDocumentsCount = showConsistentSortAndFiltering
    ? allDocumentsCount
    : allDocumentsCountDeprecated;

  const reimbursementPromotionIcon = useMemo(() => {
    let reimbursementsTabIcon: TabItem | undefined = {
      iconColor: 'purple500',
      iconTooltip: t('tag.addOn'),
      icon: 'arrowUpCircleFilled',
    };

    if (variant === 'noPromotion') {
      reimbursementsTabIcon = {
        badge:
          Number(allReimbursementsCount) > 0
            ? (allReimbursementsCount ?? 0).toString()
            : undefined,
        badgeColor: 'darkGray',
      };
    }

    if (variant === 'promotionForEntitlements') {
      reimbursementsTabIcon = { tag: t('common:tag.soon') };
    }

    return reimbursementsTabIcon;
  }, [t, variant, allReimbursementsCount]);

  const tabs: TabItem[] = [
    {
      key: 'Invoices',
      title: t('tabs.invoices'),
      children: <ApprovalsTableContainer {...props} />,
      badgeColor: 'darkGray',
      badge: approvalDocumentsCount?.toString(),
    },
    {
      key: 'Reimbursements',
      title: t('tabs.reimbursements'),
      children: <ApprovalReimbursementsContainer />,
      ...reimbursementPromotionIcon,
    },
  ];
  return <ApprovalTabs tabs={tabs} defaultSelectedKey={props.activeTab} />;
};
