import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { usePdfViewProvider } from '../../PdfViewProvider';
import { MAX_SCALE, ZOOM_STEP } from '../constants';

export const ZoomInButton = () => {
  const [t] = useTranslation();
  const { usePdfView, setPdfView } = usePdfViewProvider();
  const scale = usePdfView('scale');
  const isDisabled = scale >= MAX_SCALE;

  return (
    <Button
      icon="zoomIn"
      label={t('document.zoomIn')}
      onClick={() =>
        setPdfView('scale', Math.min(MAX_SCALE, scale + ZOOM_STEP))
      }
      disabled={isDisabled}
      variant="tertiary"
      size="small"
    />
  );
};
