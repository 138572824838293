import { Flex, Grid, Separator, Text } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { HospitalityExpenseInvoiceDataFields } from './HospitalityExpenseInvoiceDataFields';
import {
  HospitalityFieldNames,
  generateFieldNames,
} from './utils/generateHospitalityFormFieldNames';
import { getExpenseExtractionStatus } from './utils/invoiceDataExtractionHelpers';
import { BooleanMap } from './utils/useReimbursementItemsFormActions';

type GuestFieldKeys = HospitalityFieldNames[
  | 'externalGuests'
  | 'internalGuests'];

interface HospitalityExpenseFieldsWithExtractionProps {
  formIndex: number;
  isExcluded: boolean;
  invoice: ExpenseInvoice | undefined;
  isExpenseFormEditable: boolean;
  isCreatingInvoice: BooleanMap;
  isExtractingInvoiceData: BooleanMap;
  onUpdateExpense: (index: number) => () => Promise<void>;
  onUpdateTotalAmount: (index: number) => void;
}

export const HospitalityExpenseFieldsWithExtraction = ({
  formIndex,
  invoice,
  isCreatingInvoice,
  isExcluded,
  isExpenseFormEditable,
  isExtractingInvoiceData,
  onUpdateExpense,
  onUpdateTotalAmount,
}: HospitalityExpenseFieldsWithExtractionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fields = generateFieldNames(formIndex);
  const { getValues, setValue, trigger } =
    useFormContext<ReimbursementItemsFormOutput>();

  const extractedData = getValues(fields.extractedData);
  const isExtractedDataAccepted = getValues(fields.isExtractedDataAccepted);
  const totalAmount = getValues(fields.totalAmount);

  const extractionStatus = getExpenseExtractionStatus({
    extractedData,
    isExtractedDataAccepted,
  });

  const handleGuestFieldUpdate = (field: GuestFieldKeys) => {
    onUpdateExpense(formIndex)();
    void trigger(field);
  };

  return (
    <Grid gap="space16" alignContent="start">
      <Grid gap="space16">
        <HookFormTextField
          name={fields.reason}
          readOnly={isExcluded}
          maxLength={150}
          label={t(
            'reimbursementView.middleSection.form.hospitality.reason.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.reason.placeholder'
          )}
          onClear={onUpdateExpense(formIndex)}
          inputProps={{
            onBlur: onUpdateExpense(formIndex),
          }}
        />
        <HookFormTextField
          name={fields.internalGuests}
          readOnly={isExcluded}
          label={t(
            'reimbursementView.middleSection.form.hospitality.internalGuests.label'
          )}
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.internalGuests.placeholder'
          )}
          infoTooltip={t(
            'reimbursementView.middleSection.form.hospitality.internalGuests.tooltip'
          )}
          onClear={() => handleGuestFieldUpdate(fields.externalGuests)}
          inputProps={{
            onBlur: () => handleGuestFieldUpdate(fields.externalGuests),
          }}
        />
        <HookFormTextField
          name={fields.externalGuests}
          label={
            /**
             * The other field aligned to this one has infoTooltip which makes the alignement impossible
             * thus the need of putting the label into a flex
             */
            <Flex height="space16" alignItems="center">
              <Text>
                {t(
                  'reimbursementView.middleSection.form.hospitality.externalGuests.label'
                )}
              </Text>
            </Flex>
          }
          placeholder={t(
            'reimbursementView.middleSection.form.hospitality.externalGuests.placeholder'
          )}
          readOnly={isExcluded}
          onClear={() => handleGuestFieldUpdate(fields.internalGuests)}
          inputProps={{
            onBlur: () => handleGuestFieldUpdate(fields.internalGuests),
          }}
        />
      </Grid>
      {!!invoice && (
        <div className="grid gap-2">
          <Grid alignItems="center" templateColumns="auto 1fr" gap="space8">
            <Text fontWeight="semibold" fontSize="basic">
              {t('reimbursementView.middleSection.form.invoiceData')}
            </Text>
            <Separator />
          </Grid>
          <HospitalityExpenseInvoiceDataFields
            onSetValue={setValue}
            extractionStatus={extractionStatus}
            extractedData={extractedData}
            fields={fields}
            formIndex={formIndex}
            isExcluded={isExcluded}
            totalAmount={totalAmount}
            onUpdateHospitality={onUpdateExpense}
            onUpdateTotalAmount={onUpdateTotalAmount}
            isExpenseFormEditable={isExpenseFormEditable}
            isLoadingInvoiceData={
              isCreatingInvoice[formIndex] || isExtractingInvoiceData[formIndex]
            }
          />
        </div>
      )}
    </Grid>
  );
};
