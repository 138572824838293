import clsx from 'clsx';
import styles from './PerDiem.module.css';
import { getCountryFlag } from '../PerDiemLocationField';
import { TruncatedText } from '@candisio/design-system/next';
import { DateTimeRangeDisplay } from './DateTimeRangeDisplay';

interface LocationSummaryProps {
  startDate?: string;
  endDate?: string;
  countryIsoCode?: string;
  locationName?: string;
}

export const LocationSummary = ({
  startDate,
  endDate,
  locationName,
  countryIsoCode,
}: LocationSummaryProps) => {
  const countryFlag = getCountryFlag({
    isoCode: countryIsoCode,
    className: 'w-[18px] h-[14px] flex-shrink-0',
  });

  return (
    <div className={clsx('font-medium', styles.segmentSummary)}>
      {startDate && endDate && (
        <DateTimeRangeDisplay startDateTime={startDate} endDateTime={endDate} />
      )}
      <div className="flex items-center gap-3">
        {countryFlag}
        {locationName && (
          <TruncatedText className="max-w-[20rem]">
            {locationName}
          </TruncatedText>
        )}
      </div>
    </div>
  );
};
