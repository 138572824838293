import { Button, TruncatedText } from '@candisio/design-system';
import {
  ApprovingFooterMode,
  TRANSLATION,
} from './ApprovingStatusFooterActionBox';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useUserRoles } from 'hooks/useUserRoles';

interface EditModeActionProps {
  onClick: () => void;
  approvingFooterMode: ApprovingFooterMode;
}

export const EditModeAction = ({
  approvingFooterMode,
  onClick,
}: EditModeActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { isOnlyApprover, isOnlyRequester } = useUserRoles();

  if (isOnlyApprover || isOnlyRequester) {
    return null;
  }

  const btnText =
    approvingFooterMode === 'edit' ? TRANSLATION.CANCEL_EDIT : TRANSLATION.EDIT;

  return (
    <Button size="small" variant="tertiary" onClick={onClick}>
      <TruncatedText>{t(btnText)}</TruncatedText>
    </Button>
  );
};
