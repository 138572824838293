import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import styles from './OverviewGrid.module.css';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { AvailableLimitTile } from './AvailableLimitTile/AvailableLimitTile';
import { CardInUseTile } from './CardInUseTile/CardInUseTile';
import { OverviewIssueCardTile } from './OverviewIssueCardTile/OverviewIssueCardTile';
import { PromoCreditCardChart } from '../promos/PromoCreditCardChart';
import { useGetCountExpiringSoonCards } from 'views/CreditCards/hooks/useGetCountExpiringSoonCards';
import { CardExpiresSoonTile } from './CardExpiresSoonTile/CardExpiresSoonTile';
import { MissingInvoicesTile } from './MissingInvoicesTile/MissingInvoicesTile';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { PromoCreditCardExplained } from '../promos/PromoCreditCardExplained';

export const OverViewGrid = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isPromotionSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_EXPLAINED
  );
  const { countExpiringCards } = useGetCountExpiringSoonCards();

  const cols = !!countExpiringCards ? 4 : 3;

  return (
    <div className="flex flex-col gap-2">
      <SwimlaneHeader
        icon="creditCardOutline"
        label={t('insights.requestsOverview.title')}
      />
      <div className={styles.grid} data-cols={cols}>
        <OverviewIssueCardTile />
        <CardInUseTile />
        {!!countExpiringCards && <CardExpiresSoonTile />}

        <div className={styles.limit}>
          <AvailableLimitTile />
        </div>

        <div className={styles.promo}>
          {isPromotionSeen ? (
            <PromoCreditCardChart />
          ) : (
            <PromoCreditCardExplained />
          )}
        </div>

        <div className={styles.invoices}>
          <MissingInvoicesTile />
        </div>
      </div>
    </div>
  );
};
