import { Flex, Grid } from '@candisio/design-system';
import { PromotionDetail } from 'components/NewPromotions/types';
import { CTASection } from './CTASection/CTASection';
import { PromotionBenefitsSection } from './PromotionBenefitsSection/PromotionBenefitsSection';
import { UpgradeInfo } from './UpgradeInfoSection/UpgradeInfo';
import { TrackOption } from '../PromotionCard';

export type PromotionDetailsModalContentProps = {
  promotionDetails: PromotionDetail;
  trackOption?: Pick<TrackOption, 'eventLocation' | 'feature'>;
};

export const PromotionDetailsModalContent = ({
  promotionDetails,
  trackOption,
}: PromotionDetailsModalContentProps) => {
  const { title, benefits, upgradeInfo, imageUrl } = promotionDetails;

  return (
    <Grid templateColumns="1fr 39%">
      <PromotionBenefitsSection
        title={title}
        benefits={benefits}
        imageUrl={imageUrl}
      />

      {upgradeInfo && (
        <Flex
          direction="column"
          background="gray100"
          padding="space32 space32"
          borderRadius="8px"
          justifyContent="space-between"
        >
          <UpgradeInfo upgradeInfo={upgradeInfo} />
          <CTASection
            promotionDetails={promotionDetails}
            trackOption={trackOption}
          />
        </Flex>
      )}
    </Grid>
  );
};
