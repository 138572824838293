import { Link, LottieAnimation } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { useTranslation } from 'react-i18next';

export interface EmptyStateLayoutProps {
  /** Indicates if there's any filter applied to the table */
  isFiltered?: boolean;
  /** It cleans all the filters applied to the table */
  resetFilters?: () => void;
}

export const EmptyStateLayout = ({
  isFiltered,
  resetFilters,
}: EmptyStateLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  return (
    <div className="grid text-gray-800 min-h-full justify-items-center gap-4 items-center">
      <LottieAnimation
        animationData={isFiltered ? 'noresultsData' : 'nocontactData'}
      />
      <div className="flex flex-col max-w-64 items-center">
        <span className="text-center whitespace-pre-wrap">
          {isFiltered
            ? t('emptyState.content.filtered')
            : t('emptyState.content.empty')}
        </span>
        {isFiltered && resetFilters && (
          <Link as="button" onClick={() => resetFilters()}>
            {t('emptyState.actions.resetFilters')}
          </Link>
        )}
      </div>
    </div>
  );
};
