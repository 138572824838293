import { useMemo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  DocumentCurrency,
  usePerDiemItemCalculationQuery,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useFormatPerDiemDay } from '../utils/useFormatPerDiemDay';

export type DayType = 'HALF_DAY' | 'FULL_DAY';

export type MealRate = {
  rateDifference: string;
  percentageDifference: string;
};

export interface PerDiemDayCalculationDataProps {
  id: string;
  date: string;
  dayType: DayType;
  dayRate: string;
  reimbursableRate: string;
  location: string;
  countryIsoCode: string;
  breakfast?: MealRate;
  lunch?: MealRate;
  dinner?: MealRate;
}

export const usePerDiemItemCalculation = (perDiemId: string) => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUsePerDiemReimbursement } = useReimbursement();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const formatPerDiemDay = useFormatPerDiemDay();

  const skipQuery = !reimbursementId || !canUsePerDiemReimbursement;

  const { data, loading } = usePerDiemItemCalculationQuery({
    variables: { id: perDiemId },
    fetchPolicy: 'network-only',
    skip: skipQuery,
  });

  const perDiemItem =
    data?.reimbursementItem.__typename === 'PerDiemItem'
      ? data.reimbursementItem
      : undefined;

  const perDiemDayCalculationData = useMemo(() => {
    if (!perDiemItem?.id || !perDiemItem?.days) return [];

    return perDiemItem.days.map(formatPerDiemDay);
  }, [formatPerDiemDay, perDiemItem]);

  const { totalAmount } = perDiemItem ?? {};

  const formattedTotalReimbursableRate = totalAmount?.amount
    ? transactionAmountFormatter({
        value: totalAmount.amount ?? 0,
        currency: totalAmount?.currency ?? DocumentCurrency.Eur,
        precision: totalAmount?.precision ?? 2,
      })
    : undefined;

  return {
    isLoading: loading,
    perDiemDayCalculationData,
    totalReimbursableRate: formattedTotalReimbursableRate,
  };
};
