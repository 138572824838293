import {
  EcmStorageFormInitialDataQuery,
  GetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import {
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

interface UseCanModifyAccessProps {
  accessModificationPermissions:
    | NonNullable<
        GetDocumentForDraftQuery['getDocument']
      >['accessModificationPermissions']
    | NonNullable<
        EcmStorageFormInitialDataQuery['getAggregatedEcmDocument']
      >['accessModificationPermissions'];
}

export const useCanModifyAccess = ({
  accessModificationPermissions,
}: UseCanModifyAccessProps) => {
  const [modifyEcmDocumentEditAccessFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.modifyEcmDocumentEditAccess,
  ]);
  // this will be replaced in the future with actual permissions check
  const canModifyDocumentAccess = accessModificationPermissions?.viewAccess;

  const entitlement = useEntitlementsFlag();

  return {
    canModifyDocumentAccess:
      modifyEcmDocumentEditAccessFF &&
      entitlement !== 'LEGACY' &&
      canModifyDocumentAccess,
    isModifyDocumentAccessFeatureEnabled:
      modifyEcmDocumentEditAccessFF && entitlement !== 'LEGACY',
  };
};
